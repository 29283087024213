export const locale = {
    lang: 'de',
    data: {
        'DASHBOARD' : {
            'LICENSORNAME' : 'Lizenzgeber',
            'PERIOD' : 'Royalty Period',
            'PAYOUT' : 'Auszahlung',
            'DUE' : 'Restschuld',
            'ACTION' : 'Statement / Details',
            'TITLE' : 'Dashboard',
            'SERVERTASKS' : 'Servertasks',
            'TASKSTABLE' : {
                'RUNNING' : 'In Bearbeitung',
                'FINISHED' : 'Beendet',
                'TYPE' : 'Type',
                'STARTED' : 'Startzeit',
                'ENDED'   : 'Endzeit',
                'USER' : 'Benutzer'
            },
            'WHATSNEW' : 'What\'s new',
            'KONTOSTAND': 'Kontostand',
            'NOTIFICATIONS': 'Notifications'
        }
    }
};
