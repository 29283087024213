import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '@/shared/services/global.service';

import {forEach} from '@angular/router/src/utils/collection';
import {FuseTranslationLoaderService} from '../../../@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';

@Pipe({name: 'countryByCode'})
export class CountryByCodePipe implements PipeTransform {

    constructor (private _translateService: TranslateService, private _globalService: GlobalService) {}

    transform(value: string): number {

        let language = this._translateService.currentLang;

        for (let i = 0; i < this._globalService.countries.length; i++) {
            if (this._globalService.countries[i].code === value) {
                return this._globalService.countries[i][language];
            }
        }
    }
}
