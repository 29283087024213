import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import {UsersService} from '@/main/users/users.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {Location} from '@angular/common';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material';
import {OAuthService} from 'angular-oauth2-oidc';
import {GlobalService} from '@/shared/services/global.service';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {Observable} from 'rxjs';
import {ExpensetypesService} from '@/main/expensetypes/expensetypes.service';


@Component({
  selector: 'app-expensetype-detail',
  templateUrl: './expensetype-detail.component.html',
  styleUrls: ['./expensetype-detail.component.scss']
})
export class ExpensetypeDetailComponent implements OnInit {


  @ViewChild('expensetypeForm') expensetypeForm: NgForm;
  costTypeId: string;
  costType: any = {client_id: 0};
  //userinfo: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;


  constructor(
      private _location: Location,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private route: ActivatedRoute,
      private router: Router,
      private auth: OAuthService,
      private _formBuilder: FormBuilder,
      private _expensetypesService: ExpensetypesService,
      private _globalService: GlobalService,
      public dialog: MatDialog
      ) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }


    canDeactivate(): Observable<boolean> | boolean {
        return this._globalService.canDeactivate();
    }

  ngOnInit(): void {

    this.costTypeId = this.route.snapshot.paramMap.get('id');
    this.costType.client_id = Number(this.route.snapshot.queryParamMap.get('clientId'));

    if (this.costTypeId !== 'new') {

        this.getOne(this.costTypeId);
    }else{

        if (!this.route.snapshot.queryParamMap.get('clientId')){
            this.costType.client_id = this._globalService.getUserClientId();
        }

        this._globalService.setFormData(this.costType, Object.assign({}, this.costType));
    }

  }

  back(): void {
    this._location.back();
  }


  save(): void{

     if (this.expensetypeForm.form.valid && this.costType.client_id !== null) {

      let uri = 'accounting/saveCostType';
      if (this.costTypeId !== 'new'){
        uri += '/' + this.costTypeId;
      }

      this._expensetypesService.save(uri, this.costType).subscribe(
          ( response: HttpEvent<any> ) => {

            switch (response.type) {

              case HttpEventType.Response:
                if ( response.body != null && response.body.success === true ) {

                    this._globalService.setFormData(this.costType, Object.assign({}, this.costType));

                    if (this.costTypeId === 'new'){
                        this.askForContinueAdding();
                    }
                }
                break;
            }

          },
          error => {
            console.log(error);
          });
    }
  }


  askForContinueAdding(): void{

      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to add more items?';
      this.confirmDialogRef.afterClosed()
          .subscribe(result => {
              if ( result ){
                  this.costType = {clientId: 0};
                  this.expensetypeForm.resetForm();
              }else{
                  this._location.back();
              }
              this.confirmDialogRef = null;
          });

  }

  getOne(uid: string): void{

    this._expensetypesService.getOne(
        'accounting/getCostType/' + uid,
    ).subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){

                this.costType = response.body.data;
                //console.log(this.costType);
                this._globalService.setFormData(this.costType, Object.assign({}, this.costType));
              }
              break;
          }
        },
        error => {
          console.log("error get one: " );
          console.log(error);
        });
  }

    openHelp(): void {
        const dialogRef = this.dialog.open(HelpDialog, {
            //width: '500px',
            //height: '400px',
            //disableClose: true
            data: { helpText: 'HELPTEXT.DETAIL.TEXT' , helpHeadline : 'HELPTEXT.DETAIL.HEADLINE', helpTextList1: 'HELPTEXT.DETAIL.LIST1',},
        });
    }

}
