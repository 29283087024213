import {Component, ViewChild} from '@angular/core';
import {MatDialog, MatTabChangeEvent} from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from './i18n/de';
import {locale as english } from './i18n/en';
import {fuseAnimations} from '../../../@fuse/animations';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {ProductListComponent} from '@/main/products/product-list/product-list.component';
import {GlobalService} from '@/shared/services/global.service';
import {ReleaseListComponent} from '@/main/products/release-list/release-list.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ImportModalComponent} from '@/main/licensors/import-modal/import-modal.component';
import {ProductImportModalComponent} from '@/main/products/product-import-modal/product-import-modal.component';
import {ReleaseImportModalComponent} from '@/main/products/release-import-modal/release-import-modal.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  animations   : fuseAnimations
})


export class ProductsComponent {

  tabs = {
    0 : 'audio',
    1 : 'merch',
    2 : 'merchbundle'
  };

  selectedTab = 0;

  activeTab = 'audio';

  @ViewChild('productListAudio') _productListAudio: ReleaseListComponent;
  @ViewChild('productListMerch') _productListMerch: ProductListComponent;
  @ViewChild('productListBundles') _productListBundles: ProductListComponent;

  constructor( private _fuseTranslationLoaderService: FuseTranslationLoaderService,
               public dialog: MatDialog,
               private _globalService: GlobalService, private _router: Router, private route: ActivatedRoute) {

    this.route.queryParams.subscribe(params => {
      this.selectedTab = params['tab'];
    });

    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.activeTab = this.tabs[tabChangeEvent.index];
    this._router.navigateByUrl('/products?tab=' + tabChangeEvent.index);
    //console.log(this.activeTab);
  }


  openHelp(): void {
    const dialogRef = this.dialog.open(HelpDialog, {
      //width: '500px',
      //height: '400px',
      //disableClose: true
      data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1',},
    });
  }

  openImportModal(): void {

    const dialogRef = this.dialog.open(ProductImportModalComponent, {
      width: '1200px',
      //data: {fpath: this.fpath}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      //this.animal = result;
    });

  }

    openReleaseImportModal(): void {
        const dialogRef = this.dialog.open(ReleaseImportModalComponent, {
            width: '1000px',
            //data: {fpath: this.fpath}
        });

        dialogRef.afterClosed().subscribe(result => {
            //console.log('The dialog was closed');
            //this.animal = result;
        });
    }

}

