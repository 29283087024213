export const locale = {
    lang: 'en',
    data: {

        'USERS' : {
            'ADD' : {
                'MODAL' : {
                    'CLIENTID' : 'Client',
                },
            },
        },

        'ACCOUNTING' : {
            'STATEMENTS' : {
                'CREATE' : {
                    'MODAL' : {
                        'HEADLINE' : 'Create statements'
                    }
                }
            },
            'PERIODS' : {
                'HEADLINE' : 'Royalty Periods',
                'TABLE' : {
                    'CLIENT'        : 'Client',
                    'IDENTIFIER'    : 'Identifier',
                    'PERIOD_START'  : 'Startdate',
                    'PERIOD_END'    : 'Enddate',
                    'INCOME'        : 'Revenue',
                    'ACCOUNTED'     : 'Payout',
                    'PERIOD_CLOSED' : 'Closed',
                    'PAYOUT'        : 'Payout',
                },
                'ADD' : {
                    'MODAL' : {
                        'SAVE'   : 'Save and Close',
                        'CANCEL' : 'Cancel',
                        'HEADLINE' : 'Abrechnungszeitraum hinzufügen / bearbeiten',
                        'IDENTIFIER' : 'Identifier',
                        'STARTDATE' : 'Start Datum',
                        'ENDDATE' : 'End Datum',
                    }
                },
                'DETAIL' : {
                    'FIELDREQMESSAGE'   : 'This field is required!',
                    'HEADLINE'          : 'Royalty Period',
                    'SALESIMPORT'       : 'Sales Import',
                    'REVENUEDIGITAL'    : 'Revenue Digital',
                    'REVENUEPHYSICAL'   : 'Revenue Physical',
                    'REVENUEMERCH'      : 'Revenue Merchandise',
                    'REVENUESUBLICENSE' : 'Sublicenses',
                    'CREATESTATEMENTS'  : 'Statements erstellen',
                    'RETURNS'           : 'Returns',
                    'SALE_TYPE'         : 'Revenue Type',
                    'CATALOG_NUMBER'    : 'Catalog Number',
                    'EAN'               : 'EAN',
                    'UPC'               : 'UPC',
                    'ISRC'              : 'ISRC',
                    'RELEASE'           : 'Release',
                    'TRACK'             : 'Track',
                    'QUANTITY'          : 'Quantity',
                    'REVENUE'           : 'Revenue',
                    'TERRITORY'         : 'Territory',
                    'PLATFORM'          : 'Platform',
                    'DISTRIBUTOR'       : 'Distributor',
                    'PPD'               : 'PPD',
                    'RELEASEARTIST'     : 'Release Artist',
                    'RELEASETITLE'      : 'Release Title',
                    'TRACKARTIST'       : 'Track Artist',
                    'TRACKETITLE'       : 'Track Title',
                    'AVERAGE_PPD'       : 'Average PPD',

                    'TRACKTITLE'       : 'Track-title',
                    'ARTIST'           : 'Artist',
                    'TITLE'            : 'Title',
                    'DESCRIPTION'      : 'Description',

                    'item_number' : '#',
                    'PRODUCT_IDENTIFIER' : 'Product identifier',
                    'PRODUCT_NAME' : 'Productname',
                    'PRODUCT' : 'Product',
                    'ITEM_NUMBER' : 'Itemnumber',
                    'CLOSEPERIOD' : 'Close Royalty Period',
                    'CLOSEPERIODWARNING' : 'Do you really want to close this royalty period? It will be not possible to change anything afterwards!'
                },
                'SALES' : {
                    'ADD' : {
                        'MODAL' : {
                            'DIGITALHEADLINE'  : 'add / edit Digitale sales',
                            'PHYSICALHEADLINE' : 'add / edit Physic sales',
                            'MERCHHEADLINE'    : 'add / edit Merchandise sales',
                            'ADDSUBLICENSEHEADLINE' : 'add / edit Sublicen sesale',
                            'RETURNSHEADLINE'  : 'add / edit Retoure',
                        }
                    },
                    'IMPORT': {
                        'MCMSSALESSYNC' : {
                            'HEADLINE' : 'Import digital sales from MCMS Yavin',
                            'SELECTPERIODS' : 'Select the Statements to import',
                            'INCLUDESHOPS' : 'Group sales also by shops (much more data and very slow!)',
                            'ADDITIONALSETTINGS' : 'Additional settings',
                            'AUDIO' : 'Audio Sales',
                            'VIDEO' : 'Video Streams - will be imported as Audio Track Streams!'
                        },
                        'RUNNINGIMPORTS' : 'Running imports',
                        'FILESFORIMPORT' : 'Files to import',
                        'IMPORTEDFILES'  : 'Imported files',
                        'ERRORIMPORTS' : 'Error Imports',
                        'FILEIMPORT' : {
                            'TYPE' : 'Import Type',
                            'FILENAME' : 'Filename',
                            'IMPORTSETTINGS' : 'Import settings',
                            'INTERFACE' : 'Interface',
                            'CURRENCYCOURSE' : 'Exchange course',
                            'USERNAME' : 'User'
                        },
                        'IMPORTTABLES' : {
                            'FILENAME' : 'Filename',
                            'REVENUE' : 'Revenue',
                            'ROWS' : 'Rows',
                            'ERRORROWS' : 'Error rows',
                            'ERRORREVENUE' : 'Error revenue',
                            'IMPORTEDROWS' : 'Imported rows',
                            'IMPORTEDREVENUE' : 'Imported revenue',
                            'CURRENCYCOURSE' : 'Currency Course'
                        }
                    }
                }
            }

        }
    }
};
