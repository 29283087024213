import {AfterViewInit, Component, forwardRef, Input, OnChanges, OnInit} from '@angular/core';
import {MatFormFieldControl} from '@angular/material';
import {Observable, Subject} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {GlobalService} from '@/shared/services/global.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {isString} from 'util';


export interface Contract {

}

@Component({
  selector: 'contract-chooser',
  templateUrl: './contract-chooser.component.html',
  styleUrls: ['./contract-chooser.component.html'],
  providers: [{
    provide: MatFormFieldControl,
    useExisting: forwardRef(() => ContractChooserComponent),

  },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContractChooserComponent),
      multi: true
    }]
})


export class ContractChooserComponent implements AfterViewInit,ControlValueAccessor, MatFormFieldControl<ContractChooserComponent> {

  @Input() licensorId: any;

  stateChanges: Subject<void> = new Subject<void>();
  id = '0';
  placeholder = 'My Custom Input';
  ngControl = null;
  focused = false;
  empty = true;
  _value: any;
  private _disabled = false;
  formControl = new FormControl();
  required = false;
  errorState = false;
  contracts = [];
  contractCtrl = new FormControl();
  filteredContracts: Observable<Contract[]>;
  httpHeaders: HttpHeaders;

  constructor(private _globalService: GlobalService, private _translateService: TranslateService, private httpClient: HttpClient, private _auth: OAuthService) {

  }

  ngAfterViewInit(): void {
    let uri = '';

    this.contractCtrl.valueChanges
        .subscribe(value => {
          this._value = value;
          this.onChange(value);
          this.stateChanges.next();
        });

    this.httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + this._auth.getAccessToken());

    if (this.licensorId !== undefined && this.licensorId !== null) {
      uri = 'contracts/get?licensorId=' + this.licensorId + '&pageSize=999';
    } else {
      uri = 'contracts/get?pageSize=999';
    }

    this.httpClient.get('/api/' + uri,  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
        ( response: HttpEvent<any> ) => {

          switch (response.type) {

            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){
                this.contracts = response.body.data.items;
                this.filteredContracts = this.contractCtrl.valueChanges
                    .pipe(
                        startWith(''),
                        map(contract => contract ? this._filterContracts(contract) : this.contracts.slice()),
                    );
              }
              break;
          }
        },
        error => console.log(error)
    );

  }

  @Input()
  set disabled(disabled) {
    this._disabled = disabled;
    disabled ? this.formControl.disable() : this.formControl.enable();
    this.stateChanges.next();
  }
  get disabled(): boolean {
    return this._disabled;
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
    //console.log("ficlem");
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  onChange: any = () => { };

  onTouched: any = () => { };

  get shouldPlaceholderFloat(): any {
    return !!this.value;
  }
  get shouldLabelFloat(): any{
    return true;
  }

  changer(): void {
    console.log(this._value);

    this.onChange(this._value);
  }

  setDescribedByIds(ids: string[]): void {
  }

  onContainerClick(): void {
  }

  public get value(): any{
    return this._value;
  }

  public set value(v) {
    this._value = v;
    //this._value = v;
    this.onChange(this._value);
    this.onTouched();
  }

  writeValue(obj: any): void {
    //console.log(this.options);
    this._value = obj;
    this.contractCtrl.setValue(this._value);
  }

  private _filterContracts(value: string): Contract[] {

    let filterValue = '';

    if (value !== undefined && isString(value)) {
       filterValue = value.toLowerCase();
    } else {
       filterValue = '';
    }

    return this.contracts.filter(contract => contract.contract_identifier.toLowerCase().indexOf(filterValue) === 0);

  }

  displayFn(contract?: Contract): string | undefined {

    for (let i = 0; i < this.contracts.length; i++) {
      if (this.contracts[i].contract_id === contract) {
        return this.contracts[i].contract_identifier;
      }
    }

  }
}
