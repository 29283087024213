import { FuseNavigation } from '@fuse/types';

export const navigationAdmin =

    {
        id      : 'systemadministration',
        title   : 'Systemadministration',
        translate: 'NAV.SYSTEMADMINISTRATION.MAIN',
        type    : 'group',
        icon    : 'apps',
        children: [
            {
                id      : 'clients',
                title   : 'clients',
                translate: 'NAV.SYSTEMADMINISTRATION.CLIENTS',
                type    : 'item',
                icon    : 'business',
                url     : '/clients',
            },
            {
                id      : 'users',
                title   : 'users',
                translate: 'NAV.SYSTEMADMINISTRATION.USERS',
                type    : 'item',
                icon    : 'person',
                url     : '/users'
            },

            {
                id      : 'automator',
                title   : 'automator',
                translate: 'NAV.SYSTEMADMINISTRATION.AUTOMATOR',
                type    : 'item',
                icon    : 'watch_later',
                url     : '/automator'
            },
            {
                id      : 'apitest',
                title   : 'apitest',
                translate: 'NAV.SYSTEMADMINISTRATION.APITEST',
                type    : 'item',
                icon    : 'bug_report',
                url     : '/testapi'
            },

        ]
    };
