import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import {UsersService} from '@/main/users/users.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {Location} from '@angular/common';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material';
import {OAuthService} from 'angular-oauth2-oidc';
import {GlobalService} from '@/shared/services/global.service';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {


  @ViewChild('userForm') userForm: NgForm;
  userId: string;
  user: any = {clientId: 0};
  newPwd: any = {password: '', password2: ''};
  userinfo: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;


  constructor(
      private _location: Location,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private route: ActivatedRoute,
      private router: Router,
      private auth: OAuthService,
      private _formBuilder: FormBuilder,
      private _usersService: UsersService,
      private _globalService: GlobalService,
      public dialog: MatDialog
      ) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }


    canDeactivate(): Observable<boolean> | boolean {
        return this._globalService.canDeactivate();
    }

  ngOnInit(): void {

    this.userId = this.route.snapshot.paramMap.get('id');
    this.user.clientId = Number(this.route.snapshot.queryParamMap.get('clientId'));
    if (this.route.snapshot.queryParamMap.get('licensorId')) {
        this.user.licensor_id = Number(this.route.snapshot.queryParamMap.get('licensorId'));
    }

    if (this.userId !== 'new') {
        this.getOne(this.userId);
    }else{

        if (!this.route.snapshot.queryParamMap.get('clientId')){
            this.user.clientId = this._globalService.getUserClientId();
        }

        this._globalService.setFormData(this.user, Object.assign({}, this.user));
    }

  }

  back(): void {
    this._location.back();
  }

    /*
  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

   */

  save(): void{

     if (this.userForm.form.valid && this.newPwd.password === this.newPwd.password2) {

      let uri = 'users/save';
      if (this.userId !== 'new'){
        uri += '/' + this.userId;
      }
      this.user.password =  this.newPwd.password;
      this.user.password2 =  this.newPwd.password2;

      this._usersService.save(uri, this.user).subscribe(
          ( response: HttpEvent<any> ) => {

            switch (response.type) {

              case HttpEventType.Response:
                if ( response.body != null && response.body.success === true ) {

                    this._globalService.setFormData(this.user, Object.assign({}, this.user));

                    if (this.userId === 'new'){
                        this.askForContinueAdding();
                    }
                }
                break;
            }

          },
          error => {
            console.log(error);
          });
    }else{
        this.userForm.form.controls['password'].setErrors({'incorrect': true});
        this.userForm.form.controls['password2'].setErrors({'incorrect': true});
        this.newPwd.password = '';
        this.newPwd.password2 = '';
    }

  }


  askForContinueAdding(): void{

      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to add more items?';
      this.confirmDialogRef.afterClosed()
          .subscribe(result => {
              if ( result ){
                  this.newPwd.password = '';
                  this.newPwd.password2 = '';
                  this.user = {clientId: 0};
                  this.userForm.resetForm();
              }else{
                  this._location.back();
              }
              this.confirmDialogRef = null;
          });
  }

  getOne(uid: string): void{

    this._usersService.getOne(
        'users/get/' + uid,
    ).subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){

                this.user = response.body.data;
                this._globalService.setFormData(this.user, Object.assign({}, this.user));
              }
              break;
          }
        },
        error => {
          console.log("error get one: " );
          console.log(error);
        });
  }

    openHelp(): void {
        const dialogRef = this.dialog.open(HelpDialog, {
            //width: '500px',
            //height: '400px',
            //disableClose: true
            data: { helpText: 'HELPTEXT.DETAIL.TEXT' , helpHeadline : 'HELPTEXT.DETAIL.HEADLINE', helpTextList1: 'HELPTEXT.DETAIL.LIST1',},
        });
    }

}
