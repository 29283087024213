export const locale = {
    lang: 'de',
    data: {
        'USERS': {
            'OVERVIEW' : {
                'HEADLINE' : 'Benutzer',
                'TABLE' : {
                    'ID'          : 'ID',
                    'FIRSTNAME'   : 'Vorname',
                    'LASTNAME'    : 'Nachname',
                    'EMAIL'       : 'E-Mail',
                },
            },

            'EDIT' : {
                'VIEW' : {
                    'HEADLINE' : 'Benutzer',


                    'SIDEBAR' : {
                        'BUTTON' : {
                            'DELETE' : 'Mandant löschen',
                            'ADDUSER'   : 'Benutzer hinzufügen',
                        },
                    },
                },
            },

            'ADD' : {
                'MODAL' : {
                    'HEADLINE'              : 'Benutzer hinzufügen / bearbeiten',
                    'LASTNAME'              : 'Nachname',
                    'LASTNAMEREQMESSAGE'    : 'Nachname notwendig!',
                    'FIRSTNAME'             : 'Vorname',
                    'FIRSTNAMEREQMESSAGE'   : 'Vorname notwendig!',
                    'EMAIL'                 : 'E-Mail',
                    'EMAILREQMESSAGE'       : 'E-Mail notwendig!',
                    'PASSWORD'              : 'Passwort',
                    'PASSWORD2'              : 'Passwort wiederholen',
                    'PASSWORDREQMESSAGE'    : 'Passwort notwendig!',
                    'PASSWORDREQMESSAGE2'    : 'Passwortwiederholung notwendig!',
                    'CLIENTID'              : 'Mandanten ID',
                    'CLIENTIDREQMESSAGE'    : 'Mandanten ID notwendig!',
                    'LANGUAGE'              : 'Sprache',
                    'LANGUAGEREQMESSAGE'    : 'Sprache notwendig!',
                },
            },


            'SIDEBAR'   : {
                'BUTTON'    : {
                    'ADD'       : 'Mandanten anlegen',
                },
            },
        },

        'HELPTEXT' : {

                'OVERVIEW' : {
                    'HEADLINE' : 'Benutzerübersicht',
                    'TEXT' : 'Hier findest du eine Übersicht, über alle Benutzer.',
                    'LIST1' : 'Mit dem + kannst du neue Benutzer erstellen.',
                },

                'DETAIL' : {
                    'HEADLINE' : 'Benutzerdetailansicht',
                    'TEXT' : 'Hier findest du die Detailansicht zu dem gewählten Benutzer.',
                    'LIST1' : 'Nach dem Bearbeiten einfach oben speichern.',
                },

                'EDIT' : {
                    'HEADLINE' : 'Mandant bearbeiten',
                    'TEXT' : 'Hier kann der Mandant mit seinen Benutzern im Detail angeschaut und bearbeitet werden.',
                    'LIST1' : 'Die mit * markierten Felder sind Pflichtfelder und müssen ausgefüllt sein.',
                    'LIST2' : 'Die Währung muss korrekt eingestellt werden, hier geht es um die Währung in der der Mandant abrechne, nicht wie er bezahlt wird.',
                    'LIST3' : 'USt. ID ist ein Pflichtfeld und wird vorausgesetzt, da jede Firma eine SteuerID haben muss.',
                    'LIST4' : 'Die Benutzer, die zum Mandanten gehören, können hier angelegt und bearbeitet werden.',
                    'LIST5' : 'Es können nur Benutzer zu diesem Mandanten angelegt werden.',
                },

        },
    }
};
