import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsComponent } from './analytics.component';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService as AuthGuard} from '@/auth/auth-guard.service';
import {TranslateModule} from '@ngx-translate/core';
import {MatMenuModule} from '@angular/material/menu';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {ChartsModule} from 'ng2-charts';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {MatButtonModule} from '@angular/material/button';
import {FuseWidgetModule} from '../../../@fuse/components';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import { RevenueByLicensorListComponent } from './revenue-by-licensor-list/revenue-by-licensor-list.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';

const routes: Routes = [

    {
        path        : 'analytics',
        component: AnalyticsComponent,
        canActivate: [AuthGuard],
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes),
        TranslateModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTabsModule,


        ChartsModule,
        NgxChartsModule,

        FuseSharedModule,
        FuseWidgetModule,
        MatToolbarModule,
        MatCardModule,
        MatPaginatorModule,
        MatTableModule,
        TranslateModule,
    ],
  declarations: [AnalyticsComponent, RevenueByLicensorListComponent]
})
export class AnalyticsModule { }
