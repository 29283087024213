import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {GlobalService} from '@/shared/services/global.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-revenue-by-licensor-list',
  templateUrl: './revenue-by-licensor-list.component.html',
  styleUrls: ['./revenue-by-licensor-list.component.scss']
})
export class RevenueByLicensorListComponent implements OnInit, OnChanges {

   @Input() revenue;
   @Input() digital = false;

   @ViewChild(MatPaginator) paginator: MatPaginator;

   dataSource: MatTableDataSource<any>;
   displayedColumns = [];

  constructor(
      private _globalService: GlobalService
  ) { }

  ngOnInit() {

      this.dataSource = new MatTableDataSource<any>(this.revenue);
      this.dataSource.paginator   = this.paginator;

      if (this.digital) {
         this.displayedColumns = ['licensor_id','name','revenue','bundles','streams','tracks'];
      } else {
          this.displayedColumns = ['licensor_id','name','revenue'];
      }

  }

  ngOnChanges(changes: SimpleChanges): void {
      this.dataSource = new MatTableDataSource<any>(changes.revenue.currentValue);
      this.dataSource.paginator   = this.paginator;
  }

}
