import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import {locale as german} from './i18n/de';
import {locale as english} from './i18n/en';
import {FuseTranslationLoaderService} from '../../@fuse/services/translation-loader.service';

import { first } from 'rxjs/operators';
import {OAuthService} from 'angular-oauth2-oidc';
import {GlobalService} from '@/shared/services/global.service';

@Component({
    selector     : 'login',
    templateUrl  : './login.component.html',
    styleUrls    : ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class LoginComponent implements OnInit
{
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private route: ActivatedRoute,
        private router: Router,
        private oauthService: OAuthService,
        private _globalService: GlobalService
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        // reset login status


        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
        this.error = '';
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;

        this.oauthService.fetchTokenUsingPasswordFlow(this.f.email.value, this.f.password.value).then((resp) => {

            // Loading data about the user
            return this.oauthService.loadUserProfile();

        }).then(() => {

            // Using the loaded user data
            // let claims = this.oauthService.getIdentityClaims();
            // if (claims) console.debug('given_name', claims);

            //console.debug(this.oauthService.getIdentityClaims());

            this._globalService.Init();

            this.router.navigateByUrl('/');

        }).catch((resp) => {
            this.error = resp.error;
            this.submitted = false;
        });

    }

}
