import { HttpEvent, HttpEventType} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit, EventEmitter, Input} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatSort} from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {fuseAnimations} from '@fuse/animations';
import {ContractsService} from '@/main/contracts/contracts.service';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {GlobalService} from '@/shared/services/global.service';

@Component({
    selector: 'app-contract-list',
    templateUrl: './contract-list.component.html',
    styleUrls: ['./contract-list.component.scss'],
    animations   : fuseAnimations
})

export class ContractListComponent implements AfterViewInit  {

    @Input() licensorId: any;

    displayedColumns: string[] = ['checkbox', 'contract_id', 'contract_identifier', 'name'  ];
    data: any[] = [];
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    filterInt: any;
    filterEvent: EventEmitter<String> = new EventEmitter(false);

    selectedContracts: string[] = [];
    isChecked = false;

    resultsLength = 0;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor( private _fuseTranslationLoaderService: FuseTranslationLoaderService,
                 private _fuseSidebarService: FuseSidebarService,
                 private _contractsService: ContractsService,
                 private _globalService: GlobalService,
                 public dialog: MatDialog) {

        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }


    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        this.filterEvent.subscribe(() => this.paginator.pageIndex = 0);



        merge(this.sort.sortChange, this.paginator.page, this.filterEvent)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._contractsService.loadContracts(
                        'contracts/get',
                        this._contractsService._contractsfilterValue,
                        this.sort.direction,
                        this.sort.active,
                        this.paginator.pageIndex,
                        this.paginator.pageSize,
                        this.licensorId
                    );
                }),
                map(data => {
                    // Flip flag to show that loading has finished.
                    this.resultsLength = data.total_count;
                    return data.data.items;
                }),
                catchError(() => {
                    return observableOf([]);
                })
            ).subscribe(data => this.data = data);

    }


    onSelectedChange(id: any): void{

        if ( this.selectedContracts.length > 0 ){

            const index = this.selectedContracts.indexOf(id);

            if ( index !== -1 ){

                this.selectedContracts.splice(index, 1);

                if ( this.selectedContracts.length === 0 ){
                    this.isChecked = false;
                }
                return;
            }
        }

        this.selectedContracts.push(id);
        this.isChecked = true;
    }


    askForDelete(): void{

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete all selected items?';

        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {
                    this.delete();
                }
                this.confirmDialogRef = null;
            });
    }

    delete(): void{

        const formData = new FormData();
        formData.append('ids', JSON.stringify(this.selectedContracts ));


        this._contractsService.delete('contracts/delete', formData).subscribe(
            ( response: HttpEvent<any> ) => {

                switch (response.type) {

                    case HttpEventType.Response:
                        if ( response.body != null && response.body.success === true ){

                            this.selectedContracts.length = 0;
                            this.isChecked = false;
                            this.filterEvent.emit(this._contractsService._contractsfilterValue);

                        }
                        break;
                }
            },
            error => {
                console.log(error);
            });

    }

    /*
    toggleSidebar(name): void
    {
      this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
     */

    applyFilter(filterValue: string): void  {
        clearInterval(this.filterInt);
        this.filterInt = setInterval(() => { this.callFilter(filterValue); }, 1000);
    }

    callFilter(filterValue: string): void {
        clearInterval(this.filterInt);
        this._contractsService._contractsfilterValue = filterValue;
        this.filterEvent.emit(this._contractsService._contractsfilterValue);
    }

    openHelp(): void {
        const dialogRef = this.dialog.open(HelpDialog, {
            //width: '500px',
            //height: '400px',
            //disableClose: true
            data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1'},
        });
    }

}

