import {Component, forwardRef, Input, OnChanges, OnInit} from '@angular/core';
import {MatFormFieldControl} from '@angular/material';
import {Observable, Subject} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {GlobalService} from '@/shared/services/global.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';


export interface Country {
  code: string;
  en: string;
  de: string;
}

@Component({
  selector: 'app-country-autocomplete',
  templateUrl: './country-autocomplete.component.html',
  styleUrls: ['./country-autocomplete.component.scss'],
  providers: [{
    provide: MatFormFieldControl,
    useExisting: forwardRef(() => CountryAutocompleteComponent),

  },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryAutocompleteComponent),
      multi: true
    }]
})

export class CountryAutocompleteComponent implements ControlValueAccessor, MatFormFieldControl<CountryAutocompleteComponent> {


  stateChanges: Subject<void> = new Subject<void>();
  id = '0';
  placeholder = 'My Custom Input';
  ngControl = null;
  focused = false;
  empty = true;
  _value: any;
  private _disabled = false;
  formControl = new FormControl();
  required = false;
  errorState = false;
  countries = [];
  countryCtrl = new FormControl();
  filteredCountries: Observable<Country[]>;
  httpHeaders: HttpHeaders;

  constructor(private _globalService: GlobalService, private _translateService: TranslateService, private httpClient: HttpClient, private _auth: OAuthService) {
    this.countryCtrl.valueChanges
        .subscribe(value => {
          this._value = value;
          this.onChange(value);
          this.stateChanges.next();
        });

    this.httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + this._auth.getAccessToken());

    this.httpClient.get('/api/iso/countriesForFiltering',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
        ( response: HttpEvent<any> ) => {

          switch (response.type) {

            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){
                this.countries = response.body.data.items;
                this.filteredCountries = this.countryCtrl.valueChanges
                    .pipe(
                        startWith(''),
                        map(country => country ? this._filterCountries(country) : this.countries.slice()),
                    );
              }
              break;
          }
        },
        error => console.log(error)
    );



  }

  @Input()
  set disabled(disabled) {
    this._disabled = disabled;
    disabled ? this.formControl.disable() : this.formControl.enable();
    this.stateChanges.next();
  }
  get disabled(): boolean {
    return this._disabled;
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
    //console.log("ficlem");
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  onChange: any = () => { };

  onTouched: any = () => { };

  get shouldPlaceholderFloat(): any {
    return !!this.value;
  }
  get shouldLabelFloat(): any{
    return true;
  }

  changer(): void {
    console.log(this._value);

    this.onChange(this._value);
  }

  setDescribedByIds(ids: string[]): void {
  }

  onContainerClick(): void {
  }

  public get value(): any{
    return this._value;
  }

  public set value(v) {
    this._value = v;
    //this._value = v;
    this.onChange(this._value);
    this.onTouched();
  }

  writeValue(obj: any): void {
    //console.log(this.options);
    this._value = obj;
    this.countryCtrl.setValue(this._value);
  }

  private _filterCountries(value: string): Country[] {
    const filterValue = value.toLowerCase();

    return this.countries.filter(country => country.filterColumn.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFn(country?: Country): string | undefined {

    let language = this._translateService.currentLang;

    for (let i = 0; i < this.countries.length; i++) {
      if (this.countries[i].code === country) {
        return this._globalService.countries[i][language];
      }
    }

  }
}
