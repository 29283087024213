import { HttpEvent, HttpEventType} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatSort} from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {fuseAnimations} from '@fuse/animations';
import {UsersService} from '@/main/users/users.service';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';


@Component({
  selector: 'app-users-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  animations   : fuseAnimations
})

export class UserListComponent implements AfterViewInit  {

  @Input() clientId: any;
  @Input() licensorId: any;
  isChecked = false;

  displayedColumns: string[] = ['checkbox', 'id', 'firstname', 'lastname', 'email' ];
  data: any[] = [];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  filterValue: string;
  filterInt: any;
  filterEvent: EventEmitter<String> = new EventEmitter(false);
  selectedUsers: string[] = [];

  resultsLength = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor( private _fuseTranslationLoaderService: FuseTranslationLoaderService,
               private _usersService: UsersService,
               public dialog: MatDialog) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }


  ngAfterViewInit(): void {

      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      this.filterEvent.subscribe(() => this.paginator.pageIndex = 0);

      merge(this.sort.sortChange, this.paginator.page, this.filterEvent)
          .pipe(
              startWith({}),
              switchMap(() => {

                  return this._usersService.loadUsers(
                      'users/get',
                      this.filterValue,
                      this.sort.direction,
                      this.sort.active,
                      this.paginator.pageIndex,
                      this.paginator.pageSize,
                      this.clientId,
                      this.licensorId
                      );
              }),
              map(data => {
                  // Flip flag to show that loading has finished.
                  this.resultsLength = data.total_count;
                  return data.data.items;
              }),
              catchError(() => {
                  return observableOf([]);
              })
          ).subscribe(data => this.data = data);


  }


  onSelectedChange(id: any): void{

    if ( this.selectedUsers.length > 0 ){

          const index = this.selectedUsers.indexOf(id);

          if ( index !== -1 ){

              this.selectedUsers.splice(index, 1);

              if ( this.selectedUsers.length === 0 ){
                  this.isChecked = false;
              }
            return;
          }
    }

    this.selectedUsers.push(id);
    this.isChecked = true;
  }


  askForDelete(): void{

    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete all selected items?';

    this.confirmDialogRef.afterClosed()
        .subscribe(result => {
          if ( result )
          {
            this.delete();
          }
          this.confirmDialogRef = null;
        });
  }

  delete(): void{

    const formData = new FormData();
    formData.append('ids', JSON.stringify(this.selectedUsers));

    //console.log(JSON.stringify(this.selectedUsers))

    this._usersService.delete('users/delete', formData).subscribe(
         ( response: HttpEvent<any> ) => {

           switch (response.type) {

             case HttpEventType.Response:
               if ( response.body != null && response.body.success === true ){


                   this.selectedUsers.length = 0;
                   this.isChecked = false;
                   this.filterEvent.emit(this.filterValue);

               }
               break;
           }
         },
         error => {
           console.log(error);
         });

  }

  applyFilter(filterValue: string): void  {
    clearInterval(this.filterInt);
    this.filterInt = setInterval(() => { this.callFilter(filterValue); }, 1000);
  }

  callFilter(filterValue: string): void {
    clearInterval(this.filterInt);
    this.filterValue = filterValue;
    this.filterEvent.emit(this.filterValue);
  }

  openHelp(): void {
    const dialogRef = this.dialog.open(HelpDialog, {
      //width: '500px',
      //height: '400px',
      //disableClose: true
      data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1',},
    });
  }
  
}

