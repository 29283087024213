import {AfterViewInit, Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {FuseSidebarService} from '../../../../../@fuse/components/sidebar/sidebar.service';
import {AccountingService} from '@/main/accounting/accounting.service';
import {GlobalService} from '@/shared/services/global.service';
import {MatDialog, MatDialogRef, MatPaginator, MatSort} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {locale as english} from '@/main/accounting/i18n/en';
import {locale as german} from '@/main/accounting/i18n/de';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {FuseConfirmDialogComponent} from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {AddPeriodDialogComponent} from '@/main/accounting/addPeriodDialog/add-period-dialog.component';
import {AddDigitalSalesModalComponent} from '@/main/accounting/royalty-periods-detail/revenue-digital/add-digital-sales-modal/add-digital-sales-modal.component';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {AddMerchSalesModalComponent} from '@/main/accounting/royalty-periods-detail/revenue-merch/add-merch-sales-modal/add-merch-sales.component';
import {AddSublicenseRevenueModalComponent} from '@/main/accounting/royalty-periods-detail/revenue-sublicense/add-sublicense-revenue-modal/add-sublicense-revenue-modal.component';

@Component({
  selector: 'app-revenue-sublicense',
  templateUrl: './revenue-sublicense.component.html',
  styleUrls: ['./revenue-sublicense.component.scss', './../../accounting.component.scss']
})
export class RevenueSublicenseComponent implements AfterViewInit {

  @Input() periodId: any;
  @Input() clientId: any;
  @Input() periodClosed: any;

  selectedRows: string[] = [];
  isChecked = false;

  resultsLength = 0;
  revenueSum = 0;

  displayedColumns: string[] = ['checkbox', 'ean', 'upc', 'isrc', 'release_title', 'track_title', 'description', 'revenue'];
  data: any[] = [];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  filterValue: string;
  filterInt: any;

  filterEvent: EventEmitter<String> = new EventEmitter(false);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
              private _fuseSidebarService: FuseSidebarService,
              private _accountingService: AccountingService,
              private _globalService: GlobalService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router) {
    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }

  ngAfterViewInit(): void {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.filterEvent.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, this.filterEvent)
        .pipe(
            startWith({}),
            switchMap(() => {

              return this._accountingService.loadSales(
                  'accounting/sales/sublicenses',
                  this.filterValue,
                  this.sort.direction,
                  this.sort.active,
                  this.paginator.pageIndex,
                  this.paginator.pageSize,
                  this.periodId
              );
            }),
            map(data => {
              // Flip flag to show that loading has finished.
              this.resultsLength = data.total_count;
              this.revenueSum = data.data.revenueSum;
              return data.data.items;
            }),
            catchError(() => {
              return observableOf([]);
            })
        ).subscribe(data => this.data = data);


  }

  openAddNewModal(periodId: any, row: any): void
  {

      if (!this.periodClosed) {
          const dialogRef = this.dialog.open(AddSublicenseRevenueModalComponent, {
              data: {'periodId': periodId, 'clientId': this.clientId, 'salesRow': row},
              disableClose: true
          });

          dialogRef.afterClosed().subscribe(result => {

              this.filterEvent.emit(this.filterValue);

          });
      }

  }

  getData(): void {

  }

  applyFilter(filterValue: string):void  {
    clearInterval(this.filterInt);
    this.filterInt = setInterval(() => { this.callFilter(filterValue); }, 1000);
  }

  callFilter(filterValue: string):void {
    clearInterval(this.filterInt);
    this.filterValue = filterValue;
    this.filterEvent.emit(this.filterValue);
  }

  onSelectedChange(id: any): void{

    if ( this.selectedRows.length > 0 ){

      const index = this.selectedRows.indexOf(id);

      if ( index !== -1 ){

        this.selectedRows.splice(index, 1);

        if ( this.selectedRows.length === 0 ){
          this.isChecked = false;
        }
        return;
      }
    }

    this.selectedRows.push(id);
    this.isChecked = true;
  }

  askForDelete(): void{

    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete all selected items?';

    this.confirmDialogRef.afterClosed()
        .subscribe(result => {
          if ( result )
          {
            this.delete();
          }
          this.confirmDialogRef = null;
        });
  }

  delete(): void {

    const formData = new FormData();
    formData.append('ids', JSON.stringify(this.selectedRows ));


    this._accountingService.deleteSublicenseRevenue(formData).subscribe(
        ( response: HttpEvent<any> ) => {

          switch (response.type) {

            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){

                this.selectedRows.length = 0;
                this.isChecked = false;
                this.filterEvent.emit(this.filterValue);

              }
              break;
          }
        },
        error => {
          console.log(error);
        });
  }

}
