import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(public auth: OAuthService, public router: Router) {}

    canActivate(): boolean {

        if (!this.auth.hasValidAccessToken()) {
            this.router.navigate(['login']);
            return false;
        }
        /*
        else {
            console.log(this.auth.getIdentityClaims());
        }
        */
        return true;
    }

}
