import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountingComponent } from './accounting.component';
import { RoyaltyPeriodsComponent } from './royalty-periods/royalty-periods.component';
import {RouterModule} from '@angular/router';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule, MatTreeModule
} from '@angular/material';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {TranslateModule} from '@ngx-translate/core';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {FuseSidebarModule} from '../../../@fuse/components';
import {UsersModule} from '@/main/users/users.module';
import {SharedModule} from '@/shared/shared.module';
import {AuthGuardService as AuthGuard} from '@/auth/auth-guard.service';
import {ClientsService} from '@/main/clients/clients.service';
import {ClientsComponent} from '@/main/clients/clients.component';
import {ClientDetailComponent} from '@/main/clients/client-detail/client-detail.component';
import {AccountingService} from '@/main/accounting/accounting.service';
import {AddPeriodDialogComponent} from '@/main/accounting/addPeriodDialog/add-period-dialog.component';
import { RoyaltyPeriodsDetailComponent } from './royalty-periods-detail/royalty-periods-detail.component';
import { RevenueDigitalComponent } from './royalty-periods-detail/revenue-digital/revenue-digital.component';
import { RevenuePhysicalComponent } from './royalty-periods-detail/revenue-physical/revenue-physical.component';
import { RevenueMerchComponent } from './royalty-periods-detail/revenue-merch/revenue-merch.component';
import { RevenueSublicenseComponent } from './royalty-periods-detail/revenue-sublicense/revenue-sublicense.component';
import { SalesImportComponent } from './sales-import/sales-import.component';
import { ReturnsComponent } from './royalty-periods-detail/returns/returns.component';
import { AddDigitalSalesModalComponent } from './royalty-periods-detail/revenue-digital/add-digital-sales-modal/add-digital-sales-modal.component';
import {AddSoundRecordingSalesModalComponent} from '@/main/accounting/royalty-periods-detail/revenue-physical/add-sound-recording-sales-modal/add-sound-recording-sales-modal.component';
import {AddMerchSalesModalComponent} from '@/main/accounting/royalty-periods-detail/revenue-merch/add-merch-sales-modal/add-merch-sales.component';
import {AddSublicenseRevenueModalComponent} from '@/main/accounting/royalty-periods-detail/revenue-sublicense/add-sublicense-revenue-modal/add-sublicense-revenue-modal.component';
import {AddReturnsModalComponent} from '@/main/accounting/royalty-periods-detail/returns/add-returns-modal.component/add-returns-modal.component';
import { McmsSalesSyncModalComponent } from './sales-import/mcms-sales-sync-modal/mcms-sales-sync-modal.component';
import { ImportedFilesComponent } from './sales-import/imported-files/imported-files.component';
import { RunningImportsComponent } from './sales-import/running-imports/running-imports.component';
import {LicensorsModule} from '@/main/licensors/licensors.module';
import { ErrorImportsComponent } from './sales-import/error-imports/error-imports.component';


const routes = [
  {
    path     : 'accounting',
    component: AccountingComponent,
    canActivate: [AuthGuard],
  },
  {
    path    : 'accounting/periods/:id',
    component: RoyaltyPeriodsDetailComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    AccountingComponent,
    RoyaltyPeriodsComponent,
    AddPeriodDialogComponent,
    RoyaltyPeriodsDetailComponent,
    RevenueDigitalComponent,
    RevenuePhysicalComponent,
    RevenueMerchComponent,
    RevenueSublicenseComponent,
    SalesImportComponent,
    ReturnsComponent,
    AddDigitalSalesModalComponent,
    AddSoundRecordingSalesModalComponent,
    AddMerchSalesModalComponent,
    AddSublicenseRevenueModalComponent,
      AddReturnsModalComponent,
      McmsSalesSyncModalComponent,
      ImportedFilesComponent,
      RunningImportsComponent,
      ErrorImportsComponent,

  ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatMomentDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        TranslateModule,
        FuseSharedModule,
        FuseSidebarModule,
        UsersModule,
        SharedModule,
        LicensorsModule
    ],
  providers   : [
    AccountingService
  ],
  entryComponents: [
      AddPeriodDialogComponent,
    AddDigitalSalesModalComponent,
    AddSoundRecordingSalesModalComponent,
    AddMerchSalesModalComponent,
    AddSublicenseRevenueModalComponent,
    AddReturnsModalComponent,
      McmsSalesSyncModalComponent
  ],
    exports: [
        AccountingComponent,
        AddPeriodDialogComponent,
        AddDigitalSalesModalComponent,
        AddSoundRecordingSalesModalComponent,
        AddMerchSalesModalComponent,
        AddSublicenseRevenueModalComponent,
        AddReturnsModalComponent,
        RoyaltyPeriodsComponent
    ]
})
export class AccountingModule { }
