import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA, MatDialog, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter} from '@angular/material';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {ClientsService} from '@/main/clients/clients.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {GlobalService} from '@/shared/services/global.service';
import {AccountingService} from '@/main/accounting/accounting.service';
import {MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import {MomentUtcDateAdapter} from '@/shared/momentUtcAdapter';


export interface DialogData {
    filename: string;
    uuid: any;
    group: any;
}

@Component({
    selector:       'add-period-dialog',
    templateUrl:    'add-period-dialog.component.html',
    providers : [
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    ]
})
export class AddPeriodDialogComponent implements OnInit {

    form: FormGroup;
    period: any = {
        'client_id' : 0
    };

    @ViewChild('periodAddModalForm') periodAddModalForm: NgForm;

    constructor(
        public dialogRef: MatDialogRef<AddPeriodDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _formBuilder: FormBuilder,
        private _globalService: GlobalService,
        private _accountingService: AccountingService,
        public dialog: MatDialog)
    {


    }

    ngOnInit(): void {

        this.period.client_id = this._globalService.getUserClientId();

    }


    onNoClick(): void {

        this.dialogRef.close(false);
    }



    save(): void
    {

        if (this.periodAddModalForm.form.valid) {

            let uri = 'accounting/addPeriod';

            this._accountingService.addPeriod(uri, this.period).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this.dialogRef.close(false);
                            }
                            break;
                    }

                },
                error => {
                    console.log(error);
                });
        }else{

        }

    }




}
