import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import {TracksService} from '@/main/tracks/tracks.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {Location} from '@angular/common';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material';
import {GlobalService} from '@/shared/services/global.service';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-track-detail',
  templateUrl: './track-detail.component.html',
  styleUrls: ['./track-detail.component.scss']
})
export class TrackDetailComponent implements OnInit {

  @ViewChild('trackForm') trackForm: NgForm;

  // @ViewChild('jsonInput') jsonInput: JsonInputComponent;

  // @ViewChild('userList') _userList: ExpensetypeListComponent;

  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  trackId: string;
  track = {track_id: 0, client_id: 0};
  ISRC: string;
  ISRClist: [];

  artists_options = {
        'unique_value': true,
        'field1' : {
            'key'       : 'role',
            'label'     : 'Role',
            'type'      : 'dropdown',
            'source'    : 'array',
            'array'     : this._globalService.artistRoles
        },
        'field2'    : {
            'key'   : 'name',
            'label' : 'Name',
            'type'  : 'text'
        }
    };

  constructor(
      private _location: Location,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private route: ActivatedRoute,
      private router: Router,
      private _formBuilder: FormBuilder,
      private _tracksService: TracksService,
      private _globalService: GlobalService,
      public dialog: MatDialog
      ) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);

  }

    canDeactivate(): Observable<boolean> | boolean {
        return this._globalService.canDeactivate();
    }

  ngOnInit(): void {

    this.trackId = this.route.snapshot.paramMap.get('id');

    this.track.client_id = this._globalService.getUserClientId();

    if (this.trackId !== 'new') {
        // this.showUserList = false;
        this.getOne(this.trackId);
    }else{
        // this.showUserList = true;
        // this.contract.licensor_id = this._globalService.getUserClientId();
        if (this.route.snapshot.queryParamMap.get('trackId')){
            this.track.track_id = Number(this.route.snapshot.queryParamMap.get('trackId'));
        }
        this._globalService.setFormData(this.track, Object.assign({}, this.track));
    }

    this.getISRCList();
  }


  addISRC(): void {
     if (this.ISRC !== '') {

         this._tracksService.saveISRC('tracks/saveISRC', {track_id: this.trackId, code: this.ISRC}).subscribe(
              (response: HttpEvent<any>) => {
                  switch (response.type) {
                      case HttpEventType.Response:
                          if (response.body != null && response.body.success === true) {
                              this.ISRC = '';
                              this.getISRCList();
                          }
                          break;
                  }
              },
             error => {
                  console.log(error);
             });
     }
  }

  getISRCList(): any {

      this._tracksService.getISRCList(
          'tracks/getISRCList/' + this.trackId,
      ).subscribe(
          ( response: HttpEvent<any> ) => {
              switch (response.type) {
                  case HttpEventType.Response:

                      if ( response.body != null && response.body.success === true ){
                          this.ISRClist = response.body.data;
                      }
                      break;
              }
          },
          error => {
              console.log('error get one: ' );
              console.log(error);
          });
  }

  deleteISRC(code: string): any {
       //console.log(code)
       this._tracksService.delete(
           'tracks/deleteISRC',
           {code: code}
       ).subscribe(
           ( response: HttpEvent<any> ) => {
               switch (response.type) {
                   case HttpEventType.Response:
                       if ( response.body != null && response.body.success === true ){
                           this.getISRCList();
                       }
                       break;
               }
           },
           error => {
               console.log('error deleteEANUPC: ' );
               console.log(error);
           });
  }

  back(): void {
    this._location.back();
  }

  askForContinueAdding(): void{

      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to add more items?';
      this.confirmDialogRef.afterClosed()
          .subscribe(result => {
              if ( result ){
                  this.track = {track_id: 0, client_id: this._globalService.userinfo.clientId};
                  this.trackForm.resetForm();
              }else{
                  this._location.back();
              }
              this.confirmDialogRef = null;
          });
  }

  save(): void{

    if (this.trackForm.form.valid) {

      let uri = 'tracks/save';
      if (this.trackId !== 'new'){
        uri += '/' + this.trackId;
      }

      this._tracksService.save(uri, this.track ).subscribe(
          ( response: HttpEvent<any> ) => {

            switch (response.type) {
              case HttpEventType.Response:
                if ( response.body != null && response.body.success === true ){

                    this._globalService.setFormData(this.track, Object.assign({}, this.track));

                    if (this.trackId === 'new') {
                        this.askForContinueAdding();
                    }
                }
                break;
            }

          },
          error => {
            console.log(error);
          });
    }

  }

  getOne(uid: string): void{

    this._tracksService.getOne(
        'tracks/get/' + uid,
    ).subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){
                // console.log(response.body);
                this.track = response.body.data;
                this._globalService.setFormData(this.track, Object.assign({}, this.track));
              }
              break;
          }
        },
        error => {
          console.log("error get one contracts: " );
          console.log(error);
        });
  }

  openHelp(): void {
       const dialogRef = this.dialog.open(HelpDialog, {
           // width: '500px',
           // height: '400px',
           // disableClose: true
           data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1',},
       });
  }

}
