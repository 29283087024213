import { Component, OnDestroy, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
import {locale as german } from './i18n/de';
import {locale as english } from './i18n/en';
//import { AngularFileUploaderModule } from "angular-file-uploader";
import { FileService } from '../../shared/services/file.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})

export class AccountComponent implements OnInit {

    accountdetailsform: FormGroup;
    userInfo: any;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _auth: OAuthService,
        private _formBuilder: FormBuilder,
        private fileService: FileService
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

  ngOnInit(): void {

      this.userInfo = this._auth.getIdentityClaims();

      this.accountdetailsform = this._formBuilder.group({
          mandant   : [
              {
                  value   : this.userInfo.mandantname,
                  disabled: true
              }, Validators.required
          ],
          firstName : [this.userInfo.firstname, Validators.required],
          lastName  : [this.userInfo.lastname, Validators.required],
          email  : [this.userInfo.email, Validators.required],
          thumbnail : [''],
          preferedLanguage   : [this.userInfo.prefered_language, Validators.required]
      });


  }


  sendData(){

      const formData = new FormData();
      formData.append('userimage', this.accountdetailsform.get('thumbnail').value);
      formData.append('email', this.accountdetailsform.get('email').value);
      formData.append('firstname', this.accountdetailsform.get('firstName').value);
      formData.append('lastname', this.accountdetailsform.get('lastName').value);


      this.fileService.updateUserData(formData).subscribe(
          (response) => {
              //response code
              console.log(response);
          });
  }

  onFileSelect(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.accountdetailsform.get('thumbnail').setValue(file);
        }
  }

}
