import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import {SnackbarComponent} from '@/main/components/snackbar/snackbar.component';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {

    constructor(public _snackbar: SnackbarComponent, private oauthService: OAuthService ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {

                    if (event.body.message !== undefined && event.body.message !== '') {

                        const msg = event.body.message;
                        this._snackbar.openSnackBar(msg,'X');
                    }

                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {

                //console.log(error);
                if (error.status !== 404) {
                    const errorMsg = error.status + ' : ' + error.statusText + ' : ' + error.url ;
                    this._snackbar.openSnackBar(errorMsg,'X');
                }
                    return throwError(error);


            }));
    }
}
