import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApitestComponent} from '@/main/apitest/apitest.component';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatStepperModule } from '@angular/material';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FuseSharedModule} from '../../../@fuse/shared.module';

const routes = [
    {
        path     : 'testapi',
        component: ApitestComponent
    }
];

@NgModule({
  imports: [
    CommonModule,
      RouterModule.forChild(routes),
      MatButtonModule,
      MatFormFieldModule,
      MatIconModule,
      MatInputModule,
      MatSelectModule,
      MatStepperModule,
      TranslateModule,

      FuseSharedModule
  ],
  declarations: [
      ApitestComponent
  ],
  exports: [
      ApitestComponent
  ]
})
export class ApitestModule { }
