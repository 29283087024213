import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {TrackListComponent} from '@/main/tracks/track-list/track-list.component';

export interface DialogData {
    dialogHeadline: string;
    dialogText: string;
    notContractId: string;
}

@Component({
    selector: 'track-dialog-component',
    templateUrl: 'track-dialog.component.html',
})

export class TrackDialogComponent{


    @ViewChild('trackList') _trackList: TrackListComponent;


    constructor(public dialogRef: MatDialogRef<TrackDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

     closeTrackDialog(data = false): void {
        if (data){
            this.dialogRef.close({data: this._trackList.selectedTracks });
        }else{
            this.dialogRef.close();
        }
     }
}
