export const locale = {
    lang: 'de',
    data: {
        'NAV': {
            'DASHBOARD'     : {
                'TITLE': 'Dashboard',
                'BADGE': '25'
            },

            'MASTERDATA': {
                'MAIN' : 'Stammdaten',
                'LICENSOR': 'Lizenzgeber',
                'PRODUCT': 'Produkte',
                'CONTRACT': 'Verträge',
                'EXPENSETYPES': 'Kosten-Typen',
                'LICENSDATA' : 'Lizenzdaten',
                'CATALOG' : 'Katalog',
                'TRACK' : 'Tracks',
                'PROMOTIONPERIODS' : 'Promotionszeiträume',
            },

            'ACCOUNTING': {
                'ACCOUNTING' : 'Accounting',
                'MAIN' : 'Abrechnungen',
                'FILE_MANAGER': 'Datei-Manager',
            },

            'ANALYTICS' : {
                'MAIN' : 'Analytics',
                'REVENUE' : 'Umsatz'
            },

            'SYSTEMADMINISTRATION' : {
                'MAIN' : 'Systemadministration',
                'USERADMINISTRATION' : 'Mandanten / Userkonten',
                'CLIENTS' : 'Mandanten',
                'USERS' : 'Benutzer',
                'APITEST' : 'API testen',
                'FILEUPLOAD' : 'Fileuploader',
                'AUTOMATOR' : 'Automator',
            },
        }
    }
};
