import {Component, OnInit, Input, AfterViewInit, ViewChild, EventEmitter} from '@angular/core';
import {locale as english} from '@/main/contracts/i18n/en';
import {locale as german} from '@/main/contracts/i18n/de';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {FuseTranslationLoaderService} from '../../../../@fuse/services/translation-loader.service';
import {MatDialog, MatPaginator, MatSort} from '@angular/material';
import {ContractsService} from '@/main/contracts/contracts.service';
import {AccountingService} from '@/main/accounting/accounting.service';
import {ActivatedRoute} from '@angular/router';
import {GlobalService} from '@/shared/services/global.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {ImportedFilesComponent} from '@/main/accounting/sales-import/imported-files/imported-files.component';
import {RunningImportsComponent} from '@/main/accounting/sales-import/running-imports/running-imports.component';
import {ErrorImportsComponent} from '@/main/accounting/sales-import/error-imports/error-imports.component';
import {InterfaceCreatorComponent} from '@/main/components/interface-creator/interface-creator.component';

@Component({
  selector: 'app-sales-import',
  templateUrl: './sales-import.component.html',
  styleUrls: ['./sales-import.component.scss']
})
export class SalesImportComponent implements AfterViewInit {

  @Input() periodId: any;
  @Input() periodClosed: any;

  displayedColumns: string[] = ['type', 'filename', 'importsettings', 'action' ];
  selectedRows: string[] = [];
  isChecked = false;
  filterEvent: EventEmitter<String> = new EventEmitter(false);
  filterValue: string;
  resultsLength = 0;
  data: any[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(ImportedFilesComponent) importedFiles: ImportedFilesComponent;
  @ViewChild(RunningImportsComponent) runningImports: RunningImportsComponent;
  @ViewChild(ErrorImportsComponent) errorImports: ErrorImportsComponent;

  tabMapping = {
      0 : 'this',
      1 : 'importedFiles',
      2 : 'runningImports',
      3 : 'errorImports'
  };



  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
              private _accountingService: AccountingService,
              private route: ActivatedRoute,
              private _globalService: GlobalService,
              public dialog: MatDialog)
  {
      this._fuseTranslationLoaderService.loadTranslations(english, german);
  }

  ngAfterViewInit(): void {


      this.periodId = this.route.snapshot.paramMap.get('id');

      //console.log("this.periodId: " + this.periodId);

      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      this.filterEvent.subscribe(() => this.paginator.pageIndex = 0);

      merge(this.sort.sortChange, this.paginator.page, this.filterEvent)
          .pipe(
              startWith({}),
              switchMap(() => {
                  return this._accountingService.loadAutomatorTasks(
                      this.filterValue,
                      this.sort.direction,
                      this.sort.active,
                      this.paginator.pageIndex,
                      this.paginator.pageSize,
                      this.periodId
                  );
              }),
              map(data => {
                  // Flip flag to show that loading has finished.
                  this.resultsLength = data.total_count;

                  //console.log(data.data.items)

                  return data.data.items;
              }),
              catchError(() => {
                  return observableOf([]);
              })
          ).subscribe(data => this.data = data);
   }

    deleteImportFile(row): void {
      this._globalService.confirmModal().subscribe(result => {
          if ( result )
          {
              this._accountingService.deleteAutomatorTask(row.id).subscribe(
                  (response: HttpEvent<any>) => {
                      switch (response.type) {

                          case HttpEventType.Response:
                              this.filterEvent.emit();
                      }
                  },
                  error => {
                      console.log(error);
                  });

          } else {
              console.log('no');
          }
      });
    }

    importFile(row): void {
        this._accountingService.startImportTask(row).subscribe(
            (response: HttpEvent<any>) => {
                switch (response.type) {

                    case HttpEventType.Response:
                        this.filterEvent.emit();
                }
            },
            error => {
                console.log(error);
            });
    }

    loadFreshDataInTab($event): void {
        if (this.tabMapping[$event] === 'importedFiles') {
            this.importedFiles.loadImportedFiles();
        }
        if (this.tabMapping[$event] === 'runningImports') {
            this.runningImports.loadImportingFiles();
        }
        if (this.tabMapping[$event] === 'errorImports') {
            this.errorImports.loadImportingFiles();
        }
        if (this.tabMapping[$event] === 'this') {
            this.filterEvent.emit();
        }

    }

    openInterfaceCreator(data): void{

        //console.log(data);
        data.period_id = this.periodId;

        const dialogRef = this.dialog.open(InterfaceCreatorComponent, {
            //width: '500px',
            //height: '400px',
            //disableClose: true
            data: data,
        });
    }

}
