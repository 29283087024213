export const locale = {
    lang: 'en',
    data: {
        'LICENSOR': {

            'OVERVIEW' : {
                'HEADLINE' : 'Licensor - overview',
                'TABLE' : {
                    'ID'        : 'ID',
                    'NAME'      : 'Name',
                    'STREET'    : 'Street',
                    'ZIP'       : 'ZIP',
                    'CITY'      : 'CITY',
                    'CONTACTPERSON'  : 'Contactperson',
                    'ACCOUNTBALANCE' : 'Account balance',
                    'TOTAL_DUE' : 'Total due',
                    'TOTAL_PAYABLE' : 'Total payable',
                    'APROVESTATEMENT' : 'Aprove statement',
                    'APROVEQUESTION' : 'Are you sure that you want to aprove this statement? This action can not be undone!',
                    'PUBLISHQUESTION' : 'Are you sure that you want to publish this statement?',
                    'UNPUBLISHQUESTION' : 'Are you sure that you want to unpublish this statement?'
                },
                'ADVANCE' : {
                    'COSTS_TYPE'    : 'TYP',
                    'NAME'          : 'Name',
                    'AMOUNT'        : 'Amount',
                    'ALREADY_BILLED': 'already billed',
                    'STILL_TO_BILL' : 'still to pay',
                    'RECOUPING_PERCENTAGE' : 'recouping percentage in %',
                    'CLEARING_VIA'  : 'recouping via',
                    'CLEARED'       : 'cleared',
                    'ADD' : {
                        'MODAL' : {
                            'HEADLINE' : 'Add Advance / Costs',
                            'COSTS_TYPE' : 'Costs / Advance Type',
                            'CONTRACT' : 'Contract',
                            'AMOUNT' : 'Amount',
                            'RECOUPING_PERCENTAGE' : 'recouping percentage in %',
                            'CLEARING_VIA' : 'recouping via',
                            'CONTRACT_REVENUE' : 'Revenue of this contract',
                            'STATEMENT_REVENUE' : 'Revenue of statement',
                            'NAME' : 'Description',
                            'SAVE' : 'Save',
                            'CANCEL' : 'Cancel',
                            'RELEASE' : 'Release'
                        }
                    }
                }
            },

            'ADD' : {
                'MODAL' : {
                    'CONTRACTS'         : 'Contracts',
                    'HEADLINE'          : 'add / edit Licensor',
                    'NAME'              : 'Name',
                    'NAMEREQMESSAGE'    : 'Name required!',
                    'STREET'            : 'Street',
                    'STREETREQMESSAGE'  : 'Street required!',
                    'CITY'              : 'City',
                    'CITYREQMESSAGE'    : 'City required!',
                    'ZIP'               : 'ZIP',
                    'ZIPREQMESSAGE'     : 'ZIP required!',
                    'COUNTRY'           : 'Country',
                    'COUNTRYREQMESSAGE' : 'Country required!',
                    'CONTACTPERSON'     : 'Contact',
                    'CONTACTEMAIL'      : 'E-Mail Contact',
                    'TYPE'              : 'Licensor type',
                    'ADVANCESANDCOSTS'  : 'Advances and billable Costs',
                    'STATEMENTS'        : 'Royalty Statements',
                    'ACCOUNTBALANCE'    : 'Account Balance',
                    'ADRESSLINE1'       : 'Address line 1',
                    'STATEMENTSLIST' : {
                        'PERIOD' : 'Royalty period',
                        'DUE' : 'Total due',
                        'PAYOUT' : 'Total payable'
                    },
                    'CURRENCY'          : 'Statement currency'
                },
            },

            'EDIT' : {
               'VIEW' : {
                  'HEADLINE' : 'Licensor',
               },
            },

            'IMPORT' : {
                'HEADLINE' : 'Import licensors',
                'TEXT'     : 'Upload the filled excel sheet and click on the cloud button afterwards for starting the import',
                'IMPORTFAILED' : 'Import failed',
                'IMPORTED' : 'Imported',
                'FAILEDREASON' : 'Reason for fail',
                'DOWNLOADTEMPLATETEXT' : 'Download licensor import template'
            }

        },

        'HELPTEXT' : {

            'OVERVIEW' : {
                'HEADLINE' : 'Licensor overview',
                'TEXT' : 'Here you find the overview.',
                'LIST1' : 'With + you can add a Licensor.',
                'LIST2' : 'With the cloud button you can import an excel sheet containing licensors'
            },

            'DETAIL' : {
                'HEADLINE' : 'edit licensor',
                'TEXT' : 'Here you can edit a licensor.',
                'LIST1' : '...',
            },

        },
    }
};
