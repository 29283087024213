export const locale = {
    lang: 'en',
    data: {
        'ACCOUNT': {
            'ERRORS' : {
                'REQUIRED' : {
                    'FIRSTNAME' : 'Firstname is required!',
                    'LASTNAME'  : 'Lastname is required!',
                    'EMAIL'     : 'E-Mail is required!',
                    'LANGUAGE'  : 'Please select a language!'
                }
            },
            'FIRSTNAME': 'Firstname',
            'LASTNAME': 'Lastname',
            'EMAIL' : 'E-Mail',
            'PREFEREDLANGUAGE' : 'Prefered Language'

        }
    }
};
