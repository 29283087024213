import {Component, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from './i18n/de';
import {locale as english } from './i18n/en';
import {fuseAnimations} from '../../../@fuse/animations';
import {TrackListComponent} from '@/main/tracks/track-list/track-list.component';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';


@Component({
    selector: 'app-contracts',
    templateUrl: './tracks.component.html',
    styleUrls: ['./tracks.component.scss'],
    animations   : fuseAnimations
})


export class TracksComponent {

    @ViewChild('trackList') _tracksList: TrackListComponent;

    constructor( private _fuseTranslationLoaderService: FuseTranslationLoaderService,
                 public dialog: MatDialog) {

        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }


    openHelp(): void {
        const dialogRef = this.dialog.open(HelpDialog, {
            //width: '500px',
            //height: '400px',
            //disableClose: true
            data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1',},
        });
    }

}

