import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {GlobalService} from '@/shared/services/global.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {FileManagerService} from '@/main/apps/file-manager/file-manager.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DialogData} from '@/main/apps/file-manager/uploaddialog/upload-dialog.component';
import {FuseTranslationLoaderService} from '../../../../@fuse/services/translation-loader.service';
import {locale as german } from './../i18n/de';
import {locale as english } from './../i18n/en';
import {LicensorsService} from '@/main/licensors/licensors.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource, MatTable} from '@angular/material/table';

@Component({
  selector: 'app-import-modal',
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss']
})
export class ImportModalComponent implements OnInit {

  @ViewChild('importForm') importForm: NgForm;

  importdata: any = { };

  fileUploaded: boolean = false;

  importSuccess: boolean = true;

  importFinished: boolean = false;

  importMessage: string = '';

  importFailed: any = {};
  imported: any = {};

  dataSource: MatTableDataSource<any>;

  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('table1') table1: MatTable<any>;
  @ViewChild('table2') table2: MatTable<any>;

  dataSourceImportFailed: MatTableDataSource<any>;
  dataSourceImported: MatTableDataSource<any>;

  private progressbarValue = 0;

  constructor(public _globalService: GlobalService, public _fileManagerService: FileManagerService,
              public dialogRef: MatDialogRef<ImportModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService,
              private _licensorsService: LicensorsService) {

    this.importdata.clientId = this._globalService.getUserClientId();
    this.importdata.user = this._globalService.userinfo;

    this._fuseTranslationLoaderService.loadTranslations(english, german);

    this.dataSourceImportFailed = new MatTableDataSource(this.importFailed);
    this.dataSourceImported = new MatTableDataSource(this.imported);

  }


  ngOnInit(): void {



  }

  uploadFile(event): void
  {
    const elem = event.target;

    const file = event.target.files[0];

    if (elem.files.length > 0){
      const formData = new FormData();
      formData.append('myfile', elem.files[0]);

      this.importdata.file = file.name;

      console.log(this.importdata);

      this._fileManagerService.sendFile(formData).subscribe(
          (response: HttpEvent<any>) => {
            switch (response.type) {
              case HttpEventType.Sent:
                this.progressbarValue = 0;
                break;
              case HttpEventType.Response:
                if ( response.body != null && response.body.success === true ){
                  this.fileUploaded = true;
                  //this._fileManagerService.getFiles( this._fileManagerService.handleCurrentPath('', true), true);
                  //this.dialogRef.close();
                }else{

                }
                break;
              case 1: {
                this.progressbarValue = Math.round(response['loaded'] / response['total'] * 100);
                break;
              }
            }
          },
          error => {
            console.log(error);
          });

    }

    elem.value = '';
  }


  close(): void {
    this.dialogRef.close();
  }

  importFile(): void {

    let uri = 'licensors/import/validateData';

    this._licensorsService.save(uri, this.importdata).subscribe(
        ( response: HttpEvent<any> ) => {

          switch (response.type) {

            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){
                this.importFinished = true;
                this.importFailed = response.body.data.importFailed;
                this.imported = response.body.data.imported;

                this.dataSourceImported.data = this.imported;
                this.dataSourceImportFailed.data = this.importFailed;


              } else {
                if (response.body != null) {
                  this.importSuccess = false;
                  this.importFinished = true;
                  this.importMessage = response.body.message;
                } else {

                }

              }
              break;
          }

        },
        error => {
          console.log(error);
        });

  }

}
