import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FileUploadComponent} from '@/main/file-upload/file-upload.component';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatStepperModule } from '@angular/material';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {AuthGuardService as AuthGuard} from '@/auth/auth-guard.service';


const routes = [
    {
        path     : 'fileupload',
        canActivate: [AuthGuard],
        component: FileUploadComponent
    }
];

@NgModule({
  imports: [
    CommonModule,
      RouterModule.forChild(routes),
      MatButtonModule,
      MatFormFieldModule,
      MatIconModule,
      MatInputModule,
      MatSelectModule,
      MatStepperModule,
      TranslateModule,
      FuseSharedModule
  ],
  declarations: [
      FileUploadComponent
  ],
  exports: [
      FileUploadComponent,
      MatButtonModule,
      MatFormFieldModule,
      MatIconModule,
      MatInputModule,
      MatSelectModule,
      MatStepperModule,
  ]
})
export class FileUploadModule { }
