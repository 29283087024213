export const locale = {
    lang: 'de',
    data: {

        'USERS' : {
            'ADD' : {
                'MODAL' : {
                    'CLIENTID' : 'Mandant',
                },
            },
        },

        'ACCOUNTING' : {
            'STATEMENTS' : {
                'CREATE' : {
                    'MODAL' : {
                        'HEADLINE' : 'Abrechnungen erstellen'
                    }
                }
            },
            'PERIODS' : {
                'HEADLINE' : 'Abrechnungszeiträume',
                'TABLE' : {
                    'CLIENT'        : 'Mandant',
                    'IDENTIFIER'    : 'Identifier',
                    'PERIOD_START'  : 'Start Datum',
                    'PERIOD_END'    : 'End Datum',
                    'INCOME'        : 'Umsatz',
                    'ACCOUNTED'     : 'Abgerechnet',
                    'PAYOUT'        : 'Auszahlung',
                    'PERIOD_CLOSED' : 'Abgeschlossen'
                },
                'ADD' : {
                    'MODAL' : {
                        'SAVE'   : 'Speichern und Schliessen',
                        'CANCEL' : 'Abbrechen',
                        'HEADLINE' : 'Abrechnungszeitraum hinzufügen / bearbeiten',
                        'IDENTIFIER' : 'Identifier',
                        'STARTDATE' : 'Start Datum',
                        'ENDDATE' : 'End Datum',
                    }
                },
                'DETAIL' : {
                    'FIELDREQMESSAGE'   : 'Dieses Feld darf nicht leer sein!',
                    'HEADLINE'          : 'Abrechnungszeitraum',
                    'SALESIMPORT'       : 'Sales Import',
                    'REVENUEDIGITAL'    : 'Digitale Umsätze',
                    'REVENUEPHYSICAL'   : 'Physische Umsätze',
                    'REVENUEMERCH'      : 'Merchandise Umsätze',
                    'REVENUESUBLICENSE' : 'Sublizenzen',
                    'CREATESTATEMENTS'  : 'Statements erstellen',
                    'RETURNS'           : 'Retouren',
                    'SALE_TYPE'         : 'Umsatz Typ',
                    'CATALOG_NUMBER'    : 'Katalognummer',
                    'EAN'               : 'EAN',
                    'UPC'               : 'UPC',
                    'ISRC'              : 'ISRC',
                    'RELEASE'           : 'Release',
                    'TRACK'             : 'Track',
                    'QUANTITY'          : 'Menge',
                    'REVENUE'           : 'Umsatz',
                    'TERRITORY'         : 'Land',
                    'PLATFORM'          : 'Plattform',
                    'DISTRIBUTOR'       : 'Vertrieb',
                    'PPD'               : 'HAP',
                    'RELEASEARTIST'     : 'Release Künstler',
                    'RELEASETITLE'      : 'Release Titel',
                    'TRACKARTIST'       : 'Track Künstler',
                    'TRACKETITLE'       : 'Track Titel',
                    'AVERAGE_PPD'       : 'Durchschnitts HAP',

                    'TRACKTITLE'       : 'Track Titel',
                    'ARTIST'           : 'Artist',
                    'TITLE'            : 'Titel',
                    'DESCRIPTION'      : 'Beschreibung',

                    'item_number' : '#',
                    'PRODUCT_IDENTIFIER' : 'Produkt Identifier',
                    'PRODUCT_NAME' : 'Produktbezeichnung',
                    'PRODUCT' : 'Produkt',
                    'ITEM_NUMBER' : 'Artikelnummer',
                    'CLOSEPERIOD' : 'Abrechnung abschliessen',
                    'CLOSEPERIODWARNING' : 'Wollen Sie diese Periode wirklich abschliessen? Danach sind keinerlei Änderungen mehr möglich!'
                },
                'SALES' : {
                    'ADD' : {
                        'MODAL' : {
                            'DIGITALHEADLINE'  : 'Digitale Umsätze erfassen / bearbeiten',
                            'PHYSICALHEADLINE' : 'Physische Umsätze erfassen / bearbeiten',
                            'MERCHHEADLINE'    : 'Merchandise Umsätze erfassen / bearbeiten',
                            'ADDSUBLICENSEHEADLINE' : 'Sublizenzverkauf erfassen / bearbeiten',
                            'RETURNSHEADLINE'  : 'Retoure erfassen / bearbeiten',
                        }
                    },
                    'IMPORT': {
                        'MCMSSALESSYNC' : {
                            'HEADLINE' : 'Import von Digitalen Verkäufen aus MCMS Yavin',
                            'SELECTPERIODS' : 'Wählen Sie die zu importierenden Abrechnungen',
                            'INCLUDESHOPS' : 'Zahlen auch nach Shops gruppieren (deutlich mehr Daten und langsamer!)',
                            'ADDITIONALSETTINGS' : 'Zusätzliche einstellungen',
                            'AUDIO' : 'Audio Sales',
                            'VIDEO' : 'Video Streams - werden als Audio Track Streams importiert!'
                        },
                        'RUNNINGIMPORTS' : 'Laufende Importe',
                        'FILESFORIMPORT' : 'Dateien für den Import',
                        'IMPORTEDFILES'  : 'Importierte Dateien',
                        'ERRORIMPORTS'   : 'Fehlerhafte Importe',
                        'FILEIMPORT' : {
                            'TYPE' : 'Import Typ',
                            'FILENAME' : 'Dateiname',
                            'IMPORTSETTINGS' : 'Importeinstellungen',
                            'INTERFACE' : 'Schnittstelle',
                            'CURRENCYCOURSE' : 'Währungskurs',
                            'USERNAME' : 'Benutzer'
                        },
                        'IMPORTTABLES' : {
                            'FILENAME' : 'Dateiname',
                            'REVENUE' : 'Umsatz',
                            'ROWS' : 'Zeilen',
                            'ERRORROWS' : 'Fehlerhafte Zeilen',
                            'ERRORREVENUE' : 'Fehlerhafter Umsatz',
                            'IMPORTEDROWS' : 'Importierte Zeilen',
                            'IMPORTEDREVENUE' : 'Importierter Umsatz',
                            'CURRENCYCOURSE' : 'Währungskurs'
                        }
                    }
                }
            }
        }
    }
};
