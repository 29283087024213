import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [

    /*** Shortnavigation ***/
    {
        id       : 'dashboard',
        title    : 'Dashboard',
        translate: 'NAV.DASHBOARD.TITLE',
        type     : 'item',
        icon     : 'dashboard',
        url      : '/dashboard',
     /*   badge    : {
            title    : '25',
            translate: 'NAV.DASHBOARD.BADGE',
            bg       : '#F44336',
            fg       : '#FFFFFF'
        } */
    },


    /*** Base Data ***/
    {
        id      : 'masterdata',
        title   : 'Masterdata',
        translate: 'NAV.MASTERDATA.MAIN',
        type    : 'group',
        icon    : 'group',
        children: [
            {
                id: 'licensors',
                title: 'Licensors',
                translate: 'NAV.MASTERDATA.LICENSOR',
                type: 'item',
                icon: 'person',
                url: '/licensors',
            },
            {
                id      : 'contracts',
                title   : 'Contracts',
                translate: 'NAV.MASTERDATA.CONTRACT',
                type    : 'item',
                icon    : 'description',
                url     : '/contracts',
            },
            {
                id      : 'expensetypes',
                title   : 'Expensetypes',
                translate: 'NAV.MASTERDATA.EXPENSETYPES',
                type    : 'item',
                icon    : 'attach_money',
                url     : '/expensetypes',
            },

            {
            id      : 'catalog',
            title   : 'Catalog',
            translate: 'NAV.MASTERDATA.CATALOG',
            type    : 'group',
            icon    : 'group',
            children: [
                    {
                        id      : 'track',
                        title   : 'Track',
                        translate: 'NAV.MASTERDATA.TRACK',
                        type    : 'item',
                        icon    : 'music_note',
                        url     : '/tracks',
                    },
                    {
                        id      : 'product',
                        title   : 'Product',
                        translate: 'NAV.MASTERDATA.PRODUCT',
                        type    : 'item',
                        icon    : 'queue_music',
                        url     : '/products',
                    },
                    {
                        id      : 'promotionperiods',
                        title   : 'Promotion Periods',
                        translate: 'NAV.MASTERDATA.PROMOTIONPERIODS',
                        type    : 'item',
                        icon    : 'timer',
                        url     : '/promotionperiods',
                    },
                ]
            },
        ]
    },


    /*** Accounting ***/
    {
        id      : 'accounting',
        title   : 'Accounting',
        translate: 'NAV.ACCOUNTING.MAIN',
        type    : 'group',
        icon    : 'apps',
        children: [
            {
                id       : 'file-manager',
                title    : 'File Manager',
                translate: 'NAV.ACCOUNTING.FILE_MANAGER',
                type     : 'item',
                icon     : 'folder',
                url      : '/apps/file-manager'
            },
            {
                id       : 'accounting-processes',
                title    : 'Accounting Processes',
                translate: 'NAV.ACCOUNTING.ACCOUNTING',
                type     : 'item',
                icon     : 'money',
                url      : '/accounting'
            }

        ]
    },

    /*** ANALYTICS ***/
    {
        id      : 'analytics',
        title   : 'Analytics',
        translate: 'NAV.ANALYTICS.MAIN',
        type    : 'group',
        icon    : 'apps',
        children: [
            {
                id       : 'revenue',
                title    : 'Revenue',
                translate: 'NAV.ANALYTICS.REVENUE',
                type     : 'item',
                icon     : 'show_chart',
                url      : '/analytics'
            }

        ]
    },

];
