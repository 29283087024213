import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {RoyaltyPeriodsComponent} from '@/main/accounting/royalty-periods/royalty-periods.component';

export interface DialogData {
    dialogHeadline: string;
    dialogText: string;
}

@Component({
    selector: 'track-dialog-component',
    templateUrl: 'royaltyperiods-dialog.component.html',
})

export class RoyaltyperiodsDialogComponent{

    @ViewChild('royaltyPeriodsList') _royaltyPeriodsList: RoyaltyPeriodsComponent;

    constructor(public dialogRef: MatDialogRef<RoyaltyperiodsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    }

     closeReleaseDialog(data = false): void {
        if (data){
            this.dialogRef.close({data: this._royaltyPeriodsList.selectedRowModal });
        }else{
            this.dialogRef.close();
        }
     }
}
