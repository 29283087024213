export const locale = {
    lang: 'de',
    data: {
        'TOOLBAR': {
            'MYPROFILE': 'My Profile',
            'MAILBOX'  : 'Mailbox',
            'LOGOUT'   : 'Logout'
        }
    }
};
