import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpensetypesComponent } from './expensetypes.component';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import {RouterModule} from '@angular/router';
import {
  MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonModule,
  MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule,
  MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatGridListModule,
  MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatPaginatorModule,
  MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule,
  MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule,
  MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule
} from '@angular/material';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {AuthGuardService as AuthGuard } from '@/auth/auth-guard.service';
import {FuseSidebarModule} from '../../../@fuse/components';
import { UserListComponent } from '@/main/users/user-list/user-list.component';
import {CanDeactivateGuard} from '@/shared/services/can-deactivate-guard.service';
import {ExpensetypeListComponent} from '@/main/expensetypes/expensetype-list/expensetype-list.component';
import {ExpensetypeDetailComponent} from '@/main/expensetypes/expensetype-detail/expensetype-detail.component';


const routes = [
  {
    path     : 'expensetypes',
    component: ExpensetypesComponent,
    canActivate: [AuthGuard],
    /*
    resolve  : {
      files: ExpensetypesService
    }
     */
  },
  {
        path    : 'expensetype/:id',
        component: ExpensetypeDetailComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatMomentDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        TranslateModule,
        FuseSharedModule,
        FuseSidebarModule,
    ],
  providers   : [
  ],
    declarations: [ExpensetypesComponent, ExpensetypeListComponent, ExpensetypeDetailComponent],
  entryComponents: [ ],
    exports: [
        ExpensetypesComponent,
        ExpensetypeListComponent,
        ExpensetypeDetailComponent
    ]
})
export class ExpensetypesModule { }
