import {Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {Location} from '@angular/common';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {MatDialog, MatDialogRef, MatTabChangeEvent} from '@angular/material';
import {OAuthService} from 'angular-oauth2-oidc';
import {GlobalService} from '@/shared/services/global.service';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {PromotionperiodsService} from '@/main/promotionperiods/promotionperiods.service';
import * as moment from 'moment';
import {ReleaseDialogComponent} from '@/main/components/releasedialog/release-dialog.component';
import {ReleaseListComponent} from '@/main/products/release-list/release-list.component';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-user-detail',
  templateUrl: './promotionperiod-detail.component.html',
  styleUrls: ['./promotionperiod-detail.component.scss']
})
export class PromotionperiodDetailComponent implements OnInit {

  @ViewChild('promotionperiodForm') promotionperiodForm: NgForm;
  @ViewChild('releaseList') _releaseList: ReleaseListComponent;


  promotionperiodId: string;
  promotionperiod: any = {period_id : 0, client_id: 0};
  //newPwd: any = {password: '', password2: ''};
  promotionperiodinfo: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;


    activeTab = '_promotionperiod';
    tabs = {
        0: '_promotionperiod',
        1: '_releaseList',
    };

  constructor(
      private _location: Location,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private route: ActivatedRoute,
      private router: Router,
      private auth: OAuthService,
      private _formBuilder: FormBuilder,
      private _promotionperiodService: PromotionperiodsService,
      private _globalService: GlobalService,
      public dialog: MatDialog
      ) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }

    canDeactivate(): Observable<boolean> | boolean {
        return this._globalService.canDeactivate();
    }

  ngOnInit(): void {

    this.promotionperiodId = this.route.snapshot.paramMap.get('id');

    this.promotionperiod.period_id = Number(this.route.snapshot.queryParamMap.get('clientId'));

    if (this.promotionperiodId !== 'new') {
        this.getOne(this.promotionperiodId);
    }else{

        if (!this.route.snapshot.queryParamMap.get('clientId')){
            this.promotionperiod.client_id = this._globalService.getUserClientId();
        }

        this._globalService.setFormData(this.promotionperiod, Object.assign({}, this.promotionperiod));
    }

  }

  back(): void {
    this._location.back();
  }


  addNew(activeTab): void {

      //this[activeTab].openAddNewModal(this.periodId,0);
      //this[activeTab].openAddNewModal('sdsdsd');
      const releaseDialogRef = this.dialog.open(ReleaseDialogComponent, {
          width: '1400px',
          //height: '100%',
          disableClose: true,
          data: { dialogHeadline: 'flex releases', dialogText: 'Select releases, AMK!'  },
      });

      releaseDialogRef.afterClosed()
          .subscribe(result => {
              //console.log(result);
              if ( result && result.data && result.data.length > 0 ) {
                  this.savePromotionperiodRelease(result.data);
              }
          });
  }

    savePromotionperiodRelease(releaseIds: any): void{

      if (releaseIds && releaseIds.length > 0)   {
          let uri = 'promotionperiods/savepr';
          //console.log({tids: trackIds});
          //console.log(this.contractId);

          const data = {'promotionperiodId': this.promotionperiodId, rids: releaseIds};

          this._promotionperiodService.save(uri, data).subscribe(
              ( response: HttpEvent<any> ) => {

                  switch (response.type) {

                      case HttpEventType.Response:
                          if ( response.body != null && response.body.success === true ){
                              this._releaseList.refreshList();
                          }
                          break;
                  }

              },
              error => {
                  console.log(error);
              });
      }

    }


    /*
  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

   */
   askForDelete(activeTab): void {

       this[activeTab].askForDelete('promotionperiods');

   }

  save(): void{

     if (this.promotionperiodForm.form.valid) {

      let uri = 'promotionperiods/save';
      if (this.promotionperiodId !== 'new'){
        uri += '/' + this.promotionperiodId;
      }


      if (moment.isMoment(this.promotionperiod.period_start_date)  ){
          this.promotionperiod.period_start_date = this.promotionperiod.period_start_date.format("YYYY-MM-DD");
      }
      if (moment.isMoment(this.promotionperiod.period_end_date) ) {
          this.promotionperiod.period_end_date = this.promotionperiod.period_end_date.format("YYYY-MM-DD");
      }
      //console.log(this.promotionperiod)

      this._promotionperiodService.save(uri, this.promotionperiod).subscribe(
          ( response: HttpEvent<any> ) => {

            switch (response.type) {

              case HttpEventType.Response:
                if ( response.body != null && response.body.success === true ) {

                    this._globalService.setFormData(this.promotionperiod, Object.assign({}, this.promotionperiod));

                    if (this.promotionperiodId === 'new'){
                        this.askForContinueAdding();
                    }
                }
                break;
            }

          },
          error => {
            console.log(error);
          });
    }

  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
      this.activeTab = this.tabs[tabChangeEvent.index];
      //console.log(this.activeTab);
  }


  askForContinueAdding(): void{

      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to add more items?';
      this.confirmDialogRef.afterClosed()
          .subscribe(result => {
              if ( result ){
                  this.promotionperiodForm.resetForm();
              }else{
                  this._location.back();
              }
              this.confirmDialogRef = null;
          });
  }

  getOne(uid: string): void{

    this._promotionperiodService.getOne(
        'promotionperiods/get/' + uid,
    ).subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){

                this.promotionperiod = response.body.data;
                this._globalService.setFormData(this.promotionperiod, Object.assign({}, this.promotionperiod));
              }
              break;
          }
        },
        error => {
          console.log("error get one: " );
          console.log(error);
        });
  }

    openHelp(): void {
        const dialogRef = this.dialog.open(HelpDialog, {
            //width: '500px',
            //height: '400px',
            //disableClose: true
            data: { helpText: 'HELPTEXT.DETAIL.TEXT' , helpHeadline : 'HELPTEXT.DETAIL.HEADLINE', helpTextList1: 'HELPTEXT.DETAIL.LIST1',},
        });
    }

}
