export const locale = {
    lang: 'de',
    data: {
        'TRACKS': {

            'OVERVIEW' : {
                'HEADLINE' : 'Titel - Übersicht',
                'TABLE' : {
                    'track_id'  : 'Titel ID',
                    'title'     : 'Titel Name',
                    'artist'    : 'Künstler',
                    'isrc'      : 'ISRC',
                    'version'   : 'Version',
                    'on_releases' : 'Auf VÖ\'s',
                    'on_contracts' : 'Auf Verträgen'
                },
            },

            'EDIT' : {
                'VIEW' : {
                    'HEADLINE' : 'Titel',
                },
            },

            'ADD' : {
                'MODAL' : {
                    'HEADLINE'              : 'Titel hinzufügen / bearbeiten',
                    'CLIENTID'              : 'Mandant',
                    'title'                 : 'Titel',
                    'artist'                : 'Künstler',
                    'isrc'                  : 'ISRC',
                    'version'               : 'Version',
                    'playtime_in_seconds'   : 'Spielzeit in Sek.',
                    'playtime'              : 'Spielzeit (HH:MM:SS)',
                    'PLAYTIMEWRONG'         : 'Bitte das format HH:MM:SS einhalten!',
                    'ARTISTS'               : 'weitere Künstler',
                    'FIELDREQMESSAGE'       : 'Feld darf nicht leer bleiben!',
                    'ISRCERRORMSG'          : 'ISRC erfoderlich oder ISRC ist nicht Normkonform!'
                },
                'ISRC'                  : 'ISRC',
            },
            'DETAIL' : {
                'TRACKINFO' : 'Trackinformationen',
                'ISRC' : 'ISRC Matching',
            },


        },

        'HELPTEXT' : {

                'OVERVIEW' : {
                    'HEADLINE' : 'Vertragsübersicht',
                    'TEXT' : 'Hier findest du eine Übersicht, über alle Mandanten.',
                    'LIST1' : 'Mit dem + kannst du neue Mandanten erstellen.',
                },

                'EDIT' : {
                    'HEADLINE' : 'Vertrag bearbeiten',
                    'TEXT' : 'Hier kann der Mandant mit seinen Benutzern im Detail angeschaut und bearbeitet werden.',
                    'LIST1' : 'Die mit * markierten Felder sind Pflichtfelder und müssen ausgefüllt sein.',
                    'LIST2' : 'Die Währung muss korrekt eingestellt werden, hier geht es um die Währung in der der Mandant abrechne, nicht wie er bezahlt wird.',
                    'LIST3' : 'USt. ID ist ein Pflichtfeld und wird vorausgesetzt, da jede Firma eine SteuerID haben muss.',
                    'LIST4' : 'Die Benutzer, die zum Mandanten gehören, können hier angelegt und bearbeitet werden.',
                    'LIST5' : 'Es können nur Benutzer zu diesem Mandanten angelegt werden.',
                },

        },
    }
};
