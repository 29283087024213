import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from './i18n/de';
import {locale as english } from './i18n/en';


@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }

  ngOnInit() {
  }

}
