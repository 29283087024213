export const locale = {
    lang: 'en',
    data: {
        'DASHBOARD' : {
            'LICENSORNAME' : 'Licensor',
            'PERIOD' : 'Royalty Period',
            'PAYOUT' : 'Payout',
            'DUE' : 'Due',
            'ACTION' : 'Cover / Table Sheet',
            'TITLE' : 'Dashboard',
            'SERVERTASKS' : 'Servertasks',
            'TASKSTABLE' : {
                'RUNNING' : 'Running',
                'FINISHED' : 'Finished',
                'TYPE' : 'Type',
                'STARTED' : 'Start time',
                'ENDED'   : 'End time',
                'USER' : 'User'
            },
            'WHATSNEW' : 'What\'s new',
            'KONTOSTAND': 'Account Balance',
            'NOTIFICATIONS': 'Notifications'
        }
    }
};
