export const locale = {
    lang: 'en',
    data: {
        'TRACKS': {

            'OVERVIEW' : {
                'HEADLINE' : 'Title - overview',
                'TABLE' : {
                    'track_id'  : 'Title id',
                    'title'     : 'Title name',
                    'artist'    : 'Artist',
                    'isrc'      : 'ISRC',
                    'version'   : 'Version',
                    'on_releases' : 'On releases',
                    'on_contracts' : 'On contracts'
                },
            },

            'EDIT' : {
                'VIEW' : {
                    'HEADLINE' : 'Title',
                },
            },

            'ADD' : {
                'MODAL' : {
                    'HEADLINE'              : 'add / edit title',
                    'CLIENTID'              : 'Client',
                    'title'                 : 'Title',
                    'artist'                : 'Artist',
                    'isrc'                  : 'ISRC',
                    'version'               : 'Version',
                    'playtime_in_seconds'   : 'Playtime in sec.',
                    'playtime'              : 'Playtime (HH:MM:SS)',
                    'PLAYTIMEWRONG'         : 'Please use the format HH:MM:SS!',
                    'ARTISTS'               : 'more artists',
                    'FIELDREQMESSAGE'       : 'Field can not be empty!',
                    'ISRCERRORMSG'          : 'ISRC required, or ISRC is malformed!'
                },
                'ISRC'                  : 'ISRC',
            },
            'DETAIL' : {
                'TRACKINFO' : 'Trackinformation',
                'ISRC' : 'ISRC Matching',
            },


        },

        'HELPTEXT' : {

            'OVERVIEW' : {
                'HEADLINE' : 'Contractoverview',
                'TEXT' : 'Here you will find an overview of all clients.',
                'LIST1' : 'With the + you can create new clients.',
            },

            'EDIT' : {
                'HEADLINE' : 'Edit contract',
                'TEXT' : 'Here, the client can be viewed and edited in detail with its users.',
                'LIST1' : 'The fields marked with * are mandatory and must be completed.',
                'LIST2' : 'The currency must be set correctly, this is the currency in which the client settles, not how it is paid.',
                'LIST3' : 'VAT ID is a mandatory field and is assumed as each company must have a tax ID.',
                'LIST4' : 'The users belonging to the client can be created and edited here.',
                'LIST5' : 'Only users can be created for this client.',
            },

        },
    }
};
