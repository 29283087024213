import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA, MatDialog, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter} from '@angular/material';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {ClientsService} from '@/main/clients/clients.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {GlobalService} from '@/shared/services/global.service';
import {AccountingService} from '@/main/accounting/accounting.service';
import {MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import {MomentUtcDateAdapter} from '@/shared/momentUtcAdapter';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {FuseSidebarService} from '../../../../../@fuse/components/sidebar/sidebar.service';
import {LicensorsService} from '@/main/licensors/licensors.service';
import {TranslateService} from '@ngx-translate/core';


export interface DialogData {
    licensors: any;
    period_id: any;
}

@Component({
    selector:       'statement-creation-modal',
    templateUrl:    'statement-creation-modal.component.html',
    providers : [
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    ]
})
export class StatementCreationModalComponent implements OnInit {

    form: FormGroup;
    period: any = {
        'client_id' : 0
    };

    licensors: any;

    @ViewChild('StatementCreationForm') StatementCreationForm: NgForm;

    constructor(
        public dialogRef: MatDialogRef<StatementCreationModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _formBuilder: FormBuilder,
        private _globalService: GlobalService,
        private _accountingService: AccountingService,
        public dialog: MatDialog,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseSidebarService: FuseSidebarService,
        private _licensorsService: LicensorsService,
        public translate: TranslateService)
    {


    }

    ngOnInit(): void {

        this.period.client_id = this._globalService.getUserClientId();

        this._licensorsService.getSome(
            this.data.licensors, true
        ).subscribe(
            ( response: HttpEvent<any> ) => {
                switch (response.type) {
                    case HttpEventType.Response:
                        if ( response.body != null && response.body.success === true ){
                            this.licensors = response.body.data;
                        }
                        break;
                }
            },
            error => {
                console.log("error get one: " );
                console.log(error);
            });


    }


    onNoClick(): void {

        this.dialogRef.close(false);
    }



    save(): void
    {



            const formData = new FormData();
            formData.append('licensors', JSON.stringify(this.data.licensors ));
            formData.append('period_id', this.data.period_id.toString());
            formData.append('currencies', JSON.stringify(this.licensors));

            console.log(this.licensors);

            this._licensorsService.save('accounting/addStatementCreationJob', formData).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){

                                this.dialogRef.close(true);

                            }
                            break;
                    }
                },
                error => {
                    console.log(error);
                });


    }




}
