import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {MatDialog} from '@angular/material';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable()
export class ProductsService  {

  httpHeaders: HttpHeaders;

  public _releasefilterValue = '';
  public _productfilterValue = '';

  constructor(
      private _httpClient: HttpClient,
      private _auth: OAuthService,
      public dialog: MatDialog
  ){
    this.httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + this._auth.getAccessToken());
  }

  loadProducts(uri: string,  filter = '', direction = 'asc', sort = 'product_name', page = 0, pageSize = 10, productType = 1, contractId = 0):  Observable<any> {

    return this._httpClient.get('/api/' + uri, {
      params: new HttpParams()
          .set('filter', filter)
          .set('sort', sort)
          .set('direction', direction)
          .set('page', page.toString())
          .set('pageSize', pageSize.toString())
          .set('contractId', contractId.toString())
          .set('productType', productType.toString()), headers : this.httpHeaders
    }).pipe(
        map(res => res )
    );
  }

  loadReleases(uri: string,  filter = '', direction = 'desc', sort = 'release_date', page = 0, pageSize = 10, releaseType = '', periodId = 0, contractId = 0):  Observable<any> {

    return this._httpClient.get('/api/' + uri, {
      params: new HttpParams()
          .set('filter', filter)
          .set('sort', sort)
          .set('direction', direction)
          .set('page', page.toString())
          .set('pageSize', pageSize.toString())
          .set('releaseType', releaseType)
          .set('periodId', periodId.toString())
          .set('contractId', contractId.toString())
          , headers : this.httpHeaders
    }).pipe(
        map(res => res )
    );
  }

    loadReleases2(uri: string,  filter = '', direction = 'desc', sort = 'release_date', page = 0, pageSize = 10, releaseType = '', periodId = 0, contractId = 0):  Observable<any> {

        return this._httpClient.get('/api/' + uri, {
            params: new HttpParams()
                .set('filter', filter)
                .set('sort', sort)
                .set('direction', direction)
                .set('page', page.toString())
                .set('pageSize', pageSize.toString())
                .set('releaseType', releaseType)
                .set('periodId', periodId.toString())
                .set('contractId', contractId.toString())
            ,   headers : this.httpHeaders,
                observe: 'events',
                reportProgress: false
        });
    }

  getOne(uri: string): any{
    return this._httpClient.get('/api/' + uri,  {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
  }


  save(uri: string, formData: any): any{
    return this._httpClient.post('/api/' + uri, formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
  }

  delete(uri: string, formData: any): any{
    return this._httpClient.post('/api/' + uri, formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
  }


  getEANUPCList(uri: string): any{
    return this._httpClient.get('/api/' + uri, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
  }

  saveEANUPC(uri: string, formData: any): any{
    return this._httpClient.post('/api/' + uri, formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
  }

  MCMSSyncReleases(userinfo: any): any{
    return this._httpClient.post('/api/products/releases/MCMSSync', {'userinfo': userinfo}, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
  }

}
