export const locale = {
    lang: 'de',
    data: {
        'CONTRACTS': {

            'OVERVIEW' : {
                'HEADLINE' : 'Vertrags - Übersicht',
                'TABLE' : {
                    'ID'        : 'ID',
                    'CONTRACT_IDENTIFIER'      : 'Identifier',
                    'LOCENSOR_NAME'      : 'Lizenzgeber',
                },
            },
            'IMPORT' : {
                'HEADLINE' : 'Verträge importieren',
                'DOWNLOADTEMPLATETEXT' : 'Download import template XLS',
                'TEXT'     : 'Lade das ausgefüllte Excel sheet hier hoch und klicke danach auf die Wolke zum importieren',
                'FAILURES' : 'Fehler',
                'UPLOAD' : 'Upload',
                'IMPORTERRORSTEXT' : 'Der Import war nicht erfolgreich!'
            },
            'EDIT' : {
                'VIEW' : {
                    'HEADLINE' : 'Vertrag',
                },
            },

            'ADD' : {
                'MODAL' : {
                    'HEADLINE'          : 'Vertrag hinzufügen / bearbeiten',
                    'NAME'              : 'Identifier',
                    'licensor'            : 'Lizenzgeber',
                    'promotion_deduction'  : 'Promotion-Abzug %',
                    'mailorder_deduction'  : 'Mailorder Abzug %',
                    'export_deduction'     : 'Export-Abzug % - Rest der Welt',
                    'digital_split_downloads'   : 'Digital Split - Downloads %',
                    'digital_split_streaming'   : 'Digital Split - Streaming %',
                    'merchandise_split' : 'Merchandise Split %',
                    'sublicense_split_digital' : 'Sublizenz Split - Digital %',
                    'sublicense_split'         : 'Sublizenz Split %',
                    'royalty_share'            : 'Royalty Share %',
                    'inhouse_compilation_split_download': 'Inhouse Compilation Split - Download %',
                    'inhouse_compilation_split_streaming': 'Inhouse Compilation Split - Streaming %',
                    'inhouse_compilation_split_share'   : 'Inhouse Compilation Split - Royalty Share %',
                    'inhouse_jason' : 'Verpackungsabzüge %',
                    'graduation_royalty_share' : 'Staffelung nach Tonträger Stückzahlen',
                    'return_reserves' : 'Retouren Reseven %',
                    'release_reserves' : 'Auflösung Retouren Reserven (in Monaten)',
                    'royalty_base' : 'Abrechnungsgrundlage physische Verkäufe',
                    'royalty_base_merch' : 'Abrechnungsgrundlage Merchandise Verkäufe',

                    'digital_download_split_territory'   : 'Digital-Download-Split nach Region',
                    'digital_streaming_split_territory'   : 'Digital-Streaming-Split nach Region',

                    'SUBLICENCESPLITS' : 'Sublizenzen',
                    'MERCHANDISESPLIT' : 'Merch - Split',
                    'COMPILATIONS' : 'Compilations',
                    'INHOUSECOMPILATIONSPLIT' : 'Compilations & Sublizenzen',

                    'SPLITS' : 'Split - Abgaben',
                    'DIGITAL' : 'Digital',
                    'ROYALTYSHARES' : 'Royalty Shares',
                    'DEDUCTIONS' : 'Abzüge',
                    'RESERVES' : 'Rückstellungen',
                    'NON_EXPORT_TERRITORIES' : 'Vom Export ausgenommene Territorien',

                    'TERRITORY' : 'Land',
                    'PERCENTAGE' : 'Prozent %',
                    'PACKAGING_TYPE' : 'Verpackungstyp',
                    'DEDUCTION' : 'Abzug',
                    'EXPENSETYPE' : 'Ausgabentyp',
                    'AMOUNT' : 'Anzahl',
                    'SHARE' : 'Share',

                    'TAB' : {
                       'CONTRACTDATA'   : 'Konditionen',
                        'TITLEDATA'     : 'Titel',
                        'PRODUCTS'      : 'Merchandise Produkte',
                        'EXPENSES'      : 'Ausgabenverrechnung',
                        'RELEASES'      : 'Releases'
                    },

                    'MERCHANDISE' : 'Merchandising',
                    'MERCH_BILLABLE' : 'Merchandising verrechenbar',
                    'YES' : 'Ja',
                    'NO' : 'Nein',

                    'EXPORT_DEDUCTION_BY_TERRITORY' : 'Export Abzug nach Land',
                    'mechanicals_deduction' : 'Mechanicals / Rückstellung Downloads %',
                    'mechanicals_deduction_streaming' : 'Mechanicals / Rückstellung Streaming %',

                    'FIELDREQMESSAGE' : 'Diese Feld muss ausgefüllt werden!',

                    'GRADUATION_DEDUCTIONS' : {
                        'STARTING_FROM' : 'Ab',
                        'SOLD_SOUND_RECORDINGS' : 'verkaufte Tonträger'
                    }
                },
            },


        },

        'HELPTEXT' : {

                'OVERVIEW' : {
                    'HEADLINE' : 'Vertragsübersicht',
                    'TEXT' : 'Hier findest du eine Übersicht, über alle Mandanten.',
                    'LIST1' : 'Mit dem + kannst du neue Mandanten erstellen.',
                },

                'EDIT' : {
                    'HEADLINE' : 'Vertrag bearbeiten',
                    'TEXT' : 'Hier kann der Mandant mit seinen Benutzern im Detail angeschaut und bearbeitet werden.',
                    'LIST1' : 'Die mit * markierten Felder sind Pflichtfelder und müssen ausgefüllt sein.',
                    'LIST2' : 'Die Währung muss korrekt eingestellt werden, hier geht es um die Währung in der der Mandant abrechne, nicht wie er bezahlt wird.',
                    'LIST3' : 'USt. ID ist ein Pflichtfeld und wird vorausgesetzt, da jede Firma eine SteuerID haben muss.',
                    'LIST4' : 'Die Benutzer, die zum Mandanten gehören, können hier angelegt und bearbeitet werden.',
                    'LIST5' : 'Es können nur Benutzer zu diesem Mandanten angelegt werden.',
                },

        },
    }
};
