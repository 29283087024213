import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { LoginModule } from 'app/login/login.module';
import {ReactiveFormsModule} from '@angular/forms';
import { OAuthModule } from 'angular-oauth2-oidc';
import {AuthGuardService as AuthGuard } from '@/auth/auth-guard.service';
import {AccountModule} from '@/main/account/account.module';
import {MaintenanceModule} from '@/main/maintenance/maintenence.module';
import {ApitestModule} from '@/main/apitest/apitest.module';
import {FileUploadModule} from './main/file-upload/file-upload.module';
import { FileService } from './shared/services/file.service';

import {ProductsModule} from './main/products/products.module';
import {ContractsModule} from './main/contracts/contracts.module';
import {ProjectModule} from './main/project/project.module';
import {ClientsModule} from '@/main/clients/clients.module';
import {UsersModule} from '@/main/users/users.module';
import {LicensorsModule} from '@/main/licensors/licensors.module';
import {PromotionperiodsModule} from './main/promotionperiods/promotionperiods.module';


import {
    MatAutocompleteModule, MatBottomSheetModule, MatButtonModule,
    MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule,
    MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatGridListModule,
    MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatPaginatorModule,
    MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule,
    MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule,
    MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule, MatBadgeModule
} from '@angular/material';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {GlobalService} from '@/shared/services/global.service';
import {SharedModule} from '@/shared/shared.module';
import {TracksModule} from '@/main/tracks/tracks.module';
import {HttpInterceptorInterceptor} from '@/interceptor/http.interceptor';
import {BusyIndicatorComponent} from '@/main/components/busyindicator/busy-indicator.component';
import {BusyindicatorInterceptor} from '@/interceptor/busyindicator.interceptor';
import {SnackbarComponent} from '@/main/components/snackbar/snackbar.component';
import {AccountingModule} from '@/main/accounting/accounting.module';
import {TrackDialogComponent} from '@/main/components/trackdialog/track-dialog.component';
import {ProductDialogComponent} from '@/main/components/product-dialog/product-dialog.component';
import {ReleaseDialogComponent} from '@/main/components/releasedialog/release-dialog.component';
import {RoyaltyperiodsDialogComponent} from '@/main/components/royaltyperiodsdialog/royaltyperiods-dialog.component';
import {CanDeactivateGuard} from '@/shared/services/can-deactivate-guard.service';
import {DashboardModule} from '@/main/dashboard/dashboard.module';
import {ExpensetypesModule} from '@/main/expensetypes/expensetypes.module';
import { LogdialogComponent } from './main/components/logdialog/logdialog.component';
import {AutomatorModule} from '@/main/automator/automator.module';
import {StatementCreationModalComponent} from '@/main/accounting/royalty-periods-detail/statement-creation-modal/statement-creation-modal.component';
import {InterfaceCreatorComponent} from '@/main/components/interface-creator/interface-creator.component';
import {InterfaceCreatorModule} from '@/main/components/interface-creator/interface-creator.module';
import {AnalyticsModule} from '@/main/analytics/analytics.module';

const appRoutes: Routes = [

    {
        path        : 'apps',
        loadChildren: './main/apps/apps.module#AppsModule'
    },
    {
        path      : '**',
        redirectTo: 'dashboard',
    },
];

@NgModule({
    declarations: [
        AppComponent,
        HelpDialog,
        InterfaceCreatorComponent,
        TrackDialogComponent,
        ReleaseDialogComponent,
        FuseConfirmDialogComponent,
        BusyIndicatorComponent,
        SnackbarComponent,
        ProductDialogComponent,
        RoyaltyperiodsDialogComponent,
        LogdialogComponent,
        StatementCreationModalComponent,
    ],
    imports     : [
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatMomentDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        ReactiveFormsModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: ['http://localhost:4200', 'http://api.bantha.local' ],
                sendAccessToken: true
            }
        }),
        TranslateModule.forRoot(),


        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        LoginModule,
        AccountModule,
        MaintenanceModule,
        ApitestModule,
        FileUploadModule,
        HttpClientModule,
        ProductsModule,
        ContractsModule,
        ExpensetypesModule,
        ProjectModule,
        ClientsModule,
        UsersModule,
        LicensorsModule,
        InterfaceCreatorModule,
        PromotionperiodsModule,
        SharedModule,
        TracksModule,
        AccountingModule,
        DashboardModule,
        AutomatorModule,
        AnalyticsModule
    ],
    entryComponents: [
        HelpDialog,
        InterfaceCreatorComponent,
        TrackDialogComponent,
        ReleaseDialogComponent,
        FuseConfirmDialogComponent,
        SnackbarComponent,
        ProductDialogComponent,
        RoyaltyperiodsDialogComponent,
        LogdialogComponent,
        StatementCreationModalComponent],
    providers: [
        AuthGuard,
        CanDeactivateGuard,
        FileService,
        GlobalService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BusyindicatorInterceptor,
            multi: true
        }
    ],
    bootstrap   : [
        AppComponent
    ],
    exports: [
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatMomentDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule
    ]
})
export class AppModule
{

}
