export const locale = {
    lang: 'en',
    data: {
        'USERS' : {
            'ADD' : {
                'MODAL' : {
                    'CLIENTID' : 'Client',
                },
            },
        },

        'PRODUCTS': {
            'RELEASESYNC' : {
                'HEADLINE' : 'Import releases from MCMS',
                'INFOTEXT' : 'Press the button to start the import process on the server. You will get notified via eMail as soon as the process finished.'
            },
            'IMPORT': {
                'HEADLINE': 'Merchandise product import',
                'IMPORTERRORSTEXT' : 'Import was not successfull! Please correct the data and try again!',
                'TEXT' : 'Upload the filled excel sheet and click on the cloud button afterwards for starting the import',
                'DOWNLOADTEMPLATETEXT' : 'Download import template XLS',
                'FAILURES' : 'Errors',
                'UPLOAD' : 'Upload'
            },
            'RELEASES' : {
                'TABLE': {
                    'ID' : '#',
                    'CATALOG_NUMBER' : 'Catalog Nr.',
                    'EAN' : 'EAN',
                    'UPC' : 'UPC',
                    'ARTIST' : 'Artist',
                    'TITLE'  : 'Title',
                    'LABEL'     : 'Label',
                    'RELEASE_DATE' : 'VÖ Date',
                    'RELEASE_TYPE' : 'VÖ Type',
                    'RELEASE_GROUP' : 'Release group',
                    'TOTAL_SALES' : 'Total sales',
                    'RATA' : 'Rata',
                    'ACTIVE_ON_CONTRACT': 'Accounted on this contract',
                    'SET_TO_NOT_ACTIVE' : 'Change to "NOT accounted on this contract"',
                    'SET_TO_ACTIVE' : 'Change to "accounted on this contract"'
                },
            },

            'OVERVIEW' : {
                'HEADLINE' : 'Productoverview',
                'AUDIO' : 'Audio',
                'MERCH' : 'Merchandise',
                'BUNDLES' : 'Merch / Audio Bundles',

                'TABLE': {
                    'ID' : '#',
                    'NAME' : 'Name',
                    'IDENTIFIER' : 'Identifier',
                    'PRODUCT_TYPE' : 'Product type',
                    'ITEM_NUMBER' : 'Itemnumber',
                    'REVENUEPERCENTAGE' : 'Revenue participation %',
                    'PPD' : 'PPD'
                },
            },

        },

        'PRODUCT' : {
            'ADD' : {
                'NAME' : 'Name',
                'IDENTIFIER' : 'Identifier',
                'ITEMNUMBER' : 'Itemnumber',
                'PRODUCTTYPE' : 'Product typ',
                'HEADLINE'  : 'Merch - Product',
                'PPD' : 'PPD'
            },
            'RELEASE' : {
                'ADD' : {
                    'RELEASETYPE' : 'Release type',
                    'PPD' : 'PPD',
                    'CATALOG_NUMBER' : 'Catalog Nr.',
                    'ALBUMTYPE' : 'Album Typ',
                    'ALBUMTYPEMESSAGE' : 'Album Typ needed!',
                    'sold_units_start_amount' : 'Sold before using Bantha!',
                    'EAN' : 'EAN',
                    'UPC' : 'UPC',
                    'ARTIST' : 'Artist',
                    'TITLE' : 'Title',
                    'SUBTITLE' : 'Subtitle',
                    'LABEL' : 'Label',
                    'ARTISTS' : 'involved Artists',
                    'RELEASEDATE' : 'Date: Release',
                    'PRESALEDATE' : 'Date: Presalestart',
                    'MEDIAVOLUME' : 'Media Volume',
                    'EAN_UPC' : 'EAN / UPC',
                    'RELEASE_GROUP_IDENTIFIER' : 'Release Group Identifier'
                },
                'DETAIL' : {
                    'PRODUCTINFO' : 'Productinformation',
                    'TRACKLIST' : 'Titlelist',
                    'PROMOTIONSETTINGS' : 'Promotionperiod / Media Volume',
                    'EAN_UPC' : 'EAN / UPC Matching',
                },

            },
        },

        'HELPTEXT' : {



        },
    }
};
