import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {TrackListComponent} from '@/main/tracks/track-list/track-list.component';
import {ReleaseListComponent} from '@/main/products/release-list/release-list.component';

export interface DialogData {
    dialogHeadline: string;
    dialogText: string;
}

@Component({
    selector: 'track-dialog-component',
    templateUrl: 'release-dialog.component.html',
})

export class ReleaseDialogComponent{


    @ViewChild('releaseList') _releaseList: ReleaseListComponent;


    constructor(public dialogRef: MatDialogRef<ReleaseDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

     closeReleaseDialog(data = false): void {
        if (data){
            this.dialogRef.close({data: this._releaseList.selectedProducts });
        }else{
            this.dialogRef.close();
        }
     }
}
