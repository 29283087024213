import { HttpEvent, HttpEventType} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit, EventEmitter, Input} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatSort} from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {fuseAnimations} from '@fuse/animations';
import {ProductsService} from '@/main/products/products.service';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {GlobalService} from '@/shared/services/global.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  animations   : fuseAnimations
})

export class ProductListComponent implements AfterViewInit  {

  @Input() clientId: any;
  @Input() productType: any;
  @Input() contractId: any;
  @Input() isModal: boolean = false;

  displayedColumns: string[] = ['checkbox', 'product_id', 'product_name', 'product_identifier', 'type', 'item_number', 'ppd'  ];
  data: any[] = [];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  filterInt: any;
  filterEvent: EventEmitter<String> = new EventEmitter(false);

  selectedProducts: string[] = [];
  isChecked = false;

  resultsLength = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor( private _fuseTranslationLoaderService: FuseTranslationLoaderService,
               private _fuseSidebarService: FuseSidebarService,
               private _productsService: ProductsService,
               private _globalService: GlobalService,
               public dialog: MatDialog) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }


  ngAfterViewInit(): void {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.filterEvent.subscribe(() => this.paginator.pageIndex = 0);



    merge(this.sort.sortChange, this.paginator.page, this.filterEvent)
        .pipe(
            startWith({}),
            switchMap(() => {

              return this._productsService.loadProducts(
                  'products/get',
                  this._productsService._productfilterValue,
                  this.sort.direction,
                  this.sort.active,
                  this.paginator.pageIndex,
                  this.paginator.pageSize,
                  this.productType,
                  this.contractId
              );
            }),
            map(data => {
              // Flip flag to show that loading has finished.
              this.resultsLength = data.total_count;
              return data.data.items;
            }),
            catchError(() => {
              return observableOf([]);
            })
        ).subscribe(data => this.data = data);

  }


  onSelectedChange(id: any): void{

    if ( this.selectedProducts.length > 0 ){

      const index = this.selectedProducts.indexOf(id);

      if ( index !== -1 ){

        this.selectedProducts.splice(index, 1);

        if ( this.selectedProducts.length === 0 ){
          this.isChecked = false;
        }
        return;
      }
    }

    this.selectedProducts.push(id);
    this.isChecked = true;
  }


  askForDelete(): void{

    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete all selected items?';

    this.confirmDialogRef.afterClosed()
        .subscribe(result => {
          if ( result )
          {
            this.delete();
          }
          this.confirmDialogRef = null;
        });
  }

  delete(): void{
    const formData = new FormData();
    formData.append('ids', JSON.stringify(this.selectedProducts ));
    var uri = '';
    if (this.contractId !== undefined && this.contractId > 0) {
      uri = 'contracts/deletecp';
      formData.append('contract_id', this.contractId);
    } else {
      uri = '/products/delete';
    }


    this._productsService.delete(uri, formData).subscribe(
        ( response: HttpEvent<any> ) => {

          switch (response.type) {

            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){

                this.selectedProducts.length = 0;
                this.isChecked = false;
                this.filterEvent.emit(this._productsService._productfilterValue);

              }
              break;
          }
        },
        error => {
          console.log(error);
        });

  }

  /*
  toggleSidebar(name): void
  {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }
   */

  applyFilter(filterValue: string): void  {
    clearInterval(this.filterInt);
    this.filterInt = setInterval(() => { this.callFilter(filterValue); }, 1000);
  }

  callFilter(filterValue: string): void {
    clearInterval(this.filterInt);
    this._productsService._productfilterValue = filterValue;
    this.filterEvent.emit(this._productsService._productfilterValue);
  }

  openHelp(): void {
    const dialogRef = this.dialog.open(HelpDialog, {
      data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1'},
    });
  }

  refreshList(): void {
    this.filterEvent.emit(this._productsService._productfilterValue);
  }
}

