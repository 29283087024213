import { NgModule, ModuleWithProviders } from '@angular/core';
//import { appDirective } from './{your-path}';
//import { appPipe } from './{your-path}';
//import { appService } from './{your-path}';
import {CountryByCodePipe} from '@/shared/pipes/CountryByCode.pipe';
import {CurrencyByCodePipe} from '@/shared/pipes/CurrencyByCode.pipe';
import {JsonInputComponent} from '@/main/components/json-input/json-input.component';
import {CountryAutocompleteComponent} from '@/main/components/country-autocomplete/country-autocomplete.component';
import {TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule, MatTreeModule
} from '@angular/material';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {FuseSharedModule} from '../../@fuse/shared.module';
import {FuseSidebarModule} from '../../@fuse/components';
import {ContractChooserComponent} from '@/main/components/contract-chooser/contract-chooser.component';
import {ReleaseChooserComponent} from '@/main/components/release-chooser/release-chooser.component';
import {TrackChooserComponent} from '@/main/components/track-chooser/track-chooser.component';
import {ProductChooserComponent} from '@/main/components/product-chooser/product-chooser.component';

@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatMomentDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        TranslateModule,
        FuseSharedModule,
        FuseSidebarModule,
    ],
    declarations: [
        CountryByCodePipe,
        CurrencyByCodePipe,
        JsonInputComponent,
        CountryAutocompleteComponent,
        ContractChooserComponent,
        ReleaseChooserComponent,
        TrackChooserComponent,
        ProductChooserComponent
    ],
    exports: [
        CountryByCodePipe,
        CurrencyByCodePipe,
        JsonInputComponent,
        CountryAutocompleteComponent,
        ContractChooserComponent,
        ReleaseChooserComponent,
        TrackChooserComponent,
        ProductChooserComponent
        //appDirective
    ]
})
export class SharedModule {}
