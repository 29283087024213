export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'DASHBOARD'     : {
                'TITLE': 'Dashboard',
                'BADGE': '25'
            },

            'MASTERDATA': {
                'MAIN' : 'Masterdata',
                'LICENSOR': 'Licensor',
                'PRODUCT': 'Products',
                'CONTRACT': 'Contracts',
                'EXPENSETYPES': 'Expensetypes',
                'LICENSDATA' : 'Licensedata',
                'CATALOG' : 'Catalog',
                'TRACK' : 'Tracks',
                'PROMOTIONPERIODS' : 'Promotionperiods',
            },

            'ACCOUNTING': {
                'ACCOUNTING' : 'Accounting',
                'MAIN' : 'Accounting',
                'FILE_MANAGER': 'File-Manager',
            },

            'ANALYTICS' : {
                'MAIN' : 'Analytics',
                'REVENUE' : 'Revenue'
            },


            'SYSTEMADMINISTRATION' : {
                'MAIN' : 'Systemadministration',
                'USERADMINISTRATION' : 'Client / Useraccounts',
                'CLIENTS' : 'Clients',
                'USERS' : 'Users',
                'APITEST' : 'test API',
                'FILEUPLOAD' : 'Fileuploader',
                'AUTOMATOR' : 'Automator',
            },
        }
    }
};
