import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
import {FuseTranslationLoaderService} from '../../../@fuse/services/translation-loader.service';
import {reject} from 'q';

import 'rxjs-compat/add/operator/map';

@Component({
  selector: 'app-apitest',
  templateUrl: './apitest.component.html',
  styleUrls: ['./apitest.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})

export class ApitestComponent implements OnInit {

    apitestform: FormGroup;
    submitted: boolean;
    loading: boolean;
    error: '';
    f: any;
    r: {};

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _http: HttpClient,
        private _auth: OAuthService,
        private _formBuilder: FormBuilder,

    )
    {



    }

  ngOnInit(): void {

    this.apitestform = this._formBuilder.group({
        url: ['', Validators.required ]
    });


  }


  makeApiCall(): any {

      this.submitted = true;
      this.error = '';

      let f = this.apitestform.controls;

      // stop here if form is invalid
      if (this.apitestform.invalid) {
          return;
      }

      const token = this._auth.getAccessToken();
      console.log(token);

      const httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + token);


      this._http.get( f.url.value, { headers : httpHeaders } ).subscribe((res) => {
            console.log(res);
            this.r = res;
      }, ( error ) => {
          if (error) {
              this.r = error;
          }
      });

  }

}
