import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatIconModule, MatMenuModule, MatToolbarModule } from '@angular/material';
import {MatBadgeModule} from '@angular/material/badge';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        TranslateModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        MatBadgeModule
    ],
    exports     : [
        ToolbarComponent
    ]
})
export class ToolbarModule
{
}
