import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import {LicensorsService} from '@/main/licensors/licensors.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {Location} from '@angular/common';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {MatDialog, MatTabChangeEvent} from '@angular/material';
import {GlobalService} from '@/shared/services/global.service';
import {ContractListComponent} from '@/main/contracts/contract-list/contract-list.component';
import {AdvancesAndCostsComponent} from '@/main/licensors/advances-and-costs/advances-and-costs.component';
import {Observable} from 'rxjs';
import {UserListComponent} from '@/main/users/user-list/user-list.component';

@Component({
  selector: 'app-licensor-detail',
  templateUrl: './licensor-detail.component.html',
  styleUrls: ['./licensor-detail.component.scss']
})
export class LicensorDetailComponent implements OnInit {

  @ViewChild('licensorForm') licensorForm: NgForm;
  @ViewChild('contractList') _contractList: ContractListComponent;
  @ViewChild('advancesList') _advancesList: AdvancesAndCostsComponent;
  @ViewChild('userList') _userList: UserListComponent;


  licensorId: string;
  licensor = {client_id: 0};
  showContractList = true;
  accountBalance = '';
  activeTab = 'licensor';

  statements: any = [];

  tabs = {
        0: 'licensor',
        1: 'contracts',
        2: 'advances',
        3: 'statements',
        4: 'users'
  };

  constructor(
      private _location: Location,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private route: ActivatedRoute,
      private router: Router,
      private _formBuilder: FormBuilder,
      private _licensorsService: LicensorsService,
      private _globalService: GlobalService,
      public dialog: MatDialog
      ) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);

  }

    canDeactivate(): Observable<boolean> | boolean {
        return this._globalService.canDeactivate();
    }

  ngOnInit(): void {

    this.licensorId = this.route.snapshot.paramMap.get('id');

    if (this.licensorId !== 'new') {
        this.showContractList = false;
        this.getOne(this.licensorId);

        this.getStatements(this.licensorId);

    }else{
        //this.showUserList = true;
        this.licensor.client_id = this._globalService.getUserClientId();
        this._globalService.setFormData(this.licensor, Object.assign({}, this.licensor));
    }

  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.activeTab = this.tabs[tabChangeEvent.index];
  }


  back(): void {
    this._location.back();
  }


  save(): void{

    if (this.licensorForm.form.valid) {


      let uri = 'licensors/save';
      if (this.licensorId !== 'new'){
        uri += '/' + this.licensorId;
      }

      this._licensorsService.save(uri, this.licensor).subscribe(
          ( response: HttpEvent<any> ) => {

            switch (response.type) {

              case HttpEventType.Response:
                if ( response.body != null && response.body.success === true ){
                    this._globalService.setFormData(this.licensor, Object.assign({}, this.licensor));
                    this._globalService.loadLicensors(true);
                }
                break;
            }

          },
          error => {
            console.log(error);
          });
    }

  }

  getOne(uid: string): void{

    this._licensorsService.getOne(
        'licensors/get/' + uid,
    ).subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){
                this.licensor = response.body.data;
                this._globalService.setFormData(this.licensor, Object.assign({}, this.licensor));
              }
              break;
          }
        },
        error => {
          console.log("error get one: " );
          console.log(error);
        });

    this._licensorsService.getAccountBalance(uid).subscribe(
        ( response: HttpEvent<any> ) => {
            switch (response.type) {
                case HttpEventType.Response:
                    if ( response.body != null && response.body.success === true ){
                        this.accountBalance = response.body.data.account_balance;
                    }
                    break;
            }
        },
        error => {
            console.log("error get one: " );
            console.log(error);
        });

  }

    openHelp(): void {
        const dialogRef = this.dialog.open(HelpDialog, {
            data: { helpText: 'HELPTEXT.DETAIL.TEXT' , helpHeadline : 'HELPTEXT.DETAIL.HEADLINE', helpTextList1: 'HELPTEXT.DETAIL.LIST1',},
        });
    }

    getStatements(licensorId): void {

      this._licensorsService.getStatements(licensorId).subscribe(
          ( response: HttpEvent<any> ) => {
              switch (response.type) {
                  case HttpEventType.Response:
                      console.log(response);
                      if ( response.body != null && response.body.success === true ){
                          this.statements = response.body.items;
                      }
                      break;
              }
          },
          error => {
              console.log("error get statements: " );
              console.log(error);
          });

    }

}
