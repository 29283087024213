import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {MatDialog} from '@angular/material';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'  // <- ADD THIS
})
export class ExpensetypesService  {

    httpHeaders: HttpHeaders;

    constructor(
        private _httpClient: HttpClient,
        private _auth: OAuthService,
        public dialog: MatDialog
    ){
         this.httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + this._auth.getAccessToken());
    }

    getCostTypes(uri: string): any{
        return this._httpClient.get('/api/' + uri, {
            headers : this.httpHeaders
        }).pipe(
            map(res => res )
        );
    }

    getOne(uri: string): any{
        return this._httpClient.get('/api/' + uri,  {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    save(uri: string, formData: any): any{
        return this._httpClient.post('/api/' + uri, formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    delete(uri: string, formData: any): any{
        return this._httpClient.post('/api/' + uri, formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

}
