import {FuseNavigation} from '../../@fuse/types';

export const navigationLicensor: FuseNavigation[] = [

    /*** Shortnavigation ***/
    {
        id       : 'dashboard',
        title    : 'Dashboard',
        translate: 'NAV.DASHBOARD.TITLE',
        type     : 'item',
        icon     : 'dashboard',
        url      : '/dashboard',
        /*   badge    : {
               title    : '25',
               translate: 'NAV.DASHBOARD.BADGE',
               bg       : '#F44336',
               fg       : '#FFFFFF'
           } */
    }
];
