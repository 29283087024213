import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {BusyIndicatorService} from '@/main/components/busyindicator/busy-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import {FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import {ClientsService} from '@/main/clients/clients.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {Location} from '@angular/common';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {MatDialog} from '@angular/material';
import {UserListComponent} from '@/main/users/user-list/user-list.component';
import {GlobalService} from '@/shared/services/global.service';
import { MatTabChangeEvent } from '@angular/material';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent implements OnInit {

  @ViewChild('clientForm') clientForm: NgForm;
  @ViewChild('textblocksForm') textblocksForm: NgForm;
  @ViewChild('userList') _userList: UserListComponent;


  clientId: string;
  client = {textblocks: null};
  showUserList = true;
  activeTab = 'client';
  tabs = {
            0: 'client',
            1: 'users',
            2: 'textblocks'
        };

  textblocks = {
                'publishstatement_emailtext' : ''
               };

  constructor(
      private _location: Location,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private route: ActivatedRoute,
      private router: Router,
      private _formBuilder: FormBuilder,
      private _clientsService: ClientsService,
      private _busyIndicatorService: BusyIndicatorService,
      private _globalService: GlobalService,
      public dialog: MatDialog
      ) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);

  }

  canDeactivate(): Observable<boolean> | boolean {
      return this._globalService.canDeactivate();
  }


    ngOnInit(): void {

    this.clientId = this.route.snapshot.paramMap.get('id');

    if (this.clientId !== 'new') {
        this.showUserList = false;
        this.getOne(this.clientId);
    }else{
        this.showUserList = true;
        this._globalService.setFormData(this.client, Object.assign({}, this.client));
    }


  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.activeTab = this.tabs[tabChangeEvent.index];
  }


  back(): void {
    this._location.back();
  }


  save(): void{

    if (this.clientForm.form.valid && this.textblocksForm.form.valid) {

      let uri = 'clients/save';
      if (this.clientId !== 'new'){
        uri += '/' + this.clientId;
      }

      this._clientsService.save(uri, this.client).subscribe(
          ( response: HttpEvent<any> ) => {

            switch (response.type) {

              case HttpEventType.Response:
                if ( response.body != null && response.body.success === true ){

                    this.client.textblocks = this.textblocks;

                    this._globalService.setFormData(this.client, Object.assign({}, this.client));
                    this._globalService.loadClients();
                }
                break;
            }

          },
          error => {
            console.log(error);
          });
    }

  }

  getOne(uid: string): void{

    //this._busyIndicatorService.show();

    this._clientsService.getOne(
        'clients/get/' + uid,
    ).subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){

                //console.log(response.body);
                this.client = response.body.data;
                this.textblocks = this.client.textblocks;

                this._globalService.setFormData(this.client, Object.assign({}, this.client));
                //this.form.patchValue( this.client );
              }
              break;
          }
        },
        error => {
          console.log("error get one: " );
          console.log(error);
        });
  }

    openHelp(): void {
        const dialogRef = this.dialog.open(HelpDialog, {
            //width: '500px',
            //height: '400px',
            //disableClose: true
            data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1',},
        });
    }

}
