import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {MatDialog} from '@angular/material';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable()
export class ClientsService  {

    httpHeaders: HttpHeaders;

    constructor(
        private _httpClient: HttpClient,
        private _auth: OAuthService,
        public dialog: MatDialog
    ){
         this.httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + this._auth.getAccessToken());
    }

    loadClients(uri: string,  filter = '', direction = 'asc', sort = 'name', page = 0, pageSize = 10):  Observable<any> {

        return this._httpClient.get('/api/' + uri, {
            params: new HttpParams()
                .set('filter', filter)
                .set('sort', sort)
                .set('direction', direction)
                .set('page', page.toString())
                .set('pageSize', pageSize.toString()), headers : this.httpHeaders
        }).pipe(
            map(res => res )
        );
    }

    getOne(uri: string): any{
        return this._httpClient.get('/api/' + uri,  {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }


    save(uri: string, formData: any): any{
        return this._httpClient.post('/api/' + uri, formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    delete(uri: string, formData: any): any{
        return this._httpClient.post('/api/' + uri, formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

}
