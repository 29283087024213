import { Component, OnInit } from '@angular/core';
import {GlobalService} from '@/shared/services/global.service';
import {DashboardService} from '@/main/dashboard/dashboard.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {FuseTranslationLoaderService} from '../../../@fuse/services/translation-loader.service';
import {locale as english} from '@/main/dashboard/i18n/en';
import {locale as german} from '@/main/dashboard/i18n/de';
import {LicensorsService} from '@/main/licensors/licensors.service';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private _globalService: GlobalService,
              private _dashboardService: DashboardService,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService,
              private _translateService: TranslateService,
              private _licensorsService: LicensorsService) {
      this._fuseTranslationLoaderService.loadTranslations(english, german);
      this.languages = [
          {
              id   : 'en',
              title: 'English',
              flag : 'us'
          },
          {
              id   : 'de',
              title: 'Deutsch',
              flag : 'de'
          }
      ];
      this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});
  }

  runningTasks: any = [];
  finishedTasks: any = [];
  statements: any = [];
  selectedLanguage: any;
  languages: any;

  ngOnInit(): any {

      if (this._globalService.isLicensor()) {
          this._dashboardService.getLatestPublishedLicensorStatements(this._globalService.userinfo.licensor_id).subscribe(
              ( response: HttpEvent<any> ) => {

                  switch (response.type) {

                      case HttpEventType.Response:
                          this.statements = response.body.items;
                          break;
                  }
              });

      } else {

          this._dashboardService.getLatestStatements().subscribe(
              ( response: HttpEvent<any> ) => {

                  switch (response.type) {

                      case HttpEventType.Response:
                          this.statements = response.body.items;
                          break;
                  }
              });

      }

  }

}
