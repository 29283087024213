import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {TrackListComponent} from '@/main/tracks/track-list/track-list.component';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {TracksService} from '@/main/tracks/tracks.service';

export interface DialogData {
    trackId: string;
    dialogHeadline: string;
    items: [];
    isReleases: true;
}

@Component({
    selector: 'trackinfo-dialog-component',
    templateUrl: 'trackinfo-dialog.component.html',
})

export class TrackinfoDialogComponent{



    constructor(public dialogRef: MatDialogRef<TrackinfoDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private _tracksService: TracksService) {


        this.getData();
    }

     closeTrackDialog(data = false): void {
         this.dialogRef.close();
        /*
        if (data){
            this.dialogRef.close({data: this._trackList.selectedTracks });
        }else{
            this.dialogRef.close();
        }
        */

     }

    getData(): void  {

        let uri = '';

        if (this.data.isReleases === true){
            uri = 'tracks/getTrackInfoReleaseItems/' + this.data.trackId;
        }else{
            uri = 'tracks/getTrackInfoContractItems/' + this.data.trackId;
        }

        this._tracksService.getOne(uri).subscribe(
        ( response: HttpEvent<any> ) => {

            switch (response.type) {
                case HttpEventType.Response:
                console.log(response.body);
                if ( response.body !== null && response.body.success === true ){
                    this.data.items = response.body.data.items;
                }
                break;
            }
        },
        error => {
            console.log(error);
        });

    }

}
