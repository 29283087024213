import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsComponent } from './products.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductListComponent } from './product-list/product-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import {RouterModule} from '@angular/router';
import {
  MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonModule,
  MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule,
  MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatGridListModule,
  MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatPaginatorModule,
  MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule,
  MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule,
  MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule
} from '@angular/material';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {AuthGuardService as AuthGuard } from '@/auth/auth-guard.service';
import {FuseSidebarModule} from '../../../@fuse/components';
import {CountryByCodePipe} from '@/shared/pipes/CountryByCode.pipe';
import {CurrencyByCodePipe} from '@/shared/pipes/CurrencyByCode.pipe';
import {SharedModule} from '@/shared/shared.module';
import {UsersModule} from '@/main/users/users.module';
import {ProductsService} from '@/main/products/products.service';
import { ReleaseListComponent } from './release-list/release-list.component';
import { ReleaseDetailComponent } from './release-detail/release-detail.component';
import {CanDeactivateGuard} from '@/shared/services/can-deactivate-guard.service';
import { ProductImportModalComponent } from './product-import-modal/product-import-modal.component';
import { ReleaseImportModalComponent } from './release-import-modal/release-import-modal.component';


const routes = [
  {
    path     : 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard],
    /*
    resolve  : {
      files: ExpensetypesService
    }
     */
  },
  {
    path    : 'products/:id',
    component: ProductDetailComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path    : 'products/release/:id',
    component: ReleaseDetailComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path    : 'products/release/:id/:id2',
    component: ReleaseDetailComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    TranslateModule,
    FuseSharedModule,
    FuseSidebarModule,
    UsersModule,
    SharedModule,

  ],
  providers   : [
    ProductsService
  ],
  declarations: [ProductsComponent, ProductDetailComponent, ProductListComponent, ReleaseListComponent, ReleaseDetailComponent, ProductImportModalComponent, ReleaseImportModalComponent],
  entryComponents: [ ProductImportModalComponent, ReleaseImportModalComponent ],
  exports: [
      ProductsComponent,
      ProductListComponent,
      ReleaseListComponent,
      ReleaseDetailComponent,
      ProductImportModalComponent,
      ReleaseImportModalComponent
  ]
})


export class ProductsModule { }
