import {AfterViewInit, Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import {LicensorsService} from '@/main/licensors/licensors.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {Location} from '@angular/common';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {MatDialog, MatDialogRef, MatPaginator, MatSort, MatTabChangeEvent} from '@angular/material';
import {GlobalService} from '@/shared/services/global.service';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {AddReturnsModalComponent} from '@/main/accounting/royalty-periods-detail/returns/add-returns-modal.component/add-returns-modal.component';
import {AddAdvanceModalComponent} from '@/main/licensors/advances-and-costs/add-advance-modal/add-advance-modal.component';
import {ContractsService} from '@/main/contracts/contracts.service';

@Component({
  selector: 'app-advances-and-costs',
  templateUrl: './advances-and-costs.component.html',
  styleUrls: ['./advances-and-costs.component.scss']
})
export class AdvancesAndCostsComponent implements AfterViewInit {

  @Input() licensorId: number;
  @Input() clientId: number;

  displayedColumns: string[] = ['checkbox', 'costs_type', 'name', 'amount', 'already_billed', 'still_to_bill', 'billable_percentage_per_statement', 'clearing_via', 'cleared' ];
  data: any[] = [];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  filterValue: string;
  filterInt: any;
  filterEvent: EventEmitter<String> = new EventEmitter(false);

  selectedRows: string[] = [];
  isChecked = false;

  resultsLength = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
      private _location: Location,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private route: ActivatedRoute,
      private router: Router,
      private _formBuilder: FormBuilder,
      private _licensorsService: LicensorsService,
      private _globalService: GlobalService,
      private _translateService: TranslateService,
      private _contractsService: ContractsService,
      public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }

  ngAfterViewInit(): void {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.filterEvent.subscribe(() => this.paginator.pageIndex = 0);



    merge(this.sort.sortChange, this.paginator.page, this.filterEvent)
        .pipe(
            startWith({}),
            switchMap(() => {

              return this._licensorsService.loadAdvances(
                  'licensors/advances/get/' + this.licensorId,
                  this.filterValue,
                  this.sort.direction,
                  this.sort.active,
                  this.paginator.pageIndex,
                  this.paginator.pageSize
              );
            }),
            map(data => {
              // Flip flag to show that loading has finished.
              this.resultsLength = data.total_count;
              return data.data.items;
            }),
            catchError(() => {
              return observableOf([]);
            })
        ).subscribe(data => this.data = data);

  }

  onSelectedChange(id: any): void{

    if ( this.selectedRows.length > 0 ){

      const index = this.selectedRows.indexOf(id);

      if ( index !== -1 ){

        this.selectedRows.splice(index, 1);

        if ( this.selectedRows.length === 0 ){
          this.isChecked = false;
        }
        return;
      }
    }

    this.selectedRows.push(id);
    this.isChecked = true;
  }


  askForDelete(): void{

    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete all selected items?';

    this.confirmDialogRef.afterClosed()
        .subscribe(result => {
          if ( result )
          {
            this.delete();
          }
          this.confirmDialogRef = null;
        });
  }

  delete(): void{
    const formData = new FormData();
    formData.append('ids', JSON.stringify(this.selectedRows ));


    this._licensorsService.delete('licensors/advances/delete', formData).subscribe(
        ( response: HttpEvent<any> ) => {

          switch (response.type) {

            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){

                this.selectedRows.length = 0;
                this.isChecked = false;
                this.filterEvent.emit(this.filterValue);

              }
              break;
          }
        },
        error => {
          console.log(error);
        });

  }



  /*
  toggleSidebar(name): void
  {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }
   */

  applyFilter(filterValue: string): void  {
    clearInterval(this.filterInt);
    this.filterInt = setInterval(() => { this.callFilter(filterValue); }, 1000);
  }

  callFilter(filterValue: string): void {
    clearInterval(this.filterInt);
    this.filterValue = filterValue;
    this.filterEvent.emit(this.filterValue);
  }

  openHelp(): void {
    const dialogRef = this.dialog.open(HelpDialog, {
      //width: '500px',
      //height: '400px',
      //disableClose: true
      data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1'},
    });
  }

  openAddNewModal(row): void
  {
    const dialogRef = this.dialog.open(AddAdvanceModalComponent, {
      data: {'clientId': this.clientId, 'salesRow': row, 'licensorId': this.licensorId},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

      this.filterEvent.emit(this.filterValue);

    });
  }

}
