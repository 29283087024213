import {HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit, EventEmitter, Input} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatSort} from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {fuseAnimations} from '@fuse/animations';
import {LicensorsService} from '@/main/licensors/licensors.service';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {GlobalService} from '@/shared/services/global.service';
import * as fileSaver from 'file-saver';
import {TranslateService} from '@ngx-translate/core';
import {AddPeriodDialogComponent} from '@/main/accounting/addPeriodDialog/add-period-dialog.component';
import {StatementCreationModalComponent} from '@/main/accounting/royalty-periods-detail/statement-creation-modal/statement-creation-modal.component';

@Component({
    selector: 'app-licensor-list',
    templateUrl: './licensor-list.component.html',
    styleUrls: ['./licensor-list.component.scss'],
    animations   : fuseAnimations
})

export class LicensorListComponent implements AfterViewInit  {

    @Input() licensorId: any;
    @Input() createStatements: boolean = false;
    @Input() royaltyPeriodId: number = 0;
    @Input() periodClosed: boolean = false;

    displayedColumns: string[] = ['checkbox', 'licensor_id', 'name', 'street', 'zip', 'city', 'contact_person', 'account_balance'  ];
    data: any[] = [];
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    filterInt: any;
    filterEvent: EventEmitter<String> = new EventEmitter(false);

    selectedLicensors: string[] = [];
    isChecked = false;

    resultsLength = 0;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor( private _fuseTranslationLoaderService: FuseTranslationLoaderService,
                 private _fuseSidebarService: FuseSidebarService,
                 private _licensorsService: LicensorsService,
                 private _globalService: GlobalService,
                 public dialog: MatDialog,
                 public translate: TranslateService) {

        this._fuseTranslationLoaderService.loadTranslations(english, german);



    }


    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        this.filterEvent.subscribe(() => this.paginator.pageIndex = 0);

        if (this.createStatements) {
            this.displayedColumns = ['checkbox', 'licensor_id', 'name', 'city', 'total_payable', 'total_due', 'action'];

        }


        merge(this.sort.sortChange, this.paginator.page, this.filterEvent)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._licensorsService.loadLicensors(
                        'licensors/get',
                        this._licensorsService._licensorfilterValue,
                        this.sort.direction,
                        this.sort.active,
                        this.paginator.pageIndex,
                        this.paginator.pageSize,
                        this.licensorId,
                        this.royaltyPeriodId
                    );
                }),
                map(data => {
                    // Flip flag to show that loading has finished.
                    this.resultsLength = data.total_count;
                    return data.data.items;
                }),
                catchError(() => {
                    return observableOf([]);
                })
            ).subscribe(data => this.data = data);

    }


    onSelectedChange(id: any): void{

        if ( this.selectedLicensors.length > 0 ){

            const index = this.selectedLicensors.indexOf(id);

            if ( index !== -1 ){

                this.selectedLicensors.splice(index, 1);

                if ( this.selectedLicensors.length === 0 ){
                    this.isChecked = false;
                }
                return;
            }
        }

        this.selectedLicensors.push(id);
        this.isChecked = true;
    }


    askForDelete(): void{

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete all selected items?';

        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {
                    this.delete();
                }
                this.confirmDialogRef = null;
            });
    }

    delete(): void{
        const formData = new FormData();
        formData.append('ids', JSON.stringify(this.selectedLicensors ));


        this._licensorsService.delete('licensors/delete', formData).subscribe(
            ( response: HttpEvent<any> ) => {

                switch (response.type) {

                    case HttpEventType.Response:
                        if ( response.body != null && response.body.success === true ){

                            this.selectedLicensors.length = 0;
                            this.isChecked = false;
                            this.filterEvent.emit(this._licensorsService._licensorfilterValue);

                        }
                        break;
                }
            },
            error => {
                console.log(error);
            });

    }

    /*
    toggleSidebar(name): void
    {
      this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
     */

    applyFilter(filterValue: string): void  {
        clearInterval(this.filterInt);
        this.filterInt = setInterval(() => { this.callFilter(filterValue); }, 1000);
    }

    callFilter(filterValue: string): void {
        clearInterval(this.filterInt);
        this._licensorsService._licensorfilterValue = filterValue;
        this.filterEvent.emit(this._licensorsService._licensorfilterValue);
    }

    openHelp(): void {
        const dialogRef = this.dialog.open(HelpDialog, {
            //width: '500px',
            //height: '400px',
            //disableClose: true
            data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1'},
        });
    }

    generateStatements(): void {

        const dialogRef = this.dialog.open(StatementCreationModalComponent, {
            //width: '500px',
            //height: '400px',
            data: {'licensors': this.selectedLicensors, 'period_id': this.royaltyPeriodId}, //important! for insert uuid = 0 / edit = uuid
            disableClose: true
        });


        dialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {
                    this.selectedLicensors.length = 0;
                    this.isChecked = false;
                    this.filterEvent.emit(this._licensorsService._licensorfilterValue);
                }

            });

        /*
        const formData = new FormData();
        formData.append('licensors', JSON.stringify(this.selectedLicensors ));
        formData.append('period_id', this.royaltyPeriodId.toString());

        this._licensorsService.save('accounting/addStatementCreationJob', formData).subscribe(
            ( response: HttpEvent<any> ) => {

                switch (response.type) {

                    case HttpEventType.Response:
                        if ( response.body != null && response.body.success === true ){

                            this.selectedLicensors.length = 0;
                            this.isChecked = false;
                            this.filterEvent.emit(this._licensorsService._licensorfilterValue);

                        }
                        break;
                }
            },
            error => {
                console.log(error);
            });

         */
    }

    aproveStatement(statementId):void {

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = this.translate.instant('LICENSOR.OVERVIEW.TABLE.APROVEQUESTION');

        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {
                    this._licensorsService.aproveStatement(statementId).subscribe(
                        ( response: HttpEvent<any> ) => {

                            switch (response.type) {

                                case HttpEventType.Response:
                                    if ( response.body != null && response.body.success === true ){

                                        this.filterEvent.emit(this._licensorsService._licensorfilterValue);

                                    }
                                    break;
                            }
                        },
                        error => {
                            console.log(error);
                        });
                }
                this.confirmDialogRef = null;
            });


    }

    publishStatement(statementId, licensor_id, published): void {


        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        let cMsg = this.translate.instant('LICENSOR.OVERVIEW.TABLE.PUBLISHQUESTION');
        if (published === 1){
            cMsg = this.translate.instant('LICENSOR.OVERVIEW.TABLE.UNPUBLISHQUESTION');
        }

        this.confirmDialogRef.componentInstance.confirmMessage = cMsg;

        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {

                    const formData = new FormData();
                    formData.append('licensor_id', licensor_id);
                    formData.append('published', published);
                    formData.append('rootUrl', window.location.origin);

                    this._licensorsService.publishStatement(statementId, formData).subscribe(
                        ( response: HttpEvent<any> ) => {

                            switch (response.type) {

                                case HttpEventType.Response:
                                    if ( response.body != null && response.body.success === true ){

                                        this.filterEvent.emit(this._licensorsService._licensorfilterValue);

                                    }
                                    break;
                            }
                        },
                        error => {
                            console.log(error);
                        });
                    /*
                     this._licensorsService.aproveStatement(statementId).subscribe(
                         ( response: HttpEvent<any> ) => {

                             switch (response.type) {

                                 case HttpEventType.Response:
                                     if ( response.body != null && response.body.success === true ){

                                         this.filterEvent.emit(this._licensorsService._licensorfilterValue);

                                     }
                                     break;
                             }
                         },
                         error => {
                             console.log(error);
                         });
                     */
                }
                this.confirmDialogRef = null;
            });


    }





}

