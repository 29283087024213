import {Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {FuseTranslationLoaderService} from '../../../../@fuse/services/translation-loader.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, NgForm} from '@angular/forms';
import {LicensorsService} from '@/main/licensors/licensors.service';
import {GlobalService} from '@/shared/services/global.service';
import {MatDialog, MatDialogRef} from '@angular/material';
import {locale as english} from '@/main/contracts/i18n/en';
import {locale as german} from '@/main/contracts/i18n/de';
import {ProductsService} from '@/main/products/products.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  productId: string;
  product = {
    'product_type' : 1,
    'client_id' : 0,
    'PPD' : 0,
    'bundled_audio' : [],
    'bundled_merch' : []
  };


  @ViewChild('productForm') productForm: NgForm;

  releaseChooser    = new FormControl();
  merchChooser      = new FormControl();

  constructor(
      private _location: Location,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private route: ActivatedRoute,
      private router: Router,
      private _formBuilder: FormBuilder,
      private _productService: ProductsService,
      private _globalService: GlobalService,
      public dialog: MatDialog
  ) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);

  }

    canDeactivate(): Observable<boolean> | boolean {
        return this._globalService.canDeactivate();
    }

  ngOnInit(): void {

    this.productId = this.route.snapshot.paramMap.get('id');
    console.log(this.productId);
    if (this.productId !== 'new') {
      // this.showUserList = false;
      this.getOne(this.productId);
    }else{
      // this.showUserList = true;
      // this.contract.licensor_id = this._globalService.getUserClientId();
      this.product.product_type = 1;
      this.product.client_id = this._globalService.getUserClientId();



      this._globalService.setFormData(this.product, Object.assign({}, this.product));

    }


  }

  back(): void {
    this._location.back();
  }

  getOne(productId): any {

    this._productService.getOne(
        'products/get/' + productId,
    ).subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){
                //console.log(response.body);
                this.product = response.body.data.items;

                  this._globalService.setFormData(this.product, Object.assign({}, this.product));

                  //this.form.patchValue( this.licensor );
              }
              break;
          }
        },
        error => {
          console.log("error get one: " );
          console.log(error);
        });

  }

  save(): void{

    if (this.productForm.form.valid) {

      let uri = 'products/save';
      if (this.productId !== 'new'){
        uri += '/' + this.productId;
      }
      let save = true;
      // VALIDATE BUNDLE PERCENTAGES! //
        if (this.product.product_type === 3) {
            let percentage = 0;
            if (this.product.bundled_audio.length > 0) {

                this.product.bundled_audio.forEach(function (item) {
                    percentage = percentage + item.revenue_percentage;
                });
            }
            if (this.product.bundled_merch.length > 0) {

                this.product.bundled_merch.forEach(function (item) {
                    percentage = percentage + item.revenue_percentage;
                });
            }

            if (percentage !== 100) {
                save = false;
                this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                    disableClose: false
                });

                this.confirmDialogRef.componentInstance.confirmMessage = 'Percentage of bundled components is not 100%';
                this.confirmDialogRef.afterClosed()
                    .subscribe(result => {
                        return false;
                        this.confirmDialogRef = null;
                    });
            }
        }

      // END //
        if (save) {
            this._productService.save(uri, this.product).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                //this.back();
                                this._globalService.setFormData(this.product, Object.assign({}, this.product));

                                if (this.productId === 'new') {
                                    this.askForContinueAdding();
                                }
                            }
                            break;
                    }

                },
                error => {
                    console.log(error);
                });
        }

    }

  }

  askForContinueAdding(): void{

       this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
           disableClose: false
       });

       this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to add more items?';
       this.confirmDialogRef.afterClosed()
           .subscribe(result => {
               if ( result ){
                   this.product = {
                       'product_type' : 1,
                       'client_id' : 0,
                       'PPD' : 0,
                       'bundled_audio' : [],
                       'bundled_merch' : []
                   };
                   this.productForm.resetForm();
               }else{
                   this._location.back();
               }
               this.confirmDialogRef = null;
           });
  }

    addRelease(release): void{
        console.log(release);
        this.product.bundled_audio.push({
            'id'                : release.release_id,
            'catalog_number'    : release.catalog_number,
            'upc'               : release.upc,
            'ean'               : release.ean,
            'artist'            : release.artist,
            'title'             : release.title + ((release.sub_title !== null && release.sub_title !== '') ? '(' + release.sub_title + ')' : ''),
            'revenue_percentage': 0
        });

    }

    addMerch(product): void{

      this.product.bundled_merch.push({
          'id' : product.product_id,
          'item_number' : product.item_number,
          'product_identifier' : product.product_identifier,
          'product_name' : product.product_name,
          'revenue_percentage' : 0
      });

    }

}
