import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractsComponent } from './contracts.component';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import {RouterModule} from '@angular/router';
import {
  MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonModule,
  MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule,
  MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatGridListModule,
  MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatPaginatorModule,
  MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule,
  MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule,
  MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule
} from '@angular/material';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {AuthGuardService as AuthGuard } from '@/auth/auth-guard.service';
import {FuseSidebarModule} from '../../../@fuse/components';
import {ContractsService} from '@/main/contracts/contracts.service';

import { ContractDetailComponent } from './contract-detail/contract-detail.component';
import {SharedModule} from '@/shared/shared.module';
import {ContractListComponent} from '@/main/contracts/contract-list/contract-list.component';
import {TracksModule} from '@/main/tracks/tracks.module';
import {ProductsModule} from '@/main/products/products.module';
import { ContractImportModalComponent } from './import-modal/contract-import-modal.component';
import {CanDeactivateGuard} from '@/shared/services/can-deactivate-guard.service';


const routes = [
  {
    path     : 'contracts',
    component: ContractsComponent,
    canActivate: [AuthGuard],
    /*
    resolve  : {
      files: ExpensetypesService
    }
     */
  },
    {
        path    : 'contracts/:id',
        component: ContractDetailComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatMomentDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        TranslateModule,
        FuseSharedModule,
        FuseSidebarModule,
        TracksModule,
        SharedModule,
        ProductsModule
    ],
  providers   : [
      ContractsService
  ],
    declarations: [ContractsComponent, ContractDetailComponent, ContractListComponent, ContractImportModalComponent ],
  entryComponents: [ ContractImportModalComponent ],
    exports: [
        ContractsComponent,
        ContractListComponent
    ]
})
export class ContractsModule { }
