export const locale = {
    lang: 'en',
    data: {
        'ANALYTICS': {
            'OVERALLREVENUE' : 'Revenue',
            'HEADLINE': 'Analytics',
            'PERIOD' : {
                'FROM' : 'Royalty period from',
                'TO' : 'Royalty period from'
            },
            'GRAPH' : {
                'OVERALL' : 'Overall',
                'DIGITAL' : 'Digital',
                'PHYSICAL' : 'Physical',
                'SUBLICENSE' : 'Sublicense',
                'MERCH' : 'Merchandise'
            },
            'TABS' : {
                'DIGITALPERLICENSOR' : 'Digital per Licensor',
                'PHYSICALPERLICENSOR' : 'Physical per Licensor',
                'MERCHPERLICENSOR' : 'Merchandise per Licensor',
                'SUBLICENSEPERLICENSOR' : 'Sublicense per Licensor'
            }
        }
    }
};
