import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {Location} from '@angular/common';
import {FuseSidebarService} from '../../../../../@fuse/components/sidebar/sidebar.service';
import {AccountingService} from '@/main/accounting/accounting.service';
import {GlobalService} from '@/shared/services/global.service';
import {ActivatedRoute, Router} from '@angular/router';
import {locale as english} from '@/main/accounting/i18n/en';
import {locale as german} from '@/main/accounting/i18n/de';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {FuseConfirmDialogComponent} from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-imported-files',
  templateUrl: './imported-files.component.html',
  styleUrls: ['./imported-files.component.scss']
})
export class ImportedFilesComponent implements OnInit {

    @Input() periodId: any;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ['id', 'filename', 'currencycourse', 'imported_rows', 'imported_revenue', 'error_rows', 'error_revenue', 'rows', 'revenue', 'action'];

    private importedFiles: any;
    private importRevenueSum: any;
    private errorImportRevenueSum: any;
    private importedRevenueSum: any;

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
              private _location: Location,
              private _fuseSidebarService: FuseSidebarService,
              private _accountingService: AccountingService,
              private _globalService: GlobalService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router) {
      this._fuseTranslationLoaderService.loadTranslations(english, german);
  }

  ngOnInit(): void {
      console.log(this.periodId);

      this.loadImportedFiles();

  }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    askForDelete(fileId): void{

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete this File?';

        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {
                    this._accountingService.deleteImportedFile(fileId).subscribe(
                        ( response: HttpEvent<any> ) => {
                            switch (response.type) {
                                case HttpEventType.Response:
                                    if ( response.body != null && response.body.success === true ){
                                        this.loadImportedFiles();
                                    }
                                    break;
                            }
                        },
                        error => {
                            console.log('error get one: ' );
                            console.log(error);
                        });


                }
                this.confirmDialogRef = null;
            });
    }

    loadImportedFiles(): void {
        this._accountingService.getImportedFiles(this.periodId).subscribe(
            ( response: HttpEvent<any> ) => {
                switch (response.type) {
                    case HttpEventType.Response:
                        if ( response.body != null && response.body.success === true ){


                            this.importedFiles = response.body.items;
                            this.dataSource = new MatTableDataSource(this.importedFiles);
                            this.dataSource.paginator = this.paginator;
                            this.dataSource.sort = this.sort;
                            this.importRevenueSum = response.body.revenueSum;
                            this.errorImportRevenueSum = response.body.errorRevenueSum;
                            this.importedRevenueSum = response.body.importedRevenueSum;
                        }
                        break;
                }
            },
            error => {
                console.log('error get one: ' );
                console.log(error);
            });
    }

    getErrorReportFiles(file_id): void {
      this._accountingService.downloadErrorReport(file_id).subscribe(
          ( response: HttpEvent<any> ) => {

              switch (response.type) {

                  case HttpEventType.Response:
                      fileSaver.saveAs(response.body, 'error_report_file_' + file_id + '.zip');
              }

          },
          error => {
              console.log(error);
          });
    }

}
