import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable()
export class FileService {

  constructor(private httpClient: HttpClient, private _auth: OAuthService) { }

  sendFile(formData: any){
    //assume your backend base url is http://127.0.0.1:8000
    let baseUrl = '';
    let url = `${baseUrl}/api/file`;

    const token = this._auth.getAccessToken();
    //console.log(token);

    const httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + token);

    //console.log(formData);
    return this.httpClient.post(url, formData,{ headers : httpHeaders } );
  }


  updateUserData(formData: any){
    //assume your backend base url is http://127.0.0.1:8000
    let baseUrl = '';
    let url = `${baseUrl}/api/user/update/3`;

    const token = this._auth.getAccessToken();
    //console.log(token);

    const httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + token);

    //console.log(formData);
    return this.httpClient.post(url, formData,{ headers : httpHeaders } );
  }


}
