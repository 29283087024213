import { NgModule } from '@angular/core';
import {InterfaceCreatorService} from '@/main/components/interface-creator/interface-creator.service';


@NgModule({
    imports: [
    ],
    providers   : [
        InterfaceCreatorService
    ],
    declarations: [ ],
    entryComponents: [ ],
    exports: [

    ]
})
export class InterfaceCreatorModule { }
