import { Component, OnInit } from '@angular/core';
import {GlobalService} from '@/shared/services/global.service';
import {DashboardService} from '@/main/dashboard/dashboard.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {FuseTranslationLoaderService} from '../../../@fuse/services/translation-loader.service';
import {locale as english} from '@/main/dashboard/i18n/en';
import {locale as german} from '@/main/dashboard/i18n/de';
import {LogdialogComponent} from '@/main/components/logdialog/logdialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-automator',
  templateUrl: './automator.component.html',
  styleUrls: ['./automator.component.scss']
})
export class AutomatorComponent implements OnInit {


    constructor(public dialog: MatDialog,
                private _globalService: GlobalService,
                private _dashboardService: DashboardService,
                private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    confirmDialogRef: MatDialogRef<LogdialogComponent>;

    runningTasks: any = [];
    finishedTasks: any = [];
    errorTasks: any = [];

    ngOnInit(): void {

        this._dashboardService.getRunningServerTasks().subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
              case HttpEventType.Response:
                  if ( response.body != null && response.body.success === true ){

                      this.runningTasks = response.body.items;

                  }
                  break;
          }
        },
        error => {
          console.log("error get one: " );
          console.log(error);
        });

        this._dashboardService.getFinishedServerTasks().subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
              case HttpEventType.Response:
                  if ( response.body != null && response.body.success === true ){

                      this.finishedTasks = response.body.items;

                  }
                  break;
          }
        },
        error => {
          console.log("error get one: " );
          console.log(error);
        });

        this._dashboardService.getErrorTasks().subscribe(
            ( response: HttpEvent<any> ) => {
                switch (response.type) {
                    case HttpEventType.Response:
                        if ( response.body != null && response.body.success === true ){

                            this.errorTasks = response.body.items;

                        }
                        break;
                }
            },
            error => {
                console.log("error get one: " );
                console.log(error);
            });

    }

    openInfo(log, jsons = {}): void {

            this.confirmDialogRef = this.dialog.open(LogdialogComponent, {
                disableClose: false,
                data: {log: log != null ? log.replace(new RegExp('\r?\n', 'g'), '<br />') : '', jsons: jsons}
            });

            this.confirmDialogRef.afterClosed()
                .subscribe(result => {

                    this.confirmDialogRef = null;
                });

    }

}
