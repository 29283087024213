import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {BusyIndicatorService} from '@/main/components/busyindicator/busy-indicator.service';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'busy-indicator',
    templateUrl: 'busy-indicator.component.html',
    styleUrls: ['./busy-indicator.component.scss'],
})

export class BusyIndicatorComponent implements AfterViewInit, OnDestroy  {

    debounceTime: number = 200;
    loading: boolean = false;
    biSubscription: Subscription;

    constructor(private _busyIndicatorService: BusyIndicatorService,
                private _elmRef: ElementRef,
                private _changeDetectorRef: ChangeDetectorRef) {

        //console.log('BusyIndicatorComponent + constructor');
    }

    ngAfterViewInit(): void {
        //console.log('ngOnInit');
        this._elmRef.nativeElement.style.display = 'none';
        this.biSubscription = this._busyIndicatorService.loadingStatus.pipe(debounceTime(this.debounceTime)).subscribe(
            (status: boolean) => {
                this._elmRef.nativeElement.style.display = status ? 'block' : 'none';
                this._changeDetectorRef.detectChanges();
            }
        );
    }

    ngOnDestroy(): void {
        this.biSubscription.unsubscribe();
    }
}
