import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FuseConfigService} from '../../../@fuse/services/config.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FuseTranslationLoaderService} from '../../../@fuse/services/translation-loader.service';
import {ActivatedRoute, Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {GlobalService} from '@/shared/services/global.service';
import {locale as english} from '@/login/i18n/en';
import {locale as german} from '@/login/i18n/de';
import {fuseAnimations} from '../../../@fuse/animations';
import {HttpEvent, HttpEventType} from '@angular/common/http';

@Component({
  selector: 'forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ForgotpasswordComponent implements OnInit {

    forgotPasswordForm: FormGroup;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private route: ActivatedRoute,
        private router: Router,
        private oauthService: OAuthService,
        private _globalService: GlobalService
    ){
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

  ngOnInit() {
      this.forgotPasswordForm = this._formBuilder.group({
          email: ['', [Validators.required, Validators.email]]
      });
  }

    get f() { return this.forgotPasswordForm.controls; }

    onSubmit(): void {

        this._globalService.requestPasswordReset({email : this.f.email.value, url: window.location.href }).subscribe(
            ( response: HttpEvent<any> ) => {
                switch (response.type) {
                    case HttpEventType.Response:
                        if ( response.body != null && response.body.success === true ){
                            console.log(response.body);
                        }
                        break;
                }
            },
            error => console.log(error)
        );

    }

}
