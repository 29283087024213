import {AfterViewInit, Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatSort, MatTabChangeEvent} from '@angular/material';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {FuseTranslationLoaderService} from '../../../../@fuse/services/translation-loader.service';
import {FuseSidebarService} from '../../../../@fuse/components/sidebar/sidebar.service';
import {ContractsService} from '@/main/contracts/contracts.service';
import {GlobalService} from '@/shared/services/global.service';
import {locale as english} from '@/main/accounting/i18n/en';
import {locale as german} from '@/main/accounting/i18n/de';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {AccountingService} from '@/main/accounting/accounting.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {RevenueDigitalComponent} from '@/main/accounting/royalty-periods-detail/revenue-digital/revenue-digital.component';
import {RevenuePhysicalComponent} from '@/main/accounting/royalty-periods-detail/revenue-physical/revenue-physical.component';
import {RevenueMerchComponent} from '@/main/accounting/royalty-periods-detail/revenue-merch/revenue-merch.component';
import {RevenueSublicenseComponent} from '@/main/accounting/royalty-periods-detail/revenue-sublicense/revenue-sublicense.component';
import {ActivatedRoute, Router} from '@angular/router';
import {SalesImportComponent} from '@/main/accounting/sales-import/sales-import.component';
import {ReturnsComponent} from '@/main/accounting/royalty-periods-detail/returns/returns.component';
import {Location} from '@angular/common';
import {McmsSalesSyncModalComponent} from '@/main/accounting/sales-import/mcms-sales-sync-modal/mcms-sales-sync-modal.component';
import {LicensorListComponent} from '@/main/licensors/licensor-list/licensor-list.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-royalty-periods-detail',
  templateUrl: './royalty-periods-detail.component.html',
  styleUrls: ['./royalty-periods-detail.component.scss']
})
export class RoyaltyPeriodsDetailComponent implements OnInit {

  @ViewChild('revenueDigital')  _revenueDigital: RevenueDigitalComponent;
  @ViewChild('revenuePhysical') _revenuePhysical: RevenuePhysicalComponent;
  @ViewChild('revenueMerch')    _revenueMerch: RevenueMerchComponent;
  @ViewChild('revenueSublicense') _revenueSublicense: RevenueSublicenseComponent;
  @ViewChild('salesImport') _salesImport: SalesImportComponent;
  @ViewChild('returns') _returns: ReturnsComponent;
  @ViewChild('licensorList') _statements: LicensorListComponent;

  periodId: any = 0;
  period: any = {};
  activeTab: string = '_salesImport';

  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  tabs = {
    0: '_salesImport',
    1: '_revenueDigital',
    2: '_revenuePhysical',
    3: '_revenueMerch',
    4: '_revenueSublicense',
    5: '_statements',
    6: '_closePeriod'
  };

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
              private _location: Location,
              private _fuseSidebarService: FuseSidebarService,
              private _accountingService: AccountingService,
              private _globalService: GlobalService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              public translate: TranslateService) {
    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }

  ngOnInit(): void {

    this.periodId = this.route.snapshot.paramMap.get('id');

    if (this.periodId !== 0) {
      this.getOne(this.periodId);
    }

  }

  closePeriod(): void {

      this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
          disableClose: false
      });

      this.confirmDialogRef.componentInstance.confirmMessage = this.translate.instant('ACCOUNTING.PERIODS.DETAIL.CLOSEPERIODWARNING');

      this.confirmDialogRef.afterClosed()
          .subscribe(result => {
              if ( result )
              {

                  this._accountingService.closePeriod(
                      this.periodId,
                  ).subscribe(
                      ( response: HttpEvent<any> ) => {
                          switch (response.type) {
                              case HttpEventType.Response:
                                  if ( response.body != null && response.body.success === true ){

                                      this._location.back();

                                  }
                                  break;
                          }
                      },
                      error => {
                          console.log('error get one: ' );
                          console.log(error);
                      });

              }
              this.confirmDialogRef = null;
          });

  }

  getOne(uid: string): void{

    this._accountingService.getOnePeriod(
        'accounting/period/' + uid,
    ).subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){

                this.period = response.body.data;
                //console.log("period");
                console.log(this.period);
              }
              break;
          }
        },
        error => {
          console.log('error get one: ' );
          console.log(error);
        });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.activeTab = this.tabs[tabChangeEvent.index];
    //console.log(this.activeTab);
  }

  addNew(activeTab): void {

    this[activeTab].openAddNewModal(this.periodId,0);

    //eval('this.' + activeTab + '.openAddNewModal(' + this.periodId + ')');

  }

  askForDelete(activeTab): void {
    return this[activeTab].askForDelete();
  }

  isChecked(activeTab): boolean {
    if (activeTab !== '_closePeriod') {
        return this[activeTab].isChecked;
    }

    //return eval('this.' + activeTab + '.isChecked');
  }

    openMCMSSalesSyncModal(): void {

        const dialogRef = this.dialog.open(McmsSalesSyncModalComponent, {
            //width: '500px',
            //height: '400px',
            data: { accountingPeriodId: this.periodId },
        });

        dialogRef.afterClosed().subscribe(result => {



        });

    }

}
