import {AfterViewInit, Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatSort} from '@angular/material';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {FuseTranslationLoaderService} from '../../../../@fuse/services/translation-loader.service';
import {FuseSidebarService} from '../../../../@fuse/components/sidebar/sidebar.service';
import {ContractsService} from '@/main/contracts/contracts.service';
import {GlobalService} from '@/shared/services/global.service';
import {locale as english} from '@/main/accounting/i18n/en';
import {locale as german} from '@/main/accounting/i18n/de';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {AccountingService} from '@/main/accounting/accounting.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';

@Component({
  selector: 'app-royalty-periods',
  templateUrl: './royalty-periods.component.html',
  styleUrls: ['./royalty-periods.component.scss']
})
export class RoyaltyPeriodsComponent implements AfterViewInit {

  @Input() licensorId: any;
  @Input() isModal = false;

  displayedColumns: string[];
  data: any[] = [];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  filterValue: string;
  filterInt: any;
  filterEvent: EventEmitter<String> = new EventEmitter(false);

  selectedPeriods: string[] = [];
  //selectedPeriodItems: string[] = [];
  selectedRowModal: any;
  isChecked = false;

  resultsLength = 0;

  public modalDialogRef: MatDialogRef<any>;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor( private _fuseTranslationLoaderService: FuseTranslationLoaderService,
               private _fuseSidebarService: FuseSidebarService,
               private _accountingService: AccountingService,
               private _globalService: GlobalService,
               public dialog: MatDialog) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);


    if (this._globalService.isAdmin()) {
      this.displayedColumns = [ 'checkbox', 'client', 'identifier', 'royalty_period_start', 'royalty_period_end', 'income', 'accounted', 'period_closed' ];
    } else {
      this.displayedColumns = [ 'checkbox', 'identifier', 'royalty_period_start', 'royalty_period_end', 'income', 'accounted', 'period_closed' ];
    }

  }

  ngAfterViewInit(): void {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.filterEvent.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, this.filterEvent)
        .pipe(
            startWith({}),
            switchMap(() => {

              return this._accountingService.loadPeriods(
                  'accounting/getPeriods',
                  this.filterValue,
                  this.sort.direction,
                  this.sort.active,
                  this.paginator.pageIndex,
                  this.paginator.pageSize
              );
            }),
            map(data => {
              // Flip flag to show that loading has finished.
              this.resultsLength = data.total_count;
              return data.data.items;
            }),
            catchError(() => {
              return observableOf([]);
            })
        ).subscribe(data => this.data = data);

  }

  onSelectedChange(id: any): void{

        if ( this.selectedPeriods.length > 0 ){

            const index = this.selectedPeriods.indexOf(id);

            if ( index !== -1 ){

                this.selectedPeriods.splice(index, 1);

                if ( this.selectedPeriods.length === 0 ){
                    this.isChecked = false;
                }
                return;
            }
        }

        this.selectedPeriods.push(id);
        this.isChecked = true;
    }
    /*
    onSelectedChangeModal(item: any): void{

        if ( this.selectedPeriodItems.length > 0 ){

            const index = this.selectedPeriodItems.indexOf(item);

            if ( index !== -1 ){

                this.selectedPeriodItems.splice(index, 1);
                return;
            }
        }
        this.selectedPeriodItems.push(item);
   }
   */
   selectRowForModal(row: any): void{
       this.selectedRowModal = row;
       //console.log(row);
   }


  askForDelete(): void{

    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete all selected items?';

    this.confirmDialogRef.afterClosed()
        .subscribe(result => {
          if ( result )
          {
            this.delete();
          }
          this.confirmDialogRef = null;
        });
  }

  delete(): void{

    const formData = new FormData();
    formData.append('ids', JSON.stringify(this.selectedPeriods));
    //console.log(JSON.stringify(this.selectedClients))

    this._accountingService.deletePeriods('accounting/deletePeriods', formData).subscribe(
        ( response: HttpEvent<any> ) => {

          switch (response.type) {

            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){

                this.selectedPeriods.length = 0;
                this.isChecked = false;
                this.filterEvent.emit(this.filterValue);

              }
              break;
          }
        },
        error => {
          console.log(error);
        });
  }

  applyFilter(filterValue: string): void  {
    clearInterval(this.filterInt);
    this.filterInt = setInterval(() => { this.callFilter(filterValue); }, 1000);
  }

  callFilter(filterValue: string): void {
    clearInterval(this.filterInt);
    this.filterValue = filterValue;
    this.filterEvent.emit(this.filterValue);
  }



}
