import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AnalyticsService} from '@/main/analytics/analytics.service';
import {GlobalService} from '@/shared/services/global.service';
import {AccountingService} from '@/main/accounting/accounting.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from './i18n/de';
import {locale as english } from './i18n/en';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

    widget5SelectedDay = 'today';

    revenueByLicensor: any;
    digitalRevenueByLicensor: any;
    physicalRevenueByLicensor: any;
    merchRevenueByLicensor: any;
    sublicenseRevenueByLicensor: any;

    periods: any;

    selectedGraph = 'overall';

    period = {
        from : 0,
        to: 0
    };

    chart = {
    chartType: 'line',
    datasets : {
        'overall': [
            {
                label: 'Overall Revenue',
                data : [],
                fill : 'start'

            }
        ],
        'digital': [
            {
                label: 'Digital Revenue',
                data : [],
                fill : 'start'

            }
        ],
        'physical': [
            {
                label: 'Physical Revenue',
                data : [],
                fill : 'start'

            }
        ],
        'merch': [
            {
                label: 'Merch Revenue',
                data : [],
                fill : 'start'

            }
        ],
        'sublicense': [
            {
                label: 'Sublicense Revenue',
                data : [],
                fill : 'start'

            }
        ]
    },
    labels   : [],
    colors   : [
        {
            borderColor              : '#3949ab',
            backgroundColor          : '#3949ab',
            pointBackgroundColor     : '#3949ab',
            pointHoverBackgroundColor: '#3949ab',
            pointBorderColor         : '#ffffff',
            pointHoverBorderColor    : '#ffffff'
        },
        {
            borderColor              : 'rgba(30, 136, 229, 0.87)',
            backgroundColor          : 'rgba(30, 136, 229, 0.87)',
            pointBackgroundColor     : 'rgba(30, 136, 229, 0.87)',
            pointHoverBackgroundColor: 'rgba(30, 136, 229, 0.87)',
            pointBorderColor         : '#ffffff',
            pointHoverBorderColor    : '#ffffff'
        }
    ],
    options  : {
        spanGaps           : false,
        legend             : {
            display: false
        },
        maintainAspectRatio: false,
        tooltips           : {
            position : 'nearest',
            mode     : 'index',
            intersect: false
        },
        layout             : {
            padding: {
                left : 24,
                right: 32
            }
        },
        elements           : {
            point: {
                radius          : 4,
                borderWidth     : 2,
                hoverRadius     : 4,
                hoverBorderWidth: 2
            }
        },
        scales             : {
            xAxes: [
                {
                    gridLines: {
                        display: false
                    },
                    ticks    : {
                        fontColor: 'rgba(0,0,0,0.54)'
                    }
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        tickMarkLength: 16
                    },
                    ticks    : {
                        stepSize: 100000
                    }
                }
            ]
        },
        plugins            : {
            filler: {
                propagate: false
            }
        }
    }
};


  constructor(
      private analyticsService: AnalyticsService,
      private cdr: ChangeDetectorRef,
      private _globalService: GlobalService,
      private _accountingService: AccountingService,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
      this._fuseTranslationLoaderService.loadTranslations(english, german);
  }

  ngOnInit(): any {

      this.getPeriods();
      this.loadData();

  }

  loadData(): any {

      this.getOverallRevenue(this.period.from, this.period.to);
      this.getRevenueByLicensor(this.period.from, this.period.to);
      this.getPhysicalRevenueByLicensor(this.period.from, this.period.to);
      this.getMerchRevenueByLicensor(this.period.from, this.period.to);
      this.getSublicenseRevenueByLicensor(this.period.from, this.period.to);

  }

  getOverallRevenue(from, to): any {

      this.analyticsService.getOverallRevenue(from, to).subscribe((data:any) => {
          if (data) {
              this.chart.datasets.overall[0].data = data.overall.yData;
              this.chart.datasets.digital[0].data = data.digital.yData;
              this.chart.datasets.physical[0].data = data.physical.yData;
              this.chart.datasets.merch[0].data = data.merch.yData;
              this.chart.datasets.sublicense[0].data = data.sublicense.yData;
              this.chart.labels = data.overall.xData;
              this.cdr.detectChanges();
          }
      });

  }

  getRevenueByLicensor(from, to): any {
      this.analyticsService.getRevenueByLicensor(from, to).subscribe((data:any) => {
          if (data) {
              this.revenueByLicensor = data.licensors;
              this.cdr.detectChanges();
          }
      });
  }

    getPhysicalRevenueByLicensor(from, to): any {
        this.analyticsService.getPhysicalRevenueByLicensor(from, to).subscribe((data:any) => {
            if (data) {
                this.physicalRevenueByLicensor = data.licensors;
                this.cdr.detectChanges();
            }
        });
    }

    getMerchRevenueByLicensor(from, to): any {
        this.analyticsService.getMerchRevenueByLicensor(from, to).subscribe((data:any) => {
            if (data) {
                this.merchRevenueByLicensor = data.licensors;
                this.cdr.detectChanges();
            }
        });
    }

    getSublicenseRevenueByLicensor(from, to): any {
        this.analyticsService.getSublicenseRevenueByLicensor(from, to).subscribe((data:any) => {
            if (data) {
                this.sublicenseRevenueByLicensor = data.licensors;
                this.cdr.detectChanges();
            }
        });
    }

  getPeriods(): any {
      this._accountingService.getPeriodsList().subscribe( ( data: any ) => {
          if (data) {
              this.periods = data.periods;
              console.log(this.periods);
          }
      });
  }

  openHelp(): any {

  }
}
