import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA, MatDialog, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {ClientsService} from '@/main/clients/clients.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {GlobalService} from '@/shared/services/global.service';
import {AccountingService} from '@/main/accounting/accounting.service';
import {MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import {MomentUtcDateAdapter} from '@/shared/momentUtcAdapter';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {debounceTime, startWith, switchMap} from 'rxjs/operators';
import {isObject} from 'util';


export interface DialogData {
    periodId: number;
    clientId: number;
    salesRow: any;
}

@Component({
    selector: 'add-sound-recording-sales-modal',
    templateUrl: 'add-sound-recording-sales-modal.component.html',
    providers : [
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    ]
})
export class AddSoundRecordingSalesModalComponent implements AfterViewInit {

    form: FormGroup;
    sale: any = {
        'sale_type' : ''
    };

    releaseChooser = new FormControl();
    trackChooser   = new FormControl();

    @ViewChild('soundRecordingSalesAddModalForm') soundRecordingSalesAddModalForm: NgForm;

    constructor(
        public dialogRef: MatDialogRef<AddSoundRecordingSalesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _formBuilder: FormBuilder,
        private _globalService: GlobalService,
        private _accountingService: AccountingService,
        private _translateService: TranslateService,
        public dialog: MatDialog)
    {


    }

    ngAfterViewInit(): void {

        this.sale.period_id = this.data.periodId;
        this.sale.client_id = this.data.clientId;

        if (isObject(this.data.salesRow) && this.data.salesRow.sound_recording_sale_id !== undefined) {
            this.sale = this.data.salesRow;
        }

        console.log(this.data);

        this.releaseChooser.valueChanges.subscribe(value => this.releaseChosen(value));

    }

    releaseChosen(release): void {

        this.sale.release_id      = release.release_id;
        this.sale.catalog_number  = release.catalog_number;
        this.sale.ean             = release.ean;
        this.sale.upc             = release.upc;
        this.sale.release_artist  = release.artist;
        this.sale.release_title   = release.title + (release.sub_title !== '' ? ' (' + release.sub_title + ')' : '');
        this.sale.PPD             = release.PPD;

        console.log(this.sale);
    }

    onNoClick(): void {

        this.dialogRef.close(false);
    }



    save(): void
    {

        if (this.soundRecordingSalesAddModalForm.form.valid) {

            let uri = 'accounting/sales/saveSoundRecordingSale';

            this._accountingService.saveSoundRecordingSale(uri, this.sale).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this.dialogRef.close(false);
                            }
                            break;
                    }

                },
                error => {
                    console.log(error);
                });
        }else{

        }

    }




}
