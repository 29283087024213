import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {MatDialog} from '@angular/material';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

    httpHeaders: HttpHeaders;

    constructor(
        private _httpClient: HttpClient,
        private _auth: OAuthService,
        public dialog: MatDialog
    ){
        this.httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + this._auth.getAccessToken());
    }

    getNews(): any {

        return this._httpClient.get('/dashboard/getNews', {reportProgress: false, observe: 'events', headers : this.httpHeaders } );

    }

    getLatestStatements(): any{

        return this._httpClient.get('/api/accounting/getLatestStatements', {reportProgress: false, observe: 'events', headers : this.httpHeaders } );

    }

    getLatestLicensorStatements(licensorId): any{
        return this._httpClient.get('/api/accounting/getLatestStatements/' + licensorId, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    getLatestPublishedLicensorStatements(licensorId): any{
        return this._httpClient.get('/api/accounting/getLatestPublishedStatements/' + licensorId, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    getRunningServerTasks(): any {

        return this._httpClient.get('/api/import/getRunningTasks', {reportProgress: false, observe: 'events', headers : this.httpHeaders } );

    }

    getFinishedServerTasks(): any {

        return this._httpClient.get('/api/import/getFinishedTasks', {reportProgress: false, observe: 'events', headers : this.httpHeaders } );

    }

    getErrorTasks(): any {
        return this._httpClient.get('/api/import/getErrorTasks', {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }
}
