import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {InterfaceCreatorService} from '@/main/components/interface-creator/interface-creator.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {forEach} from '@angular/router/src/utils/collection';

export interface DialogData {
    filename: string;
    location: string;
    period_id: number;
    sheetdata: null;
}

@Component({
    selector: 'interface-creator',
    templateUrl: 'interface-creator.component.html',
    styleUrls: ['./interface-creator.component.scss']
})

export class InterfaceCreatorComponent implements AfterViewInit{

    selectedSheets: any = [];

    constructor(private _interfaceCreatorService: InterfaceCreatorService,
                public dialogRef: MatDialogRef<InterfaceCreatorComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    }

    onSelectedChange(id: any): void{

        if ( this.selectedSheets.length > 0 ){

            const index = this.selectedSheets.indexOf(id);

            if ( index !== -1 ){

                this.selectedSheets.splice(index, 1);


                return;
            }
        }
        this.selectedSheets.push(id);
        console.log(this.selectedSheets);
    }

    ngAfterViewInit(): void {
        //console.log(this.data);

        const fileInfo = {'filename': this.data.filename, 'location': this.data.location, 'period_id': this.data.period_id};

        console.log(fileInfo);
        this._interfaceCreatorService.icReadFile(fileInfo).subscribe(
            ( response: HttpEvent<any> ) => {

                //console.log(response);

                switch (response.type) {

                    case HttpEventType.Response:
                        if ( response.body != null && response.body.success === true ){
                            this.data.sheetdata = response.body.data;
                        }
                        break;
                }

            },
            error => {
                console.log(error);
            });

    }

    loadFreshDataInTab($event): void {
        console.log("test");
        /*
        if (this.tabMapping[$event] === 'importedFiles') {
            this.importedFiles.loadImportedFiles();
        }
        if (this.tabMapping[$event] === 'runningImports') {
            this.runningImports.loadImportingFiles();
        }
        if (this.tabMapping[$event] === 'errorImports') {
            this.errorImports.loadImportingFiles();
        }
        if (this.tabMapping[$event] === 'this') {
            this.filterEvent.emit();
        }
        */

    }

    closeInterfaceCreator(data = false): void {
        //console.log(data);
        this.dialogRef.close({data: data});
    }

}
