import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {GlobalService} from '@/shared/services/global.service';
import {AccountingService} from '@/main/accounting/accounting.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter} from '@angular/material';
import {HttpEvent, HttpEventType} from '@angular/common/http';

export interface DialogData {
    'accountingPeriodId': any;
}

@Component({
  selector: 'app-mcms-sales-sync-modal',
  templateUrl: './mcms-sales-sync-modal.component.html',
  styleUrls: ['./mcms-sales-sync-modal.component.scss']
})
export class McmsSalesSyncModalComponent implements OnInit {

    private availablePeriods: any;

    private importData = {
        selectedPeriods : [],
        includeShops : false,
        video: true,
        audio: true,
        periodId : 0,
        userinfo : {}
    };



  constructor(public dialogRef: MatDialogRef<McmsSalesSyncModalComponent>,
              private _formBuilder: FormBuilder,
              private _globalService: GlobalService,
              private _accountingService: AccountingService,
              private _translateService: TranslateService,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {

      this.availablePeriods = [];
  }

  ngOnInit(): void {

      console.log(this.data);

      this.importData.periodId = this.data.accountingPeriodId;
      this.importData.userinfo = this._globalService.userinfo;

      this._accountingService.getAvailablePeriods(this.data.accountingPeriodId).subscribe(
          ( response: HttpEvent<any> ) => {

              switch (response.type) {

                  case HttpEventType.Response:
                      this.availablePeriods = response.body.data;
                      console.log(this.availablePeriods);
                      break;
              }

          },
          error => {
              console.log(error);
          });
  }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    save(): void {

        this._accountingService.MCMSSalesSync(this.importData).subscribe(
            (response: HttpEvent<any>) => {
                switch (response.type) {

                    case HttpEventType.Response:
                        if ( response.body != null && response.body.success === true ){

                        }else{

                        }
                        break;
                }
            },
            error => {
                console.log(error);
            });


    }

    onSelectedChange(id: any): void{

        if ( this.importData.selectedPeriods.length > 0 ){

            const index = this.importData.selectedPeriods.indexOf(id);

            if ( index !== -1 ){

                this.importData.selectedPeriods.splice(index, 1);

                return;
            }
        }
        this.importData.selectedPeriods.push(id);
    }


}
