import {Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {FuseTranslationLoaderService} from '../../../../@fuse/services/translation-loader.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, NgForm} from '@angular/forms';
import {LicensorsService} from '@/main/licensors/licensors.service';
import {GlobalService} from '@/shared/services/global.service';
import {MatDialog} from '@angular/material';
import {locale as english} from '@/main/contracts/i18n/en';
import {locale as german} from '@/main/contracts/i18n/de';
import {ProductsService} from '@/main/products/products.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {TrackChooserComponent} from '@/main/components/track-chooser/track-chooser.component';
import {isObject} from 'util';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-release-detail',
  templateUrl: './release-detail.component.html',
  styleUrls: ['./release-detail.component.scss']
})
export class ReleaseDetailComponent implements OnInit {

  releaseId: string;
  release = {
    client_id: 0,
    tracklist: [],
    ean : '',
    upc : '',
    release_id : ''
  };
  ceanupc : string;
  eanupclist: [];

  trackChoserCtrl = new FormControl();

  artists_options = {
    'unique_value': true,
    'field1' : {
      'key'       : 'role',
      'label'     : 'Role',
      'type'      : 'dropdown',
      'source'    : 'array',
      'array'     : this._globalService.artistRoles
    },
    'field2'    : {
      'key'   : 'name',
      'label' : 'Name',
      'type'  : 'text'
    }
  };

  @ViewChild('releaseForm') releaseForm: NgForm;
  @ViewChild('trackChoser') trackChoser: TrackChooserComponent;

  constructor(
      private _location: Location,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private route: ActivatedRoute,
      private router: Router,
      private _formBuilder: FormBuilder,
      private _productService: ProductsService,
      private _globalService: GlobalService,
      private _translateService: TranslateService,
      public dialog: MatDialog
  ) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);

  }

  canDeactivate(): Observable<boolean> | boolean {
        return this._globalService.canDeactivate();
  }

  ngOnInit(): void {

    this.releaseId = this.route.snapshot.paramMap.get('id');
    //console.log(this.releaseId);
    if (this.releaseId !== 'new') {
      // this.showUserList = false;
      this.getOne(this.releaseId);
    }else{
      // this.showUserList = true;
      // this.contract.licensor_id = this._globalService.getUserClientId();

        if (this.route.snapshot.paramMap.get('id2') !== undefined && this.route.snapshot.paramMap.get('id2') !== '' && this.route.snapshot.paramMap.get('id2') !== null ) {

            this.getOneForDuplication(this.route.snapshot.paramMap.get('id2'));
        } else {
            this.release.client_id = this._globalService.getUserClientId();
            this._globalService.setFormData(this.release, Object.assign({}, this.release));
            console.log(this.release);
        }
    }

    this.getEANUPCList();
  }


  addEanUpc(): void {
     if (this.ceanupc !== '') {

         if (this.ceanupc.length < 12) {

            alert('minimum 12 digits!');

         } else {

             this._productService.saveEANUPC('products/saveEANUPC', {release_id: this.releaseId, code: this.ceanupc}).subscribe(
                 (response: HttpEvent<any>) => {
                     switch (response.type) {
                         case HttpEventType.Response:
                             if (response.body != null && response.body.success === true) {
                                 this.ceanupc = '';
                                 this.getEANUPCList();
                             }
                             break;
                     }
                 },
                 error => {
                     console.log(error);
                 });
         }
     }

  }

  getEANUPCList(): any {

        this._productService.getEANUPCList(
            'products/getEANUPCList/' + this.releaseId,
        ).subscribe(
            ( response: HttpEvent<any> ) => {
                switch (response.type) {
                    case HttpEventType.Response:

                        if ( response.body != null && response.body.success === true ){
                            this.eanupclist = response.body.data;
                        }
                        break;
                }
            },
            error => {
                console.log('error get one: ' );
                console.log(error);
            });
  }

  deleteEANUPC(code: string): any {
    //console.log(code)
      this._productService.delete(
          'products/deleteEANUPC',
          {code: code}
      ).subscribe(
          ( response: HttpEvent<any> ) => {
              switch (response.type) {
                   case HttpEventType.Response:

                      if ( response.body != null && response.body.success === true ){
                        this.getEANUPCList();
                      }
                      break;
              }
          },
          error => {
              console.log('error deleteEANUPC: ' );
              console.log(error);
          });
  }

  drop(event: CdkDragDrop<object[]>): void {
    moveItemInArray(this.release.tracklist, event.previousIndex, event.currentIndex);
  }

  back(): void {
    this._location.back();
  }

  addTrack(): void {
    this.release.tracklist.push(this.trackChoserCtrl.value);
    this.trackChoser.trackCtrl.setValue('');
  }

  getOne(releaseId): any {

    this._productService.getOne(
        'products/getRelease/' + releaseId,
    ).subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){

                this.release = response.body.data.items;
                this._globalService.setFormData(this.release, Object.assign({}, this.release));

              }
              break;
          }
        },
        error => {
          console.log('error get one: ' );
          console.log(error);
        });
  }

  getOneForDuplication(releaseId): any {
      this._productService.getOne(
          'products/getRelease/' + releaseId,
      ).subscribe(
          ( response: HttpEvent<any> ) => {
              switch (response.type) {
                  case HttpEventType.Response:
                      if ( response.body != null && response.body.success === true ){

                          this.release = response.body.data.items;

                          this.release.ean = '';
                          this.release.upc = '';
                          this.release.release_id = '';

                          this._globalService.setFormData(this.release, Object.assign({}, this.release));

                      }
                      break;
              }
          },
          error => {
              console.log('error get one: ' );
              console.log(error);
          });
  }

  save(): void{

    if (this.releaseForm.form.valid) {

      let uri = 'products/saveRelease';
      if (this.releaseId !== 'new'){
        uri += '/' + this.releaseId;
      }

      this._productService.save(uri, this.release).subscribe(
          ( response: HttpEvent<any> ) => {

            switch (response.type) {

              case HttpEventType.Response:
                if ( response.body != null && response.body.success === true ){
                    this._globalService.setFormData(this.release, Object.assign({}, this.release));
                    this.back();
                }
                break;
            }

          },
          error => {
            console.log(error);
          });
    }

  }

  trackChosenCheck(): boolean {
    return !isObject(this.trackChoser.trackCtrl.value);
  }

}
