import {Component, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

/**
 * @title Basic snack-bar
 */
@Component({
    selector: 'app-snackbar',
    templateUrl: 'snackbar.component.html',
    //styleUrls: ['snack-bar-overview-example.css'],
})
@Injectable({
    providedIn: 'root'
})
export class SnackbarComponent {

    constructor(private _snackBar: MatSnackBar) {}

    openSnackBar(message: string, action: string): void {
        this._snackBar.open(message, action, {
            duration: 2000,
        });
    }
}