import { HttpEvent, HttpEventType} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatSort} from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from './i18n/de';
import {locale as english } from './i18n/en';
import {FuseSidebarService} from '../../../@fuse/components/sidebar/sidebar.service';
import {fuseAnimations} from '../../../@fuse/animations';
import {ClientsService} from '@/main/clients/clients.service';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {FuseConfirmDialogComponent} from '../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {GlobalService} from '@/shared/services/global.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  animations   : fuseAnimations
})

export class ClientsComponent implements AfterViewInit  {
  displayedColumns: string[] = ['checkbox', 'id', 'name', 'country', 'currency' ]; //logofile
  data: any[] = [];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  filterValue: string;
  filterInt: any;
  filterEvent: EventEmitter<String> = new EventEmitter(false);

  selectedClients: string[] = [];
  private isChecked = false;

  resultsLength = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor( private _fuseTranslationLoaderService: FuseTranslationLoaderService,
               private _fuseSidebarService: FuseSidebarService,
               private _clientsService: ClientsService,
               private _globalService: GlobalService,
               public dialog: MatDialog) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }


  ngAfterViewInit(): void {

      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      this.filterEvent.subscribe(() => this.paginator.pageIndex = 0);



      merge(this.sort.sortChange, this.paginator.page, this.filterEvent)
          .pipe(
              startWith({}),
              switchMap(() => {
                  return this._clientsService.loadClients(
                      'clients/get',
                      this.filterValue,
                      this.sort.direction,
                      this.sort.active,
                      this.paginator.pageIndex,
                      this.paginator.pageSize
                      );
              }),
              map(data => {
                  // Flip flag to show that loading has finished.
                  this.resultsLength = data.total_count;
                  return data.data.items;
              }),
              catchError(() => {
                  return observableOf([]);
              })
          ).subscribe(data => this.data = data);

  }


  onSelectedChange(id: any): void{

    if ( this.selectedClients.length > 0 ){

          const index = this.selectedClients.indexOf(id);

          if ( index !== -1 ){

              this.selectedClients.splice(index, 1);

              if ( this.selectedClients.length === 0 ){
                  this.isChecked = false;
              }
            return;
          }
    }

    this.selectedClients.push(id);
    this.isChecked = true;
  }


  askForDelete(): void{

    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete all selected items?';

    this.confirmDialogRef.afterClosed()
        .subscribe(result => {
          if ( result )
          {
            this.delete();
          }
          this.confirmDialogRef = null;
        });
  }

  delete(): void{

    const formData = new FormData();
    formData.append('ids', JSON.stringify(this.selectedClients));

    //console.log(JSON.stringify(this.selectedClients))

    this._clientsService.delete('clients/delete', formData).subscribe(
         ( response: HttpEvent<any> ) => {

           switch (response.type) {

             case HttpEventType.Response:
               if ( response.body != null && response.body.success === true ){

                   this.selectedClients.length = 0;
                   this.isChecked = false;
                   this.filterEvent.emit(this.filterValue);

               }
               break;
           }
         },
         error => {
           console.log(error);
         });

  }

  /*
  toggleSidebar(name): void
  {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }
   */

  applyFilter(filterValue: string):void  {
    clearInterval(this.filterInt);
    this.filterInt = setInterval(() => { this.callFilter(filterValue); }, 1000);
  }

  callFilter(filterValue: string):void {
    clearInterval(this.filterInt);
    this.filterValue = filterValue;
    this.filterEvent.emit(this.filterValue);
  }

  openHelp(): void {
    const dialogRef = this.dialog.open(HelpDialog, {
      //width: '500px',
      //height: '400px',
      //disableClose: true
      data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1'},
    });
  }
  
}

