import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {BusyIndicatorComponent} from '@/main/components/busyindicator/busy-indicator.component';

@Injectable({
    providedIn: 'root',
})
export class BusyIndicatorService  {

    /*
    constructor(){
    }
     */
    private _loading: boolean = false;
    loadingStatus: Subject<boolean> = new Subject();

    get loading(): boolean {
        return this._loading;
    }

    set loading(value) {
        this._loading = value;
        this.loadingStatus.next(value);
    }

    startLoading(): void {
        //console.log('startLoading');
        this.loading = true;
    }

    stopLoading(): void {
        //console.log('stopLoading');
        this.loading = false;
    }

}
