import {Component, Inject, OnInit} from '@angular/core';
import {GlobalService} from '@/shared/services/global.service';
import {FileManagerService} from '@/main/apps/file-manager/file-manager.service';
import {DialogData} from '@/main/apps/file-manager/uploaddialog/upload-dialog.component';
import {FuseTranslationLoaderService} from '../../../../@fuse/services/translation-loader.service';
import {LicensorsService} from '@/main/licensors/licensors.service';
import {ProductsService} from '@/main/products/products.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {HttpEvent, HttpEventType} from '@angular/common/http';

@Component({
  selector: 'app-release-import-modal',
  templateUrl: './release-import-modal.component.html',
  styleUrls: ['./release-import-modal.component.scss']
})
export class ReleaseImportModalComponent implements OnInit {

    constructor(public _globalService: GlobalService, public _fileManagerService: FileManagerService,
                public dialogRef: MatDialogRef<ReleaseImportModalComponent>,
                private _fuseTranslationLoaderService: FuseTranslationLoaderService,
                private _licensorsService: LicensorsService, private _productsService: ProductsService) {
    }

    ngOnInit(): void {

    }

    startSync(): void {


        this._productsService.MCMSSyncReleases(this._globalService.userinfo).subscribe(
            (response: HttpEvent<any>) => {
                switch (response.type) {

                    case HttpEventType.Response:
                        if ( response.body != null && response.body.success === true ){

                        }else{

                        }
                        break;
                }
            },
            error => {
                console.log(error);
            });

    }


    close(): void {
        this.dialogRef.close();
    }
}
