import { HttpEvent, HttpEventType} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatSort} from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {fuseAnimations} from '@fuse/animations';
import {UsersService} from '@/main/users/users.service';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {ExpensetypesService} from '@/main/expensetypes/expensetypes.service';


@Component({
  selector: 'app-expensetype-list',
  templateUrl: './expensetype-list.component.html',
  styleUrls: ['./expensetype-list.component.scss'],
  animations   : fuseAnimations
})

export class ExpensetypeListComponent implements AfterViewInit  {

  @Input() clientId: any;
  isChecked = false;

  displayedColumns: string[] = ['checkbox', 'key', 'cost_name_de', 'cost_name_en'];
  data: any[] = [];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  filterValue: string;
  filterInt: any;
  filterEvent: EventEmitter<String> = new EventEmitter(false);
  selectedCosttypes: string[] = [];

  resultsLength = 0;

  //@ViewChild(MatPaginator) paginator: MatPaginator;
  //@ViewChild(MatSort) sort: MatSort;

  constructor( private _fuseTranslationLoaderService: FuseTranslationLoaderService,
               private _expensetypesService: ExpensetypesService,
               public dialog: MatDialog) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }


  ngAfterViewInit(): void {

      //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      //this.filterEvent.subscribe(() => this.paginator.pageIndex = 0);

      merge( this.filterEvent) //this.sort.sortChange, this.paginator.page,
          .pipe(
              startWith({}),
              switchMap(() => {
                        return this._expensetypesService.getCostTypes('accounting/costTypes');
              }),
              map(data => {
                  // Flip flag to show that loading has finished.
                  //this.resultsLength = data.total_count;
                  // @ts-ignore
                  return data.data.items;
              }),
              catchError(() => {
                  return observableOf([]);
              })
          ).subscribe(data => this.data = data);

  }


  onSelectedChange(id: any): void{

    if ( this.selectedCosttypes.length > 0 ){

          const index = this.selectedCosttypes.indexOf(id);

          if ( index !== -1 ){

              this.selectedCosttypes.splice(index, 1);

              if ( this.selectedCosttypes.length === 0 ){
                  this.isChecked = false;
              }
            return;
          }
    }

    this.selectedCosttypes.push(id);
    this.isChecked = true;
  }


  askForDelete(): void{

    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete all selected items?';

    this.confirmDialogRef.afterClosed()
        .subscribe(result => {
          if ( result )
          {
            this.delete();
          }
          this.confirmDialogRef = null;
        });
  }

  delete(): void{

    const formData = new FormData();
    formData.append('ids', JSON.stringify(this.selectedCosttypes));

    //console.log(JSON.stringify(this.selectedUsers))

    this._expensetypesService.delete('accounting/deleteCostType', formData).subscribe(
         ( response: HttpEvent<any> ) => {
           switch (response.type) {

             case HttpEventType.Response:
               if ( response.body != null && response.body.success === true ){
                   this.selectedCosttypes.length = 0;
                   this.isChecked = false;
                   this.filterEvent.emit(this.filterValue);
               }
               break;
           }
         },
         error => {
           console.log(error);
         });

  }

  applyFilter(filterValue: string): void  {
    clearInterval(this.filterInt);
    this.filterInt = setInterval(() => { this.callFilter(filterValue); }, 1000);
  }

  callFilter(filterValue: string): void {
    clearInterval(this.filterInt);
    this.filterValue = filterValue;
    this.filterEvent.emit(this.filterValue);
  }

  openHelp(): void {
    const dialogRef = this.dialog.open(HelpDialog, {
      //width: '500px',
      //height: '400px',
      //disableClose: true
      data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1',},
    });
  }
  
}

