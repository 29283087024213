import { Component, OnInit } from '@angular/core';
import { FileService } from '../../shared/services/file.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  constructor(private fileService: FileService) { }

  ngOnInit() {
  }

  uploadFile(event){
    let elem = event.target;
    if(elem.files.length > 0){
      let formData = new FormData();
      formData.append('myfile', elem.files[0]);

      this.fileService.sendFile(formData).subscribe(
          (response) => {
            //response code
            console.log(response);
          });

    }

    elem.value = "";
  }



}
