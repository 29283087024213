import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
    helpText: string;
    helpHeadline: string;
    helpTextList1: string;
    helpTextList2: string;
    helpTextList3: string;
    helpTextList4: string;
    helpTextList5: string;
}

@Component({
    selector: 'help-dialog',
    templateUrl: 'help-dialog.component.html',
})

export class HelpDialog{

    constructor(public dialogRef: MatDialogRef<HelpDialog>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

}
