import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule, MatIconModule, MatRippleModule, MatSlideToggleModule, MatTableModule, MatFormFieldModule, MatPaginatorModule, MatInputModule, MatSidenavModule, MatSelectModule, MatListModule, MatTabsModule, MatMenuModule} from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import {FuseSidebarModule, FuseWidgetModule} from '@fuse/components';
import {AuthGuardService as AuthGuard } from '@/auth/auth-guard.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {DashboardComponent} from '@/main/dashboard/dashboard.component';
import {MatCard, MatCardModule} from '@angular/material/card';

const routes = [
    {
        path     : 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        DashboardComponent
    ],
    imports     : [
        FuseWidgetModule,
        RouterModule.forChild(routes),
        NgxChartsModule,
        TranslateModule,
        FuseSidebarModule,
        FuseSharedModule,
        MatButtonModule, MatIconModule, MatRippleModule, MatSlideToggleModule, MatTableModule, MatFormFieldModule, MatPaginatorModule, MatInputModule, MatSidenavModule, MatSelectModule, MatListModule,MatTabsModule,MatMenuModule,MatCardModule
    ],
    exports     : [
        DashboardComponent
    ]
})

export class DashboardModule
{
}
