import {Injectable, Pipe, PipeTransform} from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {FuseNavigationService} from '../../../@fuse/components/navigation/navigation.service';
import {FuseConfigService} from '../../../@fuse/services/config.service';
import { navigationAdmin} from '@/navigation/navigation-admin';
import { navigationLicensor} from '@/navigation/navigation-licensor';
import {takeUntil} from 'rxjs/operators';
import {interval, Observable, Subject} from 'rxjs';
import {FuseConfirmDialogComponent} from '../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material';
import {forEach} from '@angular/router/src/utils/collection';





@Injectable()
export class GlobalService {

    httpHeaders: HttpHeaders;
    currencies: any;
    currenciesLoaded: boolean = false;

    navigation_admin: any;

    navigation_licensor: any;

    countries: any;
    countriesLoaded: boolean = false;

    clients: any;
    clientsLoaded: boolean = false;

    licensors: any;
    licensorsLoaded: boolean = false;

    licensorTypes: any;
    licensorTypesLoaded: boolean = false;

    userinfo: any;
    userInfoLoaded: boolean = false;

    artistRoles: any;
    artistRolesLoaded: boolean = false;

    contributorRoles: any;
    contributorRolesLoaded: boolean = false;

    releaseTypes: any;
    releaseTypeLoaded: boolean = false;

    albumTypes: any[] = [
        {name: 'Album', key: 'Album'},
        {name: 'Single', key: 'Single'},
        {name: 'Compilation', key: 'Compilation'},
    ];

    productTypes: any;
    productTypesLoaded: boolean = false;

    productTypesMerch: any;

    costTypes: any;
    costTypesLoaded: boolean = false;

    saleTypes: any;
    saleTypesLoaded: boolean = false;

    saleTypesSoundRecording: any;
    saleTypesSoundRecordingLoaded: boolean = false;

    royaltyBases: any;
    royaltyBasesLoaded: boolean = false;

    royaltyBasesMerch: any;
    royaltyBasesMerchLoaded: boolean = false;

    importInterfaces: any;
    importInterfacesLoaded: boolean = false;

    allDataLoaded: Promise<boolean>;

    notifications: any = [];

    mySub: any;

    checkObj1: any;
    checkObj2: any;
    audio: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    constructor(private httpClient: HttpClient,
                private _auth: OAuthService,
                private _fuseNavigationService: FuseNavigationService,
                private _fuseConfigService: FuseConfigService,
                public dialog: MatDialog) {
        this.navigation_admin = navigationAdmin;
        this.navigation_licensor = navigationLicensor;

        this.audio = new Audio('assets/sound/notification.mp3');
        this.audio.load();

    }

    Init(): any {
        this.httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + this._auth.getAccessToken());

        this.userinfo = this._auth.getIdentityClaims();

        if (this._auth.hasValidAccessToken()) {
            //FIX BECAUSE SERVICE LOADED EVEN IF NOT LOGGED IN
            this.setNavigation();
            this.loadCountries();
            this.loadCurrencies();
            this.loadClients();
            this.loadLicensors();
            this.loadLicensorTypes();
            this.loadArtistRoles();
            this.loadContributorRoles();
            this.loadReleaseTypes();
            this.loadProductTypes();
            this.loadCostTypes();
            this.loadSaleTypes();
            this.loadSaleTypesSoundRecording();
            this.loadRoyaltyBases();
            this.loadRoyaltyBasesMerch();
            this.loadFileImportInterfaces();
            this.checkNotifications();
            this.mySub = interval(20000).subscribe(func => {
                this.checkNotifications();
            });

        } else {
            this.userinfo = this._auth.getIdentityClaims();
            this.allDataLoaded = Promise.resolve(true);
        }


    }

    setNavigation(): void {
        if (this.isAdmin()) {
            this._fuseNavigationService.addNavigationItem(this.navigation_admin, 'end');
        }

        if (this.isLicensor()) {
            this._fuseNavigationService.register('licensor', this.navigation_licensor);
            this._fuseNavigationService.setCurrentNavigation('licensor');
        }

    }

    checkAllLoaded(): void {

        if (
            this.currenciesLoaded &&
            this.countriesLoaded &&
            this.clientsLoaded &&
            this.licensorsLoaded &&
            this.licensorTypesLoaded &&
            this.artistRolesLoaded &&
            this.contributorRolesLoaded &&
            this.releaseTypeLoaded &&
            this.productTypesLoaded &&
            this.costTypesLoaded &&
            this.saleTypesLoaded &&
            this.saleTypesSoundRecordingLoaded &&
            this.royaltyBasesLoaded &&
            this.royaltyBasesMerchLoaded &&
            this.importInterfacesLoaded
        ) {
            this.allDataLoaded = Promise.resolve(true);
        }

    }

    checkNotifications(): any {
        this.httpClient.get('/api/notifications',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
            ( response: HttpEvent<any> ) => {

                switch (response.type) {

                    case HttpEventType.Response:
                        if ( response.body != null ){
                            if (this.notifications.length < response.body.data.length) {
                                this.audio.play();
                            }
                            this.notifications = response.body.data;
                        }
                        break;
                }
            },
            error => console.log(error)
        );
    }

    markNotificationasRead(id): void {
        this.httpClient.get('/api/notifications/markAsRead/' + id,  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
            ( response: HttpEvent<any> ) => {

                switch (response.type) {

                    case HttpEventType.Response:
                        if ( response.body != null ){
                            this.checkNotifications();
                        }
                        break;
                }
            },
            error => console.log(error)
        );
    }

    loadCountries(reload = false): any {

        if (!this.countries || reload) {
            this.httpClient.get('/api/iso/countries',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this.countries = response.body.data.items;
                                this.countriesLoaded = true;
                                this.checkAllLoaded();
                                // console.log(this.countries);
                            }
                            break;
                    }
                },
                error => console.log(error)
            );
        }
    }

    getCountries(reload = false): any {
        if (!this.countries || reload) {
            this.loadCountries();
        }
        return this.countries;
    }

    requestPasswordReset(formData): any {

        return this.httpClient.post('/api/password/create', formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );

    }

    ResetPassword(formData): any {
        return this.httpClient.post('/api/password/reset', formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    checkResetToken(token): any {
        return this.httpClient.get('/api/password/find/' + token, {reportProgress: false, observe: 'events', headers : this.httpHeaders });
    }

    loadCurrencies(reload = false): any {

        if (!this.countries || reload) {
            this.httpClient.get('/api/iso/currencies',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this.currencies = response.body.data.items;
                                this.currenciesLoaded = true;
                                this.checkAllLoaded();
                            }
                            break;
                    }
                },
                error => console.log(error)
            );
        }
    }

    loadClients(reload = false): any {

        if (!this.clients || reload) {
            this.httpClient.get('/api/clients/loadClients',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this.clients = response.body.data.items;
                                this.clientsLoaded = true;
                                this.checkAllLoaded();

                            }
                            break;
                    }
                },
                error => console.log(error)
            );
        }
    }

    isAdmin(): boolean{

        if (this._auth.hasValidAccessToken()) {
            if (this.userinfo.clientId === 1){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }

    isLicensor(): boolean{
        if (this._auth.hasValidAccessToken()) {
            if (this.userinfo.licensor_id != null) {
                return true;
            } else {
                return false;
            }
        }else{
            return false;
        }
    }

    hasApiKey(): boolean {

        if (this._auth.hasValidAccessToken() && (this.userinfo.client_data.mcms_api_key !== '' && this.userinfo.client_data.mcms_api_key !== null && this.userinfo.client_data.mcms_api_key !== undefined)) {
            return true;
        } else {
            return false;
        }

    }

    getUserClientId(): number{
        return this.userinfo['clientId'];
    }

    getUserClientCurrency(): string {
        return this.userinfo['client_data']['currency'];
    }


    loadLicensors(reload = false): any {
        if (!this.licensors || reload) {
            this.httpClient.get('/api/licensors/loadLicensors',  { reportProgress: false, observe: 'events', headers : this.httpHeaders} ).subscribe(
                ( response: HttpEvent<any> ) => {
                    switch (response.type) {
                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                //console.log(response.body.data);
                                this.licensors = response.body.data.items;
                                this.licensorsLoaded = true;
                                this.checkAllLoaded();
                            }
                            break;
                    }
                },
                error => console.log(error)
            );
        }
    }


    getLicensorNameById(id: number): void{
        if (this.licensors) {
            for (let i = 0; i < this.licensors.length; i++){
                if (this.licensors[i].licensor_id === id){
                    return this.licensors[i].name;
                }
            }
        }
    }

    loadLicensorTypes(reload = false): any {

        if (!this.licensorTypes || reload) {
            this.httpClient.get('/api/licensors/loadLicensorsTypes',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                //console.log(response.body.data);
                                this.licensorTypes = response.body.data.items;
                                this.licensorTypesLoaded = true;
                                this.checkAllLoaded();
                            }
                            break;
                    }
                },
                error => console.log(error)
            );
        }
    }

    loadArtistRoles(reload = false): any {
        if (!this.artistRoles || reload) {
            this.httpClient.get('/api/products/loadArtistRoles',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                //console.log(response.body.data);
                                this.artistRoles = response.body.data.items;
                                this.artistRolesLoaded = true;
                                this.checkAllLoaded();
                            }
                            break;
                    }
                },
                error => console.log(error)
            );
        }
    }

    loadContributorRoles(reload = false): any {
        if (!this.contributorRoles || reload) {
            this.httpClient.get('/api/products/loadContributorRoles',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                //console.log(response.body.data);
                                this.contributorRoles = response.body.data.items;
                                this.contributorRolesLoaded = true;
                                this.checkAllLoaded();
                            }
                            break;
                    }
                },
                error => console.log(error)
            );
        }
    }

    loadReleaseTypes(reload = false): any {
        if (!this.releaseTypes || reload) {
            this.httpClient.get('/api/products/releaseTypes',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this.releaseTypes = response.body.data.items;
                                this.releaseTypeLoaded = true;
                                this.checkAllLoaded();

                            }
                            break;
                    }
                },
                error => console.log(error)
            );
        }
    }

    loadProductTypes(reload = false): any {
        if (!this.productTypes || reload) {
            this.httpClient.get('/api/products/productTypes',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this.productTypes = response.body.data.items;
                                this.productTypesLoaded = true;
                                this.productTypesMerch = [];
                                this.httpClient.get('/api/products/productTypesMerch',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                                    ( response: HttpEvent<any> ) => {

                                        switch (response.type) {

                                            case HttpEventType.Response:
                                                if ( response.body != null && response.body.success === true ){
                                                    this.productTypesMerch = response.body.data.items;
                                                    this.productTypesLoaded = true;

                                                    this.checkAllLoaded();

                                                }
                                                break;
                                        }
                                    },
                                    error => console.log(error)
                                );
                                this.checkAllLoaded();

                            }
                            break;
                    }
                },
                error => console.log(error)
            );

        }
    }

    loadCostTypes(reload = false): any {
        if (!this.costTypes || reload) {
            this.httpClient.get('/api/accounting/costTypes',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this.costTypes = response.body.data.items;
                                this.costTypesLoaded = true;
                                this.checkAllLoaded();

                            }
                            break;
                    }
                },
                error => console.log(error)
            );
        }
    }

    loadSaleTypes(reload = false): any {
        if (!this.saleTypes || reload) {
            this.httpClient.get('/api/accounting/saleTypes',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this.saleTypes = response.body.data.items;
                                this.saleTypesLoaded = true;
                                this.checkAllLoaded();

                            }
                            break;
                    }
                },
                error => console.log(error)
            );
        }
    }

    loadSaleTypesSoundRecording(reload = false): any {
        if (!this.saleTypes || reload) {
            this.httpClient.get('/api/accounting/saleTypesSoundRecording',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this.saleTypesSoundRecording = response.body.data.items;
                                this.saleTypesSoundRecordingLoaded = true;
                                this.checkAllLoaded();

                            }
                            break;
                    }
                },
                error => console.log(error)
            );
        }
    }

    loadRoyaltyBases(reload = false): any {
        if (!this.royaltyBases || reload) {
            this.httpClient.get('/api/accounting/royaltyBases',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this.royaltyBases = response.body.data.items;
                                this.royaltyBasesLoaded = true;
                                this.checkAllLoaded();

                            }
                            break;
                    }
                },
                error => console.log(error)
            );
        }
    }

    loadRoyaltyBasesMerch(reload = false): any {
        if (!this.royaltyBasesMerch || reload) {
            this.httpClient.get('/api/accounting/royaltyBasesMerch',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this.royaltyBasesMerch = response.body.data.items;
                                this.royaltyBasesMerchLoaded = true;
                                this.checkAllLoaded();

                            }
                            break;
                    }
                },
                error => console.log(error)
            );
        }
    }

    setFormData(obj1: any, obj2: any ): void{
        this.checkObj1 = obj1;
        this.checkObj2 = obj2;
    }

    checkFormDataChanges(): boolean{

        if ( JSON.stringify( this.checkObj1).toLowerCase() === JSON.stringify(this.checkObj2).toLowerCase()){
            return true;
        }else{
            return false;
        }
    }

    confirmDialog(message): any {
        const confirmation = window.confirm(message || 'Are you sure?');
        return confirmation;
    }

    canDeactivate(): any {
        let value = true;
        if (!this.checkFormDataChanges()){
            if (this.confirmDialog('You have unsaved changes! If you confirm this data will be lost!')) {
                value = true;
            } else {
                value = false;
            }
        }

        return value;
    }

    loadFileImportInterfaces(reload = false): any {
        if (!this.importInterfaces || reload) {
            this.httpClient.get('/api/import/loadImportInterfaces',  {reportProgress: false, observe: 'events', headers : this.httpHeaders } ).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this.importInterfaces = response.body.items;
                                this.importInterfacesLoaded = true;
                                this.checkAllLoaded();

                            }
                            break;
                    }
                },
                error => console.log(error)
            );
        }
    }

    confirmModal(): any {
        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure?';

        return this.confirmDialogRef.afterClosed();

    }

    isInList(id: any, list: any): boolean {
        const index = list.indexOf(id);
        if ( index !== -1 ){
            return true;
        } else {
            return false;
        }
    }
}


