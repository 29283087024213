export const locale = {
    lang: 'en',
    data: {
        'USERS': {
            'OVERVIEW' : {
                'HEADLINE' : 'Users',
                'TABLE' : {
                    'ID'          : 'ID',
                    'FIRSTNAME'   : 'Firstname',
                    'LASTNAME'    : 'Lastname',
                    'EMAIL'       : 'E-Mail',
                },
            },
            'TEXTBLOCKS' : {
                'HEADLINE' : 'Textblocks',
                'FORM' : {
                    'ADD'       : 'Textblock add / edit',
                    'PUBLISHSTATEMENT_EMAILTEXT' : 'Pulish statement E-Mail Text',
                    'PUBLISHSTATEMENT_ERROR': 'Pulish statement E-Mail Text Error',
                },
            },
            'EDIT' : {
                'VIEW' : {
                    'HEADLINE' : 'User',


                    'SIDEBAR' : {
                        'BUTTON' : {
                            'DELETE' : 'Delete client',
                            'ADDUSER'   : 'Add user',
                        },
                    },
                },
            },

            'ADD' : {
                'MODAL' : {
                    'HEADLINE'              : 'User add / edit',
                    'LASTNAME'              : 'Lastname',
                    'LASTNAMEREQMESSAGE'    : 'Lastname required!',
                    'FIRSTNAME'             : 'Firstname',
                    'FIRSTNAMEREQMESSAGE'   : 'Firstname required!',
                    'EMAIL'                 : 'E-Mail',
                    'EMAILREQMESSAGE'       : 'E-Mail required!',
                    'PASSWORD'              : 'Password',
                    'PASSWORD2'              : 'repeat password',
                    'PASSWORDREQMESSAGE'    : 'Password required!',
                    'PASSWORDREQMESSAGE2'    : 'repeat password required!',
                    'CLIENTID'              : 'Client ID',
                    'CLIENTIDREQMESSAGE'    : 'Client ID required!',
                    'LANGUAGE'              : 'Language',
                    'LANGUAGEREQMESSAGE'    : 'Language required!',
                },
            },


            'SIDEBAR'   : {
                'BUTTON'    : {
                    'ADD'       : 'Add client',
                },
            },
        },

        'HELPTEXT' : {

            'OVERVIEW' : {
                'HEADLINE' : 'Useroverview',
                'TEXT' : 'Here you will find an overview of all users.',
                'LIST1' : 'With the + you can create new users.',
            },

            'DETAIL' : {
                'HEADLINE' : 'Userdetail',
                'TEXT' : 'Here you see the details of the choosen user.',
                'LIST1' : 'After you finish editing the user, make shure to save.',
            },


            'EDIT' : {
                'HEADLINE' : 'Edit clients',
                'TEXT' : 'Here, the client can be viewed and edited in detail with its users.',
                'LIST1' : 'The fields marked with * are mandatory and must be completed.',
                'LIST2' : 'The currency must be set correctly, this is the currency in which the client settles, not how it is paid.',
                'LIST3' : 'VAT ID is a mandatory field and is assumed as each company must have a tax ID.',
                'LIST4' : 'The users belonging to the client can be created and edited here.',
                'LIST5' : 'Only users can be created for this client.',
            },

        },
    }
};
