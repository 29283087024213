export const locale = {
    lang: 'de',
    data: {
        'USERS' : {
            'ADD' : {
                'MODAL' : {
                    'CLIENTID' : 'Mandant',
                },
            },
        },

        'PRODUCTS': {
            'RELEASESYNC' : {
                'HEADLINE' : 'Produkte aus dem MCMS importieren',
                'INFOTEXT' : 'Drücken Sie auf den Button um den Importprozess auf dem Server zu starten. Nach dem der Prozess erledigt ist, werden Sie per eMail informiert.'
            },
            'IMPORT': {
                'HEADLINE': 'Merchandise Produkte importieren',
                'IMPORTERRORSTEXT' : 'Import war nicht erfolgreich! Bitte die Importdaten korrigieren und erneut versuchen!',
                'TEXT' : 'Lade das ausgefüllte Excel sheet hier hoch und klicke danach auf die Wolke zum importieren',
                'DOWNLOADTEMPLATETEXT' : 'Download import template XLS',
                'FAILURES' : 'Fehler',
                'UPLOAD' : 'Upload'
            },
            'RELEASES' : {
                'TABLE': {
                    'ID' : '#',
                    'CATALOG_NUMBER' : 'Katalog Nr.',
                    'EAN' : 'EAN',
                    'UPC' : 'UPC',
                    'ARTIST' : 'Künstler',
                    'TITLE'  : 'Titel',
                    'LABEL'     : 'Label',
                    'RELEASE_DATE' : 'VÖ Datum',
                    'RELEASE_TYPE' : 'VÖ Typ',
                    'RELEASE_GROUP' : 'Release Group',
                    'TOTAL_SALES' : 'Bisher verkauft',
                    'RATA' : 'Rata',
                    'ACTIVE_ON_CONTRACT': 'Wird auf diesem Vertrag abgerechnet',
                    'SET_TO_NOT_ACTIVE' : 'Ändern zu "wird NICHT abgerechnet"',
                    'SET_TO_ACTIVE' : 'Ändern zu "wird abgerechnet"'
                },
            },

            'OVERVIEW' : {
                'HEADLINE' : 'Produktübersicht',
                'AUDIO' : 'Audio',
                'MERCH' : 'Merchandise',
                'BUNDLES' : 'Merch / Audio Bundles',
                'TABLE': {
                    'ID' : '#',
                    'NAME' : 'Name',
                    'IDENTIFIER' : 'Identifier',
                    'PRODUCT_TYPE' : 'Produkt Typ',
                    'ITEM_NUMBER' : 'Artikelnummer',
                    'REVENUEPERCENTAGE' : 'Anteil am Umsatz %',
                    'PPD' : 'HAP'
                },
            },

        },

        'PRODUCT' : {
            'ADD' : {
                'NAME' : 'Name',
                'IDENTIFIER' : 'Identifier',
                'ITEMNUMBER' : 'Artikelnummer',
                'PRODUCTTYPE' : 'Produkt Typ',
                'HEADLINE'  : 'Merch - Produkt',
                'PPD' : 'HAP'
            },
            'RELEASE' : {
                'ADD' : {
                    'RELEASETYPE' : 'Release Typ',
                    'ALBUMTYPE' : 'Album Typ',
                    'ALBUMTYPEMESSAGE' : 'Album Typ benötigt!',
                    'sold_units_start_amount' : 'Bisher verkauft vor Start Nutzung Bantha!',
                    'PPD' : 'PPD',
                    'CATALOG_NUMBER' : 'Katalog Nummer',
                    'EAN' : 'EAN',
                    'UPC' : 'UPC',
                    'ARTIST' : 'Künstler',
                    'TITLE' : 'Titel',
                    'SUBTITLE' : 'Untertitel',
                    'LABEL' : 'Label',
                    'ARTISTS' : 'Beteiligte Künstler',
                    'RELEASEDATE' : 'Datum: Veröffentlichung',
                    'PRESALEDATE' : 'Datum: Vorverkaufsstart',
                    'MEDIAVOLUME' : 'Media Volumen',
                    'PROMOTIONPERIODSTART' : 'Promotion Start',
                    'PROMOTIONPERIODEND'   : 'Promotion Ende',
                    'EAN_UPC' : 'EAN / UPC',
                    'RELEASE_GROUP_IDENTIFIER' : 'Release Group Identifier'
                },
                'DETAIL' : {
                    'PRODUCTINFO' : 'Produktinformationen',
                    'TRACKLIST' : 'Titelliste',
                    'PROMOTIONSETTINGS' : 'Promotionzeitraum / Media Volumen',
                    'EAN_UPC' : 'Zusätzliche EAN / UPC',
                },

            },
        },

        'HELPTEXT' : {



        },
    }
};
