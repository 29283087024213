export const locale = {
    lang: 'en',
    data: {
        'PROJECT': {
            'OVERVIEW' : {
                'HEADLINE' : 'Project - Overview',
            },
            'SIDEBAR'   : {
                'BUTTON'    : {
                    'ADD'       : 'add project',
                },
            },
        },
    }
};
