import { HttpEvent, HttpEventType} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit, EventEmitter, Input} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatSort} from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {fuseAnimations} from '@fuse/animations';
import {ProductsService} from '@/main/products/products.service';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {GlobalService} from '@/shared/services/global.service';
import {ContractsService} from '@/main/contracts/contracts.service';

@Component({
  selector: 'app-release-list',
  templateUrl: './release-list.component.html',
  styleUrls: ['./release-list.component.scss'],
  animations   : fuseAnimations
})

export class ReleaseListComponent implements AfterViewInit  {

  @Input() clientId: any;
  @Input() periodId: any;
  @Input() productType: any;
  @Input() isModal = false;
  @Input() contractId: any = 0;

  displayedColumns: string[] = ['checkbox', 'release_id', 'catalog_number', 'release_group', 'ean', 'artist', 'label_name', 'release_date', 'release_type', 'total_sales' ];
  data: any[] = [];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  filterValue: string;
  filterInt: any;
  filterEvent: EventEmitter<String> = new EventEmitter(false);

  selectedProducts: string[] = [];
  isChecked = false;

  resultsLength = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor( private _fuseTranslationLoaderService: FuseTranslationLoaderService,
               private _fuseSidebarService: FuseSidebarService,
               private _productsService: ProductsService,
               private _globalService: GlobalService,
               private _contractsService: ContractsService,
               public dialog: MatDialog) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }


  ngAfterViewInit(): void {

    if (this.contractId != 0) {
        this.displayedColumns = ['release_id', 'catalog_number', 'release_group', 'ean', 'artist', 'label_name', 'release_date', 'release_type', 'total_sales', 'rata', 'active_on_contract'];
    }


    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.filterEvent.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, this.filterEvent)
        .pipe(
            startWith({}),
            switchMap(() => {
              console.log(this.sort);
              return this._productsService.loadReleases(
                  'products/getReleases',
                  this._productsService._releasefilterValue,
                  this.sort.direction,
                  this.sort.active,
                  this.paginator.pageIndex,
                  this.paginator.pageSize,
                  '',
                  this.periodId,
                  this.contractId
              );
            }),
            map(data => {
              // Flip flag to show that loading has finished.
              this.resultsLength = data.total_count;
              return data.data.items;
            }),
            catchError(() => {
              return observableOf([]);
            })
        ).subscribe(data => this.data = data);

  }


  onSelectedChange(id: any): void{

    if ( this.selectedProducts.length > 0 ){

      const index = this.selectedProducts.indexOf(id);

      if ( index !== -1 ){

        this.selectedProducts.splice(index, 1);

        if ( this.selectedProducts.length === 0 ){
          this.isChecked = false;
        }
        return;
      }
    }

    this.selectedProducts.push(id);
    this.isChecked = true;
  }

    refreshList(): void {
        this.filterEvent.emit(this.filterValue);
    }


    askForDelete(type = null): void{

    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete all selected items?';

    this.confirmDialogRef.afterClosed()
        .subscribe(result => {
          if ( result )
          {

            if (type != null) {
              this.deletePromotionPeriodRelease();
            } else {
              this.delete();
            }

          }
          this.confirmDialogRef = null;
        });
  }

  deletePromotionPeriodRelease(): void {
    const formData = new FormData();
    formData.append('ids', JSON.stringify(this.selectedProducts ));
    formData.append('periodId', this.periodId);



    this._productsService.delete('promotionperiods/deletepr', formData).subscribe(
        ( response: HttpEvent<any> ) => {

          switch (response.type) {

            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){

                this.selectedProducts.length = 0;
                this.isChecked = false;
                this.filterEvent.emit(this.filterValue);

              }
              break;
          }
        },
        error => {
          console.log(error);
        });
  }

  delete(): void{
    const formData = new FormData();
    formData.append('ids', JSON.stringify(this.selectedProducts ));


    this._productsService.delete('products/deleteRelease', formData).subscribe(
        ( response: HttpEvent<any> ) => {

          switch (response.type) {

            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){

                this.selectedProducts.length = 0;
                this.isChecked = false;
                this.filterEvent.emit(this.filterValue);

              }
              break;
          }
        },
        error => {
          console.log(error);
        });

  }

  /*
  toggleSidebar(name): void
  {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }
   */

  applyFilter(filterValue: string): void  {
    clearInterval(this.filterInt);
    this.filterInt = setInterval(() => { this.callFilter(filterValue); }, 1000);
  }

  callFilter(filterValue: string): void {
    clearInterval(this.filterInt);
    this._productsService._releasefilterValue = filterValue;
    this.filterEvent.emit(this._productsService._releasefilterValue);
  }

  openHelp(): void {
    const dialogRef = this.dialog.open(HelpDialog, {
      data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1'},
    });
  }

  duplicateRelease(): void {



  }

  changeActiveOnContract(releaseId, contractId, row): void {

      const formData = new FormData();
      formData.append('contractId', contractId);
      formData.append('releaseId', releaseId);

      this._contractsService.save('contracts/changeReleaseOnContractActive', formData).subscribe(
          ( response: HttpEvent<any> ) => {

              switch (response.type) {

                  case HttpEventType.Response:
                      if ( response.body != null && response.body.success === true ){

                          if (row.active_on_contract === 0) {
                              row.active_on_contract = 1;
                          } else {
                              row.active_on_contract = 0;
                          }

                      }
                      break;
              }
          },
          error => {
              console.log(error);
          });


  }

}