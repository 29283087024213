import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import * as fileSaver from 'file-saver';

@Injectable()
export class InterfaceCreatorService  {

    httpHeaders: HttpHeaders;

    constructor(
        private _httpClient: HttpClient,
        private _auth: OAuthService
    ){
         this.httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + this._auth.getAccessToken());
    }

    icReadFile(formData: any): any{
        return this._httpClient.post('/api/import/interfaceCreator/readFile', formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }


    /*
    loadLicensors(uri: string,  filter = '', direction = 'asc', sort = 'name', page = 0, pageSize = 10, licensorId = 0, royaltyPeriodId = 0):  Observable<any> {

        return this._httpClient.get('/api/' + uri, {
            params: new HttpParams()
                .set('filter', filter)
                .set('sort', sort)
                .set('direction', direction)
                .set('page', page.toString())
                .set('licensorId', licensorId.toString())
                .set('royaltyPeriodId', royaltyPeriodId.toString())
                .set('pageSize', pageSize.toString()), headers : this.httpHeaders
        }).pipe(
            map(res => res )
        );
    }

    loadAdvances(uri: string,  filter = '', direction = 'asc', sort = 'name', page = 0, pageSize = 10, licensorId = 0):  Observable<any> {

        return this._httpClient.get('/api/' + uri, {
            params: new HttpParams()
                .set('filter', filter)
                .set('sort', sort)
                .set('direction', direction)
                .set('page', page.toString())
                .set('licensorId', licensorId.toString())
                .set('pageSize', pageSize.toString()), headers : this.httpHeaders
        }).pipe(
            map(res => res )
        );
    }

    getOne(uri: string): any{
        return this._httpClient.get('/api/' + uri,  {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    getSome(ids: any, currencies = false): any{
        return this._httpClient.get('/api/licensors/getSome',  {params: new HttpParams().set('licensors', JSON.stringify(ids)).set('currencies', currencies.toString()), reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }


    getAccountBalance(licensorId): any {
        return this._httpClient.get('/api/accounting/getAccountBalance/' + licensorId,  {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    save(uri: string, formData: any): any{
        return this._httpClient.post('/api/' + uri, formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    saveAdvance(uri: string, formData: any): any{
        return this._httpClient.post('/api/' + uri, formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    delete(uri: string, formData: any): any{
        return this._httpClient.post('/api/' + uri, formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    downloadStatementCoverPdf(statementId): any {
        return this._httpClient.post('/api/accounting/statementPdf/' + statementId, null , {responseType: 'blob', reportProgress: false, observe: 'response', headers : this.httpHeaders } );
    }

    downloadStatementDetailPdf(statementId): any {
        return this._httpClient.post('/api/accounting/statementDetailPdf/' + statementId, null , {responseType: 'blob', reportProgress: false, observe: 'response', headers : this.httpHeaders } );
    }

    getStatements(licensorId): any {
        return this._httpClient.get('/api/accounting/getStatements/' + licensorId,  {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    getStatementCoverPdf(statementId): void {
        this.downloadStatementCoverPdf(statementId).subscribe(
            ( response: HttpResponse<any> ) => {
                let fileName = 'file';
                const contentDisposition = response.headers.get('Content-Disposition');
                if (contentDisposition) {
                    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = fileNameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].replace(/['"]/g, '');
                    }
                }

                switch (response.type) {

                    case HttpEventType.Response:
                        fileSaver.saveAs(response.body,  fileName);

                }

            },
            error => {
                console.log(error);
            });
    }

    getStatementDetailPdf(statementId): void {
        this.downloadStatementDetailPdf(statementId).subscribe(
            ( response: HttpResponse<any> ) => {
                let fileName = 'file';
                const contentDisposition = response.headers.get('Content-Disposition');
                if (contentDisposition) {
                    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = fileNameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].replace(/['"]/g, '');
                    }
                }

                switch (response.type) {

                    case HttpEventType.Response:
                        fileSaver.saveAs(response.body,  fileName);

                }

            },
            error => {
                console.log(error);
            });
    }

    aproveStatement(statementId): any {
        return this._httpClient.get('/api/accounting/aproveStatement/' + statementId,  {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }
     */
}
