import {Component, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from './i18n/de';
import {locale as english } from './i18n/en';
import {fuseAnimations} from '../../../@fuse/animations';
import {UserListComponent} from '@/main/users/user-list/user-list.component';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations   : fuseAnimations
})


export class UsersComponent {

  @ViewChild('userList') _userList: UserListComponent;

  constructor( private _fuseTranslationLoaderService: FuseTranslationLoaderService,
               public dialog: MatDialog) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }


    openHelp(): void {
        const dialogRef = this.dialog.open(HelpDialog, {
            //width: '500px',
            //height: '400px',
            //disableClose: true
            data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1',},
        });
    }

}

