import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
    log: string;
    jsons: {};
}

@Component({
    selector: 'log-dialog',
    templateUrl: 'logdialog.component.html',
})

// tslint:disable-next-line:class-name
export class LogdialogComponent {

    constructor(public dialogRef: MatDialogRef<LogdialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

}