import {Component, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from './i18n/de';
import {locale as english } from './i18n/en';
import {fuseAnimations} from '../../../@fuse/animations';
import {LicensorListComponent} from '@/main/licensors/licensor-list/licensor-list.component';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {UploadDialog} from '@/main/apps/file-manager/uploaddialog/upload-dialog.component';
import {ImportModalComponent} from '@/main/licensors/import-modal/import-modal.component';


@Component({
    selector: 'app-licensors',
    templateUrl: './licensors.component.html',
    styleUrls: ['./licensors.component.scss'],
    animations   : fuseAnimations
})


export class LicensorsComponent {

    @ViewChild('licensorList') _licensorList: LicensorListComponent;

    constructor( private _fuseTranslationLoaderService: FuseTranslationLoaderService,
                 public dialog: MatDialog) {

        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }


    openHelp(): void {
        const dialogRef = this.dialog.open(HelpDialog, {
            //width: '500px',
            //height: '400px',
            //disableClose: true
            data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1', helpTextList2: 'HELPTEXT.OVERVIEW.LIST2'},
        });
    }

    openImportModal(): void {

        const dialogRef = this.dialog.open(ImportModalComponent, {
            width: '1000px',
            //data: {fpath: this.fpath}
        });

        dialogRef.afterClosed().subscribe(result => {
            //console.log('The dialog was closed');
            //this.animal = result;
        });

    }

}

