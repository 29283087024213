export const locale = {
    lang: 'de',
    data: {
        'PROMOTIONPERIODS': {
            'OVERVIEW' : {
                'HEADLINE' : 'Promotionszeiträume - Übersicht',
                'TABLE' : {
                    'ID'        : 'ID',
                    'PERIOD_IDENTIFIER' : 'Identifier',
                    'PERIOD_START_DATE' : 'Start',
                    'PERIOD_END_DATE'   : 'Ende',
                    'MEDIA_VOLUME'      : 'Mediavolumen',
                    'BUDGET'            : 'Budget',
                },
            },

            'EDIT' : {
                'VIEW' : {
                    'HEADLINE' : 'Promotionszeitraumdetails',
                },
            },

            'ADD' : {
                'MODAL' : {
                    'HEADLINE'          : 'Promotionszeitraum hinzufügen / bearbeiten',
                    'PERIOD_IDENTIFIER' : 'Identifier',
                    'PERIOD_START_DATE' : 'Start',
                    'PERIOD_END_DATE'   : 'Ende',
                    'MEDIA_VOLUME'      : 'Mediavolumen',
                    'BUDGET'            : 'Budget',
                    'CLIENTID'          : 'Mandanten ID',

                    'FIELDREQMESSAGE' : 'Diese Feld muss ausgefüllt werden!',

                    'TAB' : {
                        'DATA'      : 'Promotionzeitraum Daten',
                        'PRODUCTS'  : 'Zugehörige Produkte',
                    },
                },
            },


        },

        'HELPTEXT' : {

                'OVERVIEW' : {
                    'HEADLINE' : 'Vertragsübersicht',
                    'TEXT' : 'Hier findest du eine Übersicht, über alle Mandanten.',
                    'LIST1' : 'Mit dem + kannst du neue Mandanten erstellen.',
                },

                'EDIT' : {
                    'HEADLINE' : 'Vertrag bearbeiten',
                    'TEXT' : 'Hier kann der Mandant mit seinen Benutzern im Detail angeschaut und bearbeitet werden.',
                    'LIST1' : 'Die mit * markierten Felder sind Pflichtfelder und müssen ausgefüllt sein.',
                    'LIST2' : 'Die Währung muss korrekt eingestellt werden, hier geht es um die Währung in der der Mandant abrechne, nicht wie er bezahlt wird.',
                    'LIST3' : 'USt. ID ist ein Pflichtfeld und wird vorausgesetzt, da jede Firma eine SteuerID haben muss.',
                    'LIST4' : 'Die Benutzer, die zum Mandanten gehören, können hier angelegt und bearbeitet werden.',
                    'LIST5' : 'Es können nur Benutzer zu diesem Mandanten angelegt werden.',
                },

        },
    }
};
