export const locale = {
    lang: 'de',
    data: {
        'PROJECT': {
            'OVERVIEW' : {
                'HEADLINE' : 'Projekt - Übersicht',
            },
            'SIDEBAR'   : {
                'BUTTON'    : {
                    'ADD'       : 'Projekt anlegen',
                },
            },
        },
    }
};
