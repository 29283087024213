export const locale = {
    lang: 'de',
    data: {
        'ANALYTICS': {
            'OVERALLREVENUE' : 'Umsatz',
            'HEADLINE': 'Analytics',
            'PERIOD' : {
                'FROM' : 'Abrechnungszeitraum von',
                'TO' : 'Abrechnungszeitraum bis'
            },
            'GRAPH' : {
                'OVERALL' : 'Gesamt',
                'DIGITAL' : 'Digital',
                'PHYSICAL' : 'Physisch',
                'SUBLICENSE' : 'Sublizenzen',
                'MERCH' : 'Merchandise'
            },
            'TABS' : {
                'DIGITALPERLICENSOR' : 'Digital pro Lizenzgeber',
                'PHYSICALPERLICENSOR' : 'Physisch pro Lizenzgeber',
                'MERCHPERLICENSOR' : 'Merchandise pro Lizenzgeber',
                'SUBLICENSEPERLICENSOR' : 'Sublizenzen pro Lizenzgeber'
            }
        }
    }
};
