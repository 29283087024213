import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {TrackListComponent} from '@/main/tracks/track-list/track-list.component';
import {ProductListComponent} from '@/main/products/product-list/product-list.component';

export interface DialogData {
    dialogHeadline: string;
    dialogText: string;
}

@Component({
    selector: 'product-dialog-component',
    templateUrl: 'product-dialog.component.html',
})

export class ProductDialogComponent{


    @ViewChild('productList') _productList: ProductListComponent;


    constructor(public dialogRef: MatDialogRef<ProductDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    closeTrackDialog(data = false): void {
        if (data){
            this.dialogRef.close({data: this._productList.selectedProducts });
        }else{
            this.dialogRef.close();
        }
    }
}
