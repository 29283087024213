import {AfterViewInit, Component, forwardRef, Input, OnChanges, OnInit} from '@angular/core';
import {MatFormFieldControl} from '@angular/material';
import {Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, map, startWith} from 'rxjs/operators';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {GlobalService} from '@/shared/services/global.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {ProductsService} from '@/main/products/products.service';
import {switchMap} from 'rxjs/operators';
import {isObject} from 'util';
import {TracksService} from '@/main/tracks/tracks.service';

export interface Track {
    'track_id': number;
    'isrc': string;
    'artist': string;
    'title': string;
}

@Component({
    selector: 'track-chooser',
    templateUrl: './track-chooser.component.html',
    providers: [{
        provide: MatFormFieldControl,
        useExisting: forwardRef(() => TrackChooserComponent),

    },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TrackChooserComponent),
            multi: true
        }]
})


export class TrackChooserComponent implements OnInit, ControlValueAccessor, MatFormFieldControl<TrackChooserComponent> {

    @Input() releaseId = 0;

    stateChanges: Subject<void> = new Subject<void>();
    id = '0';
    placeholder = 'My Custom Input';
    ngControl = null;
    focused = false;
    empty = true;
    _value: any;
    private _disabled = false;
    formControl = new FormControl();
    required = false;
    errorState = false;
    tracks = [];
    trackCtrl = new FormControl();
    filteredtracks: any;
    httpHeaders: HttpHeaders;

    constructor(private _globalService: GlobalService,
                private _translateService: TranslateService,
                private httpClient: HttpClient,
                private _auth: OAuthService,
                private _tracksService: TracksService) {


    }



    ngOnInit(): void {

        let uri = '';

        this.trackCtrl.valueChanges
            .subscribe(value => {
                if (isObject(value)) {
                    this._value = value;
                    this.onChange(value);
                    this.stateChanges.next();
                }
            });

        this.filteredtracks = this.trackCtrl.valueChanges.pipe(
            startWith(''),
            // delay emits
            debounceTime(300),
            // use switch map so as to cancel previous subscribed events, before creating new once
            switchMap(value => {
                if (value !== '' && !isObject(value)) {
                    // lookup from github
                    return this.lookup(value);
                } else {
                    // if no value is present, return null
                    return of(null);
                }
            })
        );

    }

    @Input()
    set disabled(disabled) {
        this._disabled = disabled;
        disabled ? this.formControl.disable() : this.formControl.enable();
        this.stateChanges.next();
    }
    get disabled(): boolean {
        return this._disabled;
    }


    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    onChange: any = () => { };

    onTouched: any = () => { };

    get shouldPlaceholderFloat(): any {
        return !!this.value;
    }
    get shouldLabelFloat(): any{
        return true;
    }

    changer(): void {
        console.log(this._value);
        this.onChange(this._value);
    }

    setDescribedByIds(ids: string[]): void {
    }

    onContainerClick(): void {
    }

    public get value(): any{
        return this._value;
    }

    public set value(v) {
        this._value = v;
        this.onChange(this._value);
        this.onTouched();
    }

    writeValue(obj: any): void {
        this._value = obj;
        this.trackCtrl.setValue(this._value);
    }

    lookup(value: string): Observable<Track[]> {

        return this._tracksService.loadTracks('tracks/get', value, 'asc', 'title', 0, 20, this.releaseId).pipe(

            map(results => results.data.items),
            // catch errors
            catchError(_ => {
                return of(null);
            })

        );

    }

    displayFn(track?: Track): string | undefined {

        if (track !== undefined && track != null) {
            if (track.isrc !== undefined) {
                return track.isrc + ' | ' + track.artist + ' - ' + track.title;
            } else {
                return '';
            }
        } else {
            return '';
        }

    }
}
