import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpUserEvent} from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
import { MatDialog } from '@angular/material';

@Injectable()
export class FileManagerService implements Resolve<any>
{
    onFilesChanged: BehaviorSubject<any>;
    onFileSelected: BehaviorSubject<any>;


    cpath = [];
    httpHeaders: HttpHeaders;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _auth: OAuthService,
        public dialog: MatDialog
    )
    {
        this.onFilesChanged = new BehaviorSubject({});
        this.onFileSelected = new BehaviorSubject({});
        this.httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + this._auth.getAccessToken());
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getFiles('')
            ]).then(
                ([files]) => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get files
     *
     * @returns {Promise<any>}
     */
    getFiles(folder:string,showOnly:boolean=false): Promise<any>{
            return new Promise((resolve, reject) => {

                const baseUrl = '';
                const url = `${baseUrl}/api/file-manager`;

                const formData = new FormData();
                formData.append('cpath', this.handleCurrentPath(folder, showOnly));

                this._httpClient.post(url, formData,{ headers : this.httpHeaders })
                    .subscribe((response: any) => {
                        this.onFilesChanged.next(response.data);
                        this.onFileSelected.next(response.data[0]);
                        resolve(response);
                    }, reject);
            });

    }

    handleCurrentPath(folder: string, showOnly: boolean= false): string{

        if (showOnly === false){
            if (folder === '..'){
                this.cpath.pop();
            }else{
                if (folder !== ''){
                    this.cpath.push(folder);
                }
            }
        }

        let i = 0;
        let path = '';
        for (i = 0; i < this.cpath.length; i++) {
            path += '/' + this.cpath[i];
        }
        //console.log(path);
        return path;
    }


    sendFile(formData: any): any{
        //assume your backend base url is http://127.0.0.1:8000

        const baseUrl = '';
        const url = `${baseUrl}/api/file-manager/upload`;
        formData.append('cpath', this.handleCurrentPath("",true));
        //console.log(formData);
        return this._httpClient.post(url, formData,{reportProgress: true, observe: 'events', headers : this.httpHeaders } );

    }

    deleteFile(formData: any): any{
        const baseUrl = '';
        const url = `${baseUrl}/api/file-manager/delete`;
        formData.append('cpath', this.handleCurrentPath("",true));
        return this._httpClient.post(url, formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    makeDir(formData: any): any{
        const baseUrl = '';
        const url = `${baseUrl}/api/file-manager/makedir`;
        formData.append('cpath', this.handleCurrentPath("",true));
        return this._httpClient.post(url, formData, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    downloadFile(formData: any): any{
        const baseUrl = '';
        const url = `${baseUrl}/api/file-manager/download`;
        formData.append('cpath', this.handleCurrentPath("",true));
        return this._httpClient.post(url, formData, {responseType: 'blob', reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

}
