import { HttpEvent, HttpEventType} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit, EventEmitter, Input} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatSort} from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {fuseAnimations} from '@fuse/animations';
import {TracksService} from '@/main/tracks/tracks.service';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {GlobalService} from '@/shared/services/global.service';
import {TrackinfoDialogComponent} from '@/main/tracks/trackinfodialog/trackinfo-dialog.component';

@Component({
    selector: 'app-track-list',
    templateUrl: './track-list.component.html',
    styleUrls: ['./track-list.component.scss'],
    animations   : fuseAnimations
})

export class TrackListComponent implements AfterViewInit  {

    @Input() clientId: any;
    @Input() contractId: any;
    @Input() isModal = false;

    displayedColumns: string[] = ['checkbox', 'track_id', 'title', 'artist', 'isrc', 'version' , 'on_releases', 'on_contracts' ];
    data: any[] = [];
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    filterInt: any;
    filterEvent: EventEmitter<String> = new EventEmitter(false);

    selectedTracks: string[] = [];
    isChecked = false;

    allSelected = false;

    resultsLength = 0;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor( private _fuseTranslationLoaderService: FuseTranslationLoaderService,
                 private _fuseSidebarService: FuseSidebarService,
                 private _tracksService: TracksService,
                 private _globalService: GlobalService,
                 public dialog: MatDialog) {

        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    /*
    openAddNewModal(test): void{
        const trackDialogRef = this.dialog.open(RoyaltyperiodsDialogComponent, {
            //width: '500px',
            //height: '400px',
            //disableClose: true
            data: { helpText: "Select tracks, AMK!" },
        });

        trackDialogRef.afterClosed()
            .subscribe(result => {
                console.log(result);
                if ( result && result.data && result.data.length > 0 ) {

                }
            });
    }
    */
    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        this.filterEvent.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page, this.filterEvent)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._tracksService.loadContracts(
                        'tracks/get',
                        this._tracksService._tracksfilterValue,
                        this.sort.direction,
                        this.sort.active,
                        this.paginator.pageIndex,
                        this.paginator.pageSize,
                        this.clientId,
                        this.contractId
                    );
                }),
                map(data => {
                    // Flip flag to show that loading has finished.
                    this.resultsLength = data.total_count;
                    return data.data.items;
                }),
                catchError(() => {
                    return observableOf([]);
                })
            ).subscribe(data => this.data = data);

    }

    refreshList(): void {
        this.filterEvent.emit(this._tracksService._tracksfilterValue);
    }

    onSelectedChange(id: any): void{

        if ( this.selectedTracks.length > 0 ){

            const index = this.selectedTracks.indexOf(id);

            if ( index !== -1 ){

                this.selectedTracks.splice(index, 1);

                if ( this.selectedTracks.length === 0 ){
                    this.isChecked = false;
                }

                this.allSelected = false;
                
                return;
            }
        }

        this.selectedTracks.push(id);

        if (this.selectedTracks.length === this.data.length) {
            this.allSelected = true;
        }

        this.isChecked = true;
    }

    selectAllTracks($event): void {
        if (this.allSelected) {
            this.selectedTracks = [];
            this.allSelected = false;
            this.isChecked = false;
        } else {
            this.selectedTracks = [];
            this.data.forEach(row => this.selectedTracks.push(row.track_id));
            this.allSelected = true;
            this.isChecked = true;
        }

    }




    askForDelete(): void{

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete all selected items?';

        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result )
                {
                        this.delete();
                }
                this.confirmDialogRef = null;
            });
    }

    delete(): void{

        const formData = new FormData();
        formData.append('ids', JSON.stringify(this.selectedTracks ));
        let uri = '';

        if (this.contractId !== undefined && this.contractId > 0) {
            uri = 'contracts/deletect';
            formData.append('contract_id', this.contractId);
        } else {
            uri = 'tracks/delete';
        }


        this._tracksService.delete(uri, formData).subscribe(
            ( response: HttpEvent<any> ) => {

                switch (response.type) {

                    case HttpEventType.Response:
                        if ( response.body != null && response.body.success === true ){

                            this.selectedTracks.length = 0;
                            this.isChecked = false;
                            this.filterEvent.emit(this._tracksService._tracksfilterValue);

                        }
                        break;
                }
            },
            error => {
                console.log(error);
            });

    }

    /*
    toggleSidebar(name): void
    {
      this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
     */

    applyFilter(filterValue: string): void  {
        clearInterval(this.filterInt);
        this.filterInt = setInterval(() => { this.callFilter(filterValue); }, 1000);
    }

    callFilter(filterValue: string): void {
        clearInterval(this.filterInt);
        this._tracksService._tracksfilterValue = filterValue;
        this.filterEvent.emit(this._tracksService._tracksfilterValue);
    }

    openHelp(): void {
        const dialogRef = this.dialog.open(HelpDialog, {
            //width: '500px',
            //height: '400px',
            //disableClose: true
            data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1'},
        });
    }


    openTrackInfoDialog(trackId, type): void {

        const dialogRef = this.dialog.open(TrackinfoDialogComponent, {
            //width: '500px',
            //height: '400px',
            //disableClose: true
            data: {dialogHeadline: 'Trackinfos', trackId: trackId, isReleases: (type === 'releases') ? true : false },
        });

    }

}

