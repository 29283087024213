import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {Location} from '@angular/common';
import {FuseSidebarService} from '../../../../../@fuse/components/sidebar/sidebar.service';
import {AccountingService} from '@/main/accounting/accounting.service';
import {GlobalService} from '@/shared/services/global.service';
import {ActivatedRoute, Router} from '@angular/router';
import {locale as english} from '@/main/accounting/i18n/en';
import {locale as german} from '@/main/accounting/i18n/de';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {FuseConfirmDialogComponent} from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as fileSaver from 'file-saver';
import {DashboardService} from '@/main/dashboard/dashboard.service';
import {LogdialogComponent} from '@/main/components/logdialog/logdialog.component';

@Component({
    selector: 'app-error-imports',
    templateUrl: './error-imports.component.html',
    styleUrls: ['./error-imports.component.scss']
})
export class ErrorImportsComponent implements OnInit {

    @Input() periodId: any;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    confirmDialogRef: MatDialogRef<LogdialogComponent>;

    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ['id', 'filename', 'user', 'endtime', 'error' ];

    private importingFiles: any;


    constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
                private _location: Location,
                private _fuseSidebarService: FuseSidebarService,
                private _accountingService: AccountingService,
                private _globalService: GlobalService,
                private _dashboardService: DashboardService,
                public dialog: MatDialog,
                private route: ActivatedRoute,
                private router: Router) {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
    }

    ngOnInit(): void {
        console.log(this.periodId);

        this.loadImportingFiles();

    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }



    loadImportingFiles(): void {
        this._accountingService.getErrorImportTasks(this.periodId).subscribe(
            ( response: HttpEvent<any> ) => {
                switch (response.type) {
                    case HttpEventType.Response:
                        if ( response.body != null && response.body.success === true ){


                            this.importingFiles = response.body.items;
                            this.dataSource = new MatTableDataSource(this.importingFiles);
                            this.dataSource.paginator = this.paginator;
                            this.dataSource.sort = this.sort;

                        }
                        break;
                }
            },
            error => {
                console.log('error get one: ' );
                console.log(error);
            });
    }

    showTaskLog(log): void {
        if (this._globalService.isAdmin() && log != null) {
            this.confirmDialogRef = this.dialog.open(LogdialogComponent, {
                disableClose: false,
                data: {log: log.replace(new RegExp('\r?\n', 'g'), '<br />')}
            });

            this.confirmDialogRef.afterClosed()
                .subscribe(result => {

                    this.confirmDialogRef = null;
                });
        }

        console.log(log);

    }


}
