import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, NgForm} from '@angular/forms';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {GlobalService} from '@/shared/services/global.service';
import {MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import {MomentUtcDateAdapter} from '@/shared/momentUtcAdapter';
import {TranslateService} from '@ngx-translate/core';
import {isObject} from 'util';
import {LicensorsService} from '@/main/licensors/licensors.service';
import {ContractsService} from '@/main/contracts/contracts.service';
import {ProductsService} from '@/main/products/products.service';


export interface DialogData {
    clientId: number;
    salesRow: any;
    licensorId: number;
}

@Component({
    selector: 'add-advance-modal',
    templateUrl: 'add-advance-modal.component.html',
    providers : [
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    ]
})
export class AddAdvanceModalComponent implements AfterViewInit {

    form: FormGroup;
    advance: any = {
        'clearing_via' : 'CONTRACT_REVENUE'
    };

    percentageDisabled = false;

    contractChooser   = new FormControl();

    chosenContract: any = {};

    releases = [];

    @ViewChild('advanceAddModalForm') advanceAddModalForm: NgForm;

    constructor(
        public dialogRef: MatDialogRef<AddAdvanceModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private _formBuilder: FormBuilder,
        private _globalService: GlobalService,
        private _licenorsService: LicensorsService,
        private _translateService: TranslateService,
        private _contractsService: ContractsService,
        private _productsService: ProductsService,
        public dialog: MatDialog)
    {

        this.advance.client_id = this.data.clientId;
        this.advance.licensor_id = this.data.licensorId;

        if (isObject(this.data.salesRow) && this.data.salesRow.id !== undefined) {
            this.advance = this.data.salesRow;
            console.log(this.advance);
        }
    }

    ngAfterViewInit(): void {


        console.log(this.data);

        this.contractChooser.valueChanges.subscribe(value => this.contractChosen(value));

    }

    contractChosen(release): void {



        console.log(this.advance);
    }


    onNoClick(): void {

        this.dialogRef.close(false);
    }

    updateChosenContract(): void {
        if (this.advance.contract_id !== undefined && this.advance.contract_id !== '') {
            this._contractsService.getOne(
                'contracts/get/' + this.advance.contract_id,
            ).subscribe(
                ( response: HttpEvent<any> ) => {
                    switch (response.type) {
                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                // console.log(response.body);
                                this.chosenContract = response.body.data;
                                console.log(this.chosenContract);

                                this.setReleases();

                                // this.form.patchValue( this.licensor );
                            }
                            break;
                    }
                },
                error => {
                    console.log("error get one contracts: " );
                    console.log(error);
                });
        }

    }

    setReleases(): void {
        this._productsService.loadReleases2('products/getReleases', '', 'asc', 'title', 0, 100, '', 0, this.advance.contract_id).subscribe(
            (response: HttpEvent<any>) => {
                switch (response.type) {
                    case HttpEventType.Response:
                        this.releases = response.body.data.items;
                        break;
                }
            }
        );
    }

    updatePercentage(advance): void {
        this.percentageDisabled = false;
        this.advance.recouping_percentage = '';
        this.chosenContract.expense_deductions.forEach((item, index) => {
             if (item.expense_type === advance.costs_type) {
                 this.advance.recouping_percentage = item.percentage;
                 this.percentageDisabled = true;
             }
        });

        if (this.advance.costs_type === 7) {
            this.advance.clearing_via = 'CONTRACT_REVENUE';
        }

    }

    save(): void
    {

        if (this.advanceAddModalForm.form.valid) {

            let uri = 'licensors/advances/save';

            this._licenorsService.saveAdvance(uri, this.advance).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this.dialogRef.close(false);
                            }
                            break;
                    }

                },
                error => {
                    console.log(error);
                });
        }else{

        }

    }




}
