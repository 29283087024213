export const locale = {
    lang: 'de',
    data: {
        'LICENSOR': {

            'OVERVIEW' : {
                'HEADLINE' : 'Lizenzgeber - Übersicht',
                'TABLE' : {
                    'ID'        : 'ID',
                    'NAME'      : 'Name',
                    'STREET'    : 'Strasse',
                    'ZIP'       : 'PLZ',
                    'CITY'      : 'Ort',
                    'CONTACTPERSON'  : 'Kontaktperson',
                    'ACCOUNTBALANCE' : 'Kontostand',
                    'TOTAL_DUE' : 'Restschuld',
                    'TOTAL_PAYABLE' : 'Auszahlung',
                    'APROVESTATEMENT' : 'Statement bestätigen',
                    'APROVEQUESTION' : 'Sind sie Sicher dass sie das Statement abschliessen wollen? Dieser Vorgang kann nicht rückgängig gemacht werden!',
                    'PUBLISHQUESTION' : 'Sind sie Sicher dass sie das Statement veröffentlichen wollen?',
                    'UNPUBLISHQUESTION' : 'Sind sie Sicher dass sie das Statement unveröffentlichen wollen?'
                },
                'ADVANCE' : {
                    'COSTS_TYPE'    : 'Typ',
                    'NAME'          : 'Name',
                    'AMOUNT'        : 'Betrag',
                    'ALREADY_BILLED': 'bereits verrechnet',
                    'STILL_TO_BILL' : 'noch offen',
                    'RECOUPING_PERCENTAGE' : 'verrechnung in %',
                    'CLEARING_VIA'  : 'Rückzahlg. via',
                    'CLEARED'       : 'erledigt',
                    'ADD' : {
                        'MODAL' : {
                            'HEADLINE' : 'Vorschuss / Kosten hinzufügen',
                            'COSTS_TYPE' : 'Kosten / Vorschuss Typ',
                            'CONTRACT' : 'Vertrag',
                            'AMOUNT' : 'Betrag',
                            'RECOUPING_PERCENTAGE' : 'verrechnung in %',
                            'CLEARING_VIA' : 'Verrechnung mit',
                            'CONTRACT_REVENUE' : 'Umsatz dieses Vertrages',
                            'STATEMENT_REVENUE' : 'Umsatz des Statements',
                            'NAME' : 'Beschreibung',
                            'SAVE' : 'Speichern',
                            'CANCEL' : 'Abbrechen',
                            'RELEASE' : 'Release'
                        }
                    }
                }
            },


            'ADD' : {
                'MODAL' : {
                    'CONTRACTS'         : 'Verträge',
                    'HEADLINE'          : 'Lizenzgeber hinzufügen / bearbeiten',
                    'NAME'              : 'Name',
                    'NAMEREQMESSAGE'    : 'Name notwendig!',
                    'STREET'            : 'Strasse',
                    'STREETREQMESSAGE'  : 'Strasse notwendig!',
                    'CITY'              : 'Stadt',
                    'CITYREQMESSAGE'    : 'Stadt notwendig!',
                    'ZIP'               : 'PLZ',
                    'ZIPREQMESSAGE'     : 'PLZ notwendig!',
                    'COUNTRY'           : 'Land',
                    'COUNTRYREQMESSAGE' : 'Land notwendig!',
                    'CONTACTPERSON'     : 'Kontakt Ansprechpartner',
                    'CONTACTEMAIL'      : 'E-Mail Kontakt',
                    'TYPE'              : 'Lizenzgeber - Art',
                    'ADVANCESANDCOSTS'  : 'Vorschüsse und Kosten',
                    'STATEMENTS'        : 'Lizenzabrechnungen',
                    'ACCOUNTBALANCE'    : 'Kontostand',
                    'ADRESSLINE1'       : 'Addresszeile 1',
                    'STATEMENTSLIST' : {
                        'PERIOD' : 'Abrechnungs Periode',
                        'DUE' : 'Restschuld',
                        'PAYOUT' : 'Auszahlung'
                    },
                    'CURRENCY'          : 'Abrechnungswährung'
                },
            },

            'EDIT' : {
                'VIEW' : {
                    'HEADLINE' : 'Lizenzgeber',
                },
            },

            'IMPORT' : {
                'HEADLINE' : 'Lizenzgeber importieren',
                'TEXT'     : 'Lade das ausgefüllte Excel sheet hier hoch und klicke danach auf die Wolke zum importieren',
                'IMPORTFAILED' : 'Import fehlgeschlagen',
                'IMPORTED' : 'Importiert',
                'FAILEDREASON' : 'Fehlerursache',
                'DOWNLOADTEMPLATETEXT' : 'Download Lizenzgeber Import Template'
            }

        },

        'HELPTEXT' : {

                'OVERVIEW' : {
                    'HEADLINE' : 'Lizenzgeberübersicht',
                    'TEXT' : 'Hier findest du eine Übersicht, über alle Lizenzgeber.',
                    'LIST1' : 'Mit dem + kannst du neue Lizenzgeber erstellen.',
                    'LIST2' : 'Mit dem Wolkensymbol kannst du Lizenzgeber mit einem Excelsheet importieren'
                },

                'DETAIL' : {
                    'HEADLINE' : 'Lizenzgeber bearbeiten',
                    'TEXT' : 'Hier kann der Mandant mit seinen Benutzern im Detail angeschaut und bearbeitet werden.',
                    'LIST1' : 'Die mit * markierten Felder sind Pflichtfelder und müssen ausgefüllt sein.',
                },

        },
    }
};
