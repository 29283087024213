export const locale = {
    lang: 'de',
    data: {
        'ACCOUNT': {
            'ERRORS' : {
                'REQUIRED' : {
                    'FIRSTNAME' : 'Der Vorname ist ein Pflichtfeld!',
                    'LASTNAME'  : 'Der Nachname ist ein Pflichtfeld!',
                    'EMAIL'     : 'eine E-Mail Adresse wird benötigt!',
                    'LANGUAGE'  : 'Bitte wählen Sie eine bevorzugte Sprache aus!'
                }
            },
            'FIRSTNAME': 'Vorname',
            'LASTNAME': 'Nachname',
            'EMAIL' : 'E-Mail',
            'PREFEREDLANGUAGE' : 'Bevorzugte Sprache'

        }
    }
};
