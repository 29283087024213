import {Component, forwardRef, Injectable, Input, OnChanges, OnInit} from '@angular/core';
import {MatFormFieldControl} from '@angular/material';
import {Subject} from 'rxjs';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {GlobalService} from '@/shared/services/global.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from './i18n/de';
import {locale as english } from './i18n/en';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material';



@Component({
  selector: 'app-json-input',
  templateUrl: './json-input.component.html',
  styleUrls: ['./json-input.component.scss'],
    providers: [{
        provide: MatFormFieldControl,
        useExisting: forwardRef(() => JsonInputComponent),

    },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => JsonInputComponent),
            multi: true
        }]
})
export class JsonInputComponent implements ControlValueAccessor, MatFormFieldControl<JsonInputComponent> {

  stateChanges: Subject<void> = new Subject<void>();
  id = '0';
  placeholder = 'My Custom Input';
  ngControl = null;
  focused = false;
  empty = true;
  //value: any;
  private _disabled = false;
  formControl = new FormControl();
  required = false;
  errorState = false;
  adder = {
      'field1': '',
      'field2': '',
      'edit': false,
      'index': 0
  };

  private _value;

  listItems = [];


    //
  @Input() options: any;

  @Input()
  set disabled(disabled) {
      this._disabled = disabled;
      disabled ? this.formControl.disable() : this.formControl.enable();
      this.stateChanges.next();
  }
  get disabled(): boolean {
      return this._disabled;
  }


    registerOnChange(fn: any): void {
        this.onChange = fn;
        //console.log("ficlem");
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }



    setDisabledState?(isDisabled: boolean): void {
    }

    onChange: any = () => { };

    onTouched: any = () => { };

    constructor(
        private _globalService: GlobalService,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService)
    {

        this._fuseTranslationLoaderService.loadTranslations(english, german);
        this.formControl.valueChanges
            .subscribe(value => {
                this.value = value;
                //console.log(this.value);
                this.stateChanges.next();
            });

    }

    public get value(): any{
        return this.listItems;
    }

    public set value(v) {
        if (v !== undefined && v !== null) {
            this.listItems = v;
        } else {
            this.listItems = [];
        }

        //this._value = v;
        this.onChange(this.listItems);
        this.onTouched();
    }

    writeValue(obj: any): void {

        if (obj !== undefined && obj !== null) {
            this.listItems = obj;
        }else{
            //reset control
            this.listItems = [];
        }
    }


  get shouldPlaceholderFloat(): any {
      return !!this.value;
  }
  get shouldLabelFloat(): any{
    return true;
  }

  setDescribedByIds(ids: string[]): void {
  }

  onContainerClick(): void {
  }

  addItem(): void {
    if (this.disabled === false && this.adder.field1 !== '' && this.adder.field1 !== ''){

        const item = {};

        if (this.adder.edit) {

            this.listItems[this.adder.index][this.options.field1.key] = this.adder.field1;
            this.listItems[this.adder.index][this.options.field2.key] = this.adder.field2;

        } else {

            if (this.options.unique_value) {

                for (let i = 0; i < this.listItems.length ; i++) {
                    if (this.listItems[i][this.options.field1.key] === this.adder.field1) {

                        this._snackBar.open(this.adder.field1 + ' ' + this._translateService.instant('JSONINPUT.ERROR_DOUBLE_VALUE'),
                            this._translateService.instant('JSONINPUT.CLOSE_BUTTON') ,
                            {
                                duration: 1500,
                                horizontalPosition: 'center',
                                verticalPosition: 'top',

                            });

                        return;
                    }
                }

            }

            item[this.options.field1.key] = this.adder.field1;
            item[this.options.field2.key] = this.adder.field2;

            // muss rein für den init save
            if (this.listItems == null){
                this.listItems = [];
            }

            this.listItems.push(item);
            // muss rein für den init save
            this.onChange(this.listItems);
        }

        this.adder.field1 = '';
        this.adder.field2 = '';
        this.adder.edit = false;
        this.adder.index = 0;
    }
  }

  deleteItem(index): void{
    if (this.disabled === false) {
        this.listItems.splice(index, 1);
    }
  }

  editItem(index): void {
    if (this.disabled === false) {

        this.adder.field1 = this.listItems[index][this.options.field1.key];
        this.adder.field2 = this.listItems[index][this.options.field2.key];
        this.adder.edit = true;
        this.adder.index = index;

    }
  }

  getArrayOption(key): string {
      let language = this._translateService.currentLang;
      for (let i = 0; i < this.options.field1.array.length ; i++) {
          if (this.options.field1.array[i].key === key) {
              return this.options.field1.array[i].option[language];
          }
      }
  }

  /*
  tester(): void {
    console.log(this.listItems);

  }
  */

}
