import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import {LicensorsService} from '@/main/licensors/licensors.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {locale as german } from '../i18n/de';
import {locale as english } from '../i18n/en';
import {Location} from '@angular/common';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {MatDialog, MatDialogRef, MatTabChangeEvent} from '@angular/material';
import {GlobalService} from '@/shared/services/global.service';
import {TrackListComponent} from '@/main/tracks/track-list/track-list.component';
import {TrackDialogComponent} from '@/main/components/trackdialog/track-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {ProductListComponent} from '@/main/products/product-list/product-list.component';
import {ProductDialogComponent} from '@/main/components/product-dialog/product-dialog.component';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {Observable} from 'rxjs';
import {ReleaseListComponent} from '@/main/products/release-list/release-list.component';

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.scss']
})
export class ContractDetailComponent implements OnInit {

  @ViewChild('contractForm') contractForm: NgForm;
  @ViewChild('trackList') _trackList: TrackListComponent;
  @ViewChild('releaseList') _releaseList: ReleaseListComponent;
  @ViewChild('productList') _productList: ProductListComponent;


  activeTab = '_contract';
    tabs = {
        0: '_contract',
        1: '_trackList',
        2: '_releaseList',
        3: '_productList'
    };
  selectedTab = 0;

  contractId: string;
  contract = {
      contract_id: 0,
      licensor_id: 0,
      client_id: 0,
      royalty_base: 'PPD',
      royalty_share: 0,
      digital_split_downloads: 0,
      mechanicals_deduction_downloads: 0,
      digital_split_streaming: 0,
      mechanicals_deduction_streaming: 0,
      export_deduction: 0,
      promotion_deduction: 0,
      mailorder_deduction: 0,
      return_reserves: 0,
      release_reserves: 0,
      inhouse_compilation_split_download: 0,
      inhouse_compilation_split_streaming: 0,
      inhouse_compilation_royalty_share: 0,
      sublicense_split: 0,
      sublicense_split_digital: 0,
      merch_royalty_base: 'PPD',
      merchandise_split: 0,
      merch_billable: 0
  };

    territory_splits_options = {
      'unique_value': true,
      'field1' : {
          'key'     : 'territory',
          'label'   : 'CONTRACTS.ADD.MODAL.TERRITORY',
          'type'    : 'dropdown',
          'source'  : 'countries'
      },
      'field2' : {
          'key' : 'percentage',
          'label' : 'CONTRACTS.ADD.MODAL.PERCENTAGE',
          'type'  : 'number'
      }
    };

    export_deduction_by_territory_options = {
        'unique_value': true,
        'field1' : {
            'key'     : 'territory',
            'label'   : 'CONTRACTS.ADD.MODAL.TERRITORY',
            'type'    : 'dropdown',
            'source'  : 'countries'
        },
        'field2' : {
            'key' : 'percentage',
            'label' : 'CONTRACTS.ADD.MODAL.PERCENTAGE',
            'type'  : 'number'
        }
    };

    packaging_deduction_options = {
        'unique_value': true,
        'field1' : {
            'key'       : 'packagingType',
            'label'     : 'CONTRACTS.ADD.MODAL.PACKAGING_TYPE',
            'type'      : 'dropdown',
            'source'    : 'array',
            'array'     : this._globalService.releaseTypes
        },
        'field2'    : {
            'key'   : 'percentage',
            'label' : 'CONTRACTS.ADD.MODAL.DEDUCTION',
            'type'  : 'number'
        }
    };

    expense_deductions_options = {
        'unique_value': true,
        'field1' : {
            'key'       : 'expense_type',
            'label'     : 'CONTRACTS.ADD.MODAL.EXPENSETYPE',
            'type'      : 'dropdown',
            'source'    : 'array',
            'array'     : this._globalService.costTypes
        },
        'field2'    : {
            'key'   : 'percentage',
            'label' : 'CONTRACTS.ADD.MODAL.PERCENTAGE',
            'type'  : 'number'
        }
    };

    graduation_share_options = {
        'unique_value': true,
        'field1' : {
            'key'       : 'amount',
            'label'     : 'CONTRACTS.ADD.MODAL.AMOUNT',
            'type'      : 'number',

        },
        'field2'    : {
            'key'   : 'percentage',
            'label' : 'CONTRACTS.ADD.MODAL.SHARE',
            'type'  : 'number'
        }
    };
  // showUserList = true;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  constructor(
      private _location: Location,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private route: ActivatedRoute,
      private router: Router,
      private _formBuilder: FormBuilder,
      private _contractsService: LicensorsService,
      private _globalService: GlobalService,
      private _translateService: TranslateService,
      public dialog: MatDialog
      ) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);


  }

    canDeactivate(): Observable<boolean> | boolean {
        return this._globalService.canDeactivate();
    }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
       this.activeTab = this.tabs[tabChangeEvent.index];

       if (this.activeTab === '_releaseList') {
           this._releaseList.filterEvent.emit();
       }

        //console.log(this.activeTab);
  }


  addNew(activeTab): void {

      //this[activeTab].openAddNewModal(this.periodId,0);
      //this[activeTab].openAddNewModal('sdsdsd');
      const trackDialogRef = this.dialog.open(TrackDialogComponent, {
          width: '1000px',
          //height: '100%',
          disableClose: true,
          data: { dialogHeadline: 'CONTRACTS.ADD.TRACKMODAL.HEADLINE', dialogText: 'CONTRACTS.ADD.TRACKMODAL.TEXT', notContractId: this.contractId  },
      });

      trackDialogRef.afterClosed()
          .subscribe(result => {
              //console.log(result);
              if ( result && result.data && result.data.length > 0 ) {
                  this.saveContractTracks(result.data);
              }
          });
  }

    addNewProduct(activeTab): void {

        const productDialogRef = this.dialog.open(ProductDialogComponent, {
            width: '1000px',
            disableClose: true,
            data: { dialogHeadline: 'Select Products', dialogText: ''  },
        });

        productDialogRef.afterClosed()
            .subscribe(result => {
                if ( result && result.data && result.data.length > 0 ) {
                    this.saveContractProducts(result.data);
                }
            });
    }

  askForDelete(activeTab): void {

      this[activeTab].askForDelete();

  }

  ngOnInit(): void {

    this.contractId = this.route.snapshot.paramMap.get('id');


    if (this.contractId !== 'new') {
        // this.showUserList = false;
        this.getOne(this.contractId);
    }else{
        // this.showUserList = true;
        // this.contract.licensor_id = this._globalService.getUserClientId();

        if (this.route.snapshot.queryParamMap.get('copy') !== undefined && this.route.snapshot.queryParamMap.get('copy') !== null) {
            this.getOne(this.route.snapshot.queryParamMap.get('copy'), true);
        } else {

            this.contract.client_id = this._globalService.getUserClientId();
            if (this.route.snapshot.queryParamMap.get('licensorId')){
                this.contract.licensor_id = Number(this.route.snapshot.queryParamMap.get('licensorId'));
            }
            this._globalService.setFormData(this.contract, Object.assign({}, this.contract));
        }

    }
  }


  back(): void {
    this._location.back();
  }


  save(): void{

        if (this.contractForm.form.valid) {

            let uri = 'contracts/save';
            if (this.contractId !== 'new'){
                uri += '/' + this.contractId;
            }

            this._contractsService.save(uri, this.contract).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){

                                this._globalService.setFormData(this.contract, Object.assign({}, this.contract));

                                if (this.contractId === 'new'){
                                    this.askForContinueAdding();
                                }
                                /*
                                this.contract.contract_id = response.body.data;
                                this.contractId = response.body.data;
                                this._trackList.refreshList();
                                this._productList.refreshList();
                                 */
                            }
                            break;
                    }

                },
                error => {
                    console.log(error);
                });
        }

    }

    askForContinueAdding(): void{

        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Do you want to add more items?';
        this.confirmDialogRef.afterClosed()
            .subscribe(result => {
                if ( result ){
                    this.contract = {
                        contract_id: 0,
                        licensor_id: 0,
                        client_id: 0,
                        royalty_base: 'PPD',
                        royalty_share: 0,
                        digital_split_downloads: 0,
                        mechanicals_deduction_downloads: 0,
                        digital_split_streaming: 0,
                        mechanicals_deduction_streaming: 0,
                        export_deduction: 0,
                        promotion_deduction: 0,
                        mailorder_deduction: 0,
                        return_reserves: 0,
                        release_reserves: 0,
                        inhouse_compilation_split_download: 0,
                        inhouse_compilation_split_streaming: 0,
                        inhouse_compilation_royalty_share: 0,
                        sublicense_split: 0,
                        sublicense_split_digital: 0,
                        merch_royalty_base: 'PPD',
                        merchandise_split: 0,
                        merch_billable: 0
                    };
                    this.contractForm.resetForm();
                }else{
                    this._location.back();
                }
                this.confirmDialogRef = null;
            });
    }

    saveContractTracks(trackIds: any): void{

        if (trackIds && trackIds.length > 0)   {

            let uri = 'contracts/savect';

            //console.log({tids: trackIds});
            //console.log(this.contractId);

            const data = {'contractId': this.contractId, tids: trackIds};

            this._contractsService.save(uri, data).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this._trackList.refreshList();
                            }
                            break;
                    }

                },
                error => {
                    console.log(error);
                });
       }

    }

    saveContractProducts(productIds: any): void{

        if (productIds && productIds.length > 0)   {

            let uri = 'contracts/savecp';

            const data = {'contractId': this.contractId, ids: productIds};

            this._contractsService.save(uri, data).subscribe(
                ( response: HttpEvent<any> ) => {

                    switch (response.type) {

                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                this._productList.refreshList();
                            }
                            break;
                    }

                },
                error => {
                    console.log(error);
                });
        }

    }

  getOne(uid: string, forCopy: boolean = false): void{

    this._contractsService.getOne(
        'contracts/get/' + uid,
    ).subscribe(
        ( response: HttpEvent<any> ) => {
          switch (response.type) {
            case HttpEventType.Response:
              if ( response.body != null && response.body.success === true ){
                // console.log(response.body);
                  if (forCopy) {
                      this.contract = response.body.data;
                      this.contract.contract_id = 0;
                      this._globalService.setFormData(this.contract, Object.assign({}, this.contract));
                  } else {
                      this.contract = response.body.data;
                      this._globalService.setFormData(this.contract, Object.assign({}, this.contract));
                  }

                // this.form.patchValue( this.licensor );
              }
              break;
          }
        },
        error => {
          console.log("error get one contracts: " );
          console.log(error);
        });
  }

    openHelp(): void {
        const dialogRef = this.dialog.open(HelpDialog, {
            // width: '500px',
            // height: '400px',
            // disableClose: true
            data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1',},
        });
    }

}
