export const locale = {
    lang: 'en',
    data: {
        'PROMOTIONPERIODS': {
            'OVERVIEW' : {
                'HEADLINE' : 'Promotionperiods - overview',
                'TABLE' : {
                  'ID'        : 'ID',
                  'CONTRACT_IDENTIFIER'      : 'Identifier',
                  'PERIOD_START_DATE' : 'Startdate',
                  'PERIOD_END_DATE'   : 'Enddate',
                  'MEDIA_VOLUME'      : 'Mediavol.',
                  'BUDGET'            : 'Budget',
                },
            },

            'EDIT' : {
                'VIEW' : {
                    'HEADLINE' : 'Promotionsperioddetails',
                },
            },

            'ADD' : {
                'MODAL' : {
                    'HEADLINE'          : 'add / edit contract',
                    'NAME'              : 'Identifier',
                    'licensor'            : 'Licensor',
                    'promotion_deduction'  : 'Promotion deduction %',
                    'export_deduction'     : 'Export deduction %',
                    'digital_split_downloads'   : 'Digital split - downloads %',
                    'digital_split_streaming'   : 'Digital split - streaming %',
                    'merchandise_split' : 'Merchandise split %',
                    'sublicense_split_digital' : 'Sublicense split - digital %',
                    'sublicense_split'         : 'Sublicense split %',
                    'royalty_share'            : 'Royalty share %',
                    'inhouse_compilation_split_download': 'Inhouse compilation split - download %',
                    'inhouse_compilation_split_streaming': 'Inhouse compilation split - streaming %',
                    'inhouse_compilation_split_share'   : 'Inhouse compilation split - royalty share %',
                    'inhouse_jason' : 'Packaging deductions',
                    'graduation_royalty_share' : 'Graduation by CD/LP Sales numbers',
                    'return_reserves' : 'Return reserves %',
                    'release_reserves' : 'Release of return reserves (Months)',
                    'royalty_base' : 'Royalty base',
                    'royalty_base_merch' : 'Royalty Base for merchandise sales',

                    'digital_download_split_territory'   : 'Digital-Download-Split by territory',
                    'digital_streaming_split_territory'   : 'Digital-Streaming-Split by territory',

                    'SPLITS' : 'Split - Abgaben',
                    'DIGITALDOWNLOADTERRITORY' : 'Split - Download by territory',
                    'DIGITALSTREAMTERRITORY' : 'Split - Streaming by territory',
                    'ROYALTYSHARES' : 'Royalty Shares',
                    'DEDUCTIONS' : 'Deductions',
                    'RESERVES' : 'Reserves',
                    'NON_EXPORT_TERRITORIES' : 'From export excluded territories',

                    'TAB' : {
                        'CONTRACTDATA'   : 'Conditions',
                        'TITLEDATA'      : 'Titles',
                        'PRODUCTS'      : 'Merchandise products'
                    },

                    'MERCHANDISE' : 'Merchandising',
                    'MERCH_BILLABLE' : 'Merchandising billable',
                    'YES' : 'Yes',
                    'NO' : 'No',

                    'FIELDREQMESSAGE' : 'This field is required!'
                },
            },

        },

        'HELPTEXT' : {

            'OVERVIEW' : {
                'HEADLINE' : 'Contractoverview',
                'TEXT' : 'Here you will find an overview of all clients.',
                'LIST1' : 'With the + you can create new clients.',
            },

            'EDIT' : {
                'HEADLINE' : 'Edit contract',
                'TEXT' : 'Here, the client can be viewed and edited in detail with its users.',
                'LIST1' : 'The fields marked with * are mandatory and must be completed.',
                'LIST2' : 'The currency must be set correctly, this is the currency in which the client settles, not how it is paid.',
                'LIST3' : 'VAT ID is a mandatory field and is assumed as each company must have a tax ID.',
                'LIST4' : 'The users belonging to the client can be created and edited here.',
                'LIST5' : 'Only users can be created for this client.',
            },

        },
    }
};
