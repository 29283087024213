import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromotionperiodsComponent } from './promotionperiods.component';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import {RouterModule} from '@angular/router';
import {
  MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonModule,
  MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule,
  MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatGridListModule,
  MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatPaginatorModule,
  MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule,
  MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule,
  MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule
} from '@angular/material';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {AuthGuardService as AuthGuard } from '@/auth/auth-guard.service';
import {FuseSidebarModule} from '../../../@fuse/components';
import {SharedModule} from '@/shared/shared.module';
import {PromotionperiodListComponent} from '@/main/promotionperiods/promotionperiod-list/promotionperiod-list.component';
import {TracksModule} from '@/main/tracks/tracks.module';
import {ProductsModule} from '@/main/products/products.module';
import {PromotionperiodsService} from '@/main/promotionperiods/promotionperiods.service';
import {PromotionperiodDetailComponent} from '@/main/promotionperiods/promotionperiod-detail/promotionperiod-detail.component';
import {CanDeactivateGuard} from '@/shared/services/can-deactivate-guard.service';


const routes = [
  {
    path     : 'promotionperiods',
    component: PromotionperiodsComponent,
    canActivate: [AuthGuard],
    /*
    resolve  : {
      files: ExpensetypesService
    }
     */
  },


    {
        path    : 'promotionperiods/:id',
        component: PromotionperiodDetailComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
    }


];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatMomentDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        TranslateModule,
        FuseSharedModule,
        FuseSidebarModule,
        TracksModule,
        SharedModule,
        ProductsModule
    ],
  providers   : [
      PromotionperiodsService
  ],
    declarations: [PromotionperiodsComponent, PromotionperiodListComponent, PromotionperiodDetailComponent ],
  entryComponents: [ ],
    exports: [
        PromotionperiodsComponent,
        PromotionperiodListComponent
    ]
})
export class PromotionperiodsModule { }
