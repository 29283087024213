import {Component, OnInit, ViewChild} from '@angular/core';
import {RoyaltyPeriodsComponent} from '@/main/accounting/royalty-periods/royalty-periods.component';
import {FuseTranslationLoaderService} from '../../../@fuse/services/translation-loader.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog} from '@angular/material';
import {locale as english} from '@/main/accounting/i18n/en';
import {locale as german} from '@/main/accounting/i18n/de';
import {HelpDialog} from '@/main/components/helpdialog/help-dialog.component';
import {AddPeriodDialogComponent} from '@/main/accounting/addPeriodDialog/add-period-dialog.component';
import {MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import {MomentUtcDateAdapter} from '@/shared/momentUtcAdapter';
import {McmsSalesSyncModalComponent} from '@/main/accounting/sales-import/mcms-sales-sync-modal/mcms-sales-sync-modal.component';


@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss'],
    providers : [
  { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
  { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  { provide: DateAdapter, useClass: MomentUtcDateAdapter },
]
})
export class AccountingComponent implements OnInit {

  @ViewChild('royaltyPeriods') _royaltyPeriods: RoyaltyPeriodsComponent;

  constructor( private _fuseTranslationLoaderService: FuseTranslationLoaderService,
               public dialog: MatDialog) {

    this._fuseTranslationLoaderService.loadTranslations(english, german);
  }

  ngOnInit(): void {
  }

  openHelp(): void {
    const dialogRef = this.dialog.open(HelpDialog, {
      //width: '500px',
      //height: '400px',
      //disableClose: true
      data: { helpText: 'HELPTEXT.OVERVIEW.TEXT' , helpHeadline : 'HELPTEXT.OVERVIEW.HEADLINE', helpTextList1: 'HELPTEXT.OVERVIEW.LIST1',},
    });
  }

  openAddPeriodDialog(id: any): void
  {
    const dialogRef = this.dialog.open(AddPeriodDialogComponent, {
      //width: '500px',
      //height: '400px',
      data: {'id': id}, //important! for insert uuid = 0 / edit = uuid
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

        this._royaltyPeriods.filterEvent.emit(this._royaltyPeriods.filterValue);

    });
  }




}
