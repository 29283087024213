import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsComponent } from './clients.component';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import {RouterModule} from '@angular/router';
import {
  MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonModule,
  MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule,
  MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatGridListModule,
  MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatPaginatorModule,
  MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule,
  MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule,
  MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule
} from '@angular/material';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {AuthGuardService as AuthGuard } from '@/auth/auth-guard.service';
import {FuseSidebarModule} from '../../../@fuse/components';
import {ClientsService} from '@/main/clients/clients.service';

import { ClientDetailComponent } from './client-detail/client-detail.component';
import {UsersModule} from '@/main/users/users.module';
import {CountryByCodePipe} from '@/shared/pipes/CountryByCode.pipe';
import {CurrencyByCodePipe} from '@/shared/pipes/CurrencyByCode.pipe';
import {LicensorDetailComponent} from '@/main/licensors/licensor-detail/licensor-detail.component';
import {SharedModule} from '@/shared/shared.module';
import {CanDeactivateGuard} from '@/shared/services/can-deactivate-guard.service';

const routes = [
  {
    path     : 'clients',
    component: ClientsComponent,
    canActivate: [AuthGuard],
    /*
    resolve  : {
      files: ExpensetypesService
    }
     */
  },
    {
        path    : 'clients/:id',
        component: ClientDetailComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatMomentDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        TranslateModule,
        FuseSharedModule,
        FuseSidebarModule,
        UsersModule,
        SharedModule
    ],
  providers   : [
    ClientsService
  ],
    declarations: [ClientsComponent, ClientDetailComponent],
  entryComponents: [ ],
  exports: [
    ClientsComponent
  ]
})
export class ClientsModule { }
