import { Component, ViewEncapsulation } from '@angular/core';
import {FuseConfigService} from '../../../../@fuse/services/config.service';
import {FuseSidebarService} from '../../../../@fuse/components/sidebar/sidebar.service';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../../../@fuse/services/translation-loader.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {Router} from '@angular/router';
import {GlobalService} from '@/shared/services/global.service';
import * as _ from 'lodash';
import {locale as english} from '@/main/dashboard/i18n/en';
import {locale as german} from '@/main/dashboard/i18n/de';

@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent
{
    date: Date;
    events: any[];
    notes: any[];
    settings: any;
    languages: any;
    selectedLanguage: any;
    /**
     * Constructor
     */
    constructor(private _fuseConfigService: FuseConfigService,
                private _fuseSidebarService: FuseSidebarService,
                private _translateService: TranslateService,
                private _fuseTranslationLoaderService: FuseTranslationLoaderService,
                private auth: OAuthService,
                private router: Router,
                private _translateModul: TranslateModule,
                private _globalService: GlobalService)
    {
        this._fuseTranslationLoaderService.loadTranslations(english, german);
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud : false,
            retro : true
        };

        this.languages = [
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            },
            {
                id   : 'de',
                title: 'Deutsch',
                flag : 'de'
            }
        ];
        this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});
    }

    openNotification(msg):void {
        console.log(msg);
    }
}
