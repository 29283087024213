export const locale = {
    lang: 'de',
    data: {
        'CLIENTS': {

            'OVERVIEW' : {
                'HEADLINE' : 'Mandanten - Übersicht',
                'TABLE' : {
                    'ID'        : 'ID',
                    'NAME'      : 'Name',
                    'CITY'      : 'Ort',
                    'ZIP'       : 'PLZ',
                    'STREET'    : 'Strasse',
                    'LOGOFILE'  : 'Logo',
                    'COUNTRY'   : 'Land',
                    'VAT'       : 'USt. ID',
                    'CURRENCY'  : 'Währung',
                },
            },
            'TEXTBLOCKS' : {
                'HEADLINE' : 'Textbausteine',
                'FORM' : {
                    'ADD'        : 'Textbaustein hinzufügen / bearbeiten',
                    'PUBLISHSTATEMENT_EMAILTEXT' : 'Pulish statement E-Mail Text',
                    'PUBLISHSTATEMENT_ERROR': 'Pulish statement E-Mail Text Error',
                },
            },
            'EDIT' : {
                'VIEW' : {
                    'HEADLINE' : 'Mandant',


                    'SIDEBAR' : {
                        'BUTTON' : {
                            'DELETE' : 'Mandant löschen',
                            'ADDUSER'   : 'Benutzer hinzufügen',
                        },
                    },
                },
            },

            'ADD' : {
                'MODAL' : {
                    'CLIENTDATA'        : 'Mandant',
                    'HEADLINE'          : 'Mandanten hinzufügen / bearbeiten',
                    'NAME'              : 'Firmenname',
                    'NAMEREQMESSAGE'    : 'Firmenname notwendig!',
                    'STREET'            : 'Strasse',
                    'STREETREQMESSAGE'  : 'Strasse notwendig!',
                    'CITY'              : 'Stadt',
                    'CITYREQMESSAGE'    : 'Stadt notwendig!',
                    'ZIP'               : 'PLZ',
                    'ZIPREQMESSAGE'     : 'PLZ notwendig!',
                    'VAT'               : 'USt. ID:',
                    'VATREQMESSAGE'     : 'USt. ID notwendig!',
                    'COUNTRY'           : 'Land',
                    'COUNTRYREQMESSAGE' : 'Land notwendig!',
                    'CURRENCY'          : 'Währung',
                    'CURRENCYREQMESSAGE': 'Währung notwendig!',
                    'MCMSAPIKEY'        : 'MCMS API KEY',
                    'MCMSAPIKEYMESSAGE' : 'MCMS API KEY notwendig!',
                    'MINIMUMPAYOUT'     : 'Minimum Auszahlungsbetrag an Lizenzgeber',
                    'EUR'               : 'EUR',
                    'USD'               : 'USD',
                    'BUTTON'            : {
                        'SAVE'      : 'Speichern',
                        'CANCEL'    : 'Abbrechen',
                    },
                },
            },

            'USERS' : {
                'OVERVIEW' : {
                    'LABEL1' : 'Benutzer',
                },
            },


            'SIDEBAR'   : {
                'BUTTON'    : {
                    'ADD'       : 'Mandanten anlegen',
                },
            },
        },

        'HELPTEXT' : {

                'OVERVIEW' : {
                    'HEADLINE' : 'Mandantenübersicht',
                    'TEXT' : 'Hier findest du eine Übersicht, über alle Mandanten.',
                    'LIST1' : 'Mit dem + kannst du neue Mandanten erstellen.',
                },

                'EDIT' : {
                    'HEADLINE' : 'Mandant bearbeiten',
                    'TEXT' : 'Hier kann der Mandant mit seinen Benutzern im Detail angeschaut und bearbeitet werden.',
                    'LIST1' : 'Die mit * markierten Felder sind Pflichtfelder und müssen ausgefüllt sein.',
                    'LIST2' : 'Die Währung muss korrekt eingestellt werden, hier geht es um die Währung in der der Mandant abrechne, nicht wie er bezahlt wird.',
                    'LIST3' : 'USt. ID ist ein Pflichtfeld und wird vorausgesetzt, da jede Firma eine SteuerID haben muss.',
                    'LIST4' : 'Die Benutzer, die zum Mandanten gehören, können hier angelegt und bearbeitet werden.',
                    'LIST5' : 'Es können nur Benutzer zu diesem Mandanten angelegt werden.',
                },

        },
    }
};
