export const locale = {
    lang: 'en',
    data: {
        'CLIENTS': {
            'OVERVIEW' : {
                'HEADLINE' : 'Client - overview',
                'TABLE' : {
                    'ID'        : 'ID',
                    'NAME'      : 'Name',
                    'CITY'      : 'City',
                    'ZIP'       : 'ZIP',
                    'STREET'    : 'Street',
                    'LOGOFILE'  : 'Logo',
                    'COUNTRY'   : 'Country',
                    'VAT'       : 'VAT ID',
                    'CURRENCY'  : 'Currency',
                },
            },

            'EDIT' : {
                'VIEW' : {
                    'HEADLINE' : 'Client',


                    'SIDEBAR' : {
                        'BUTTON' : {
                            'DELETE' : 'Delete client',
                            'ADDUSER'   : 'Add user',
                        },
                    },
                },
            },

            'ADD' : {
                'MODAL' : {
                    'CLIENTDATA'        : 'Client',
                    'HEADLINE'          : 'Add / Edit client',
                    'NAME'              : 'Companyname',
                    'NAMEREQMESSAGE'    : 'Companyname is required!',
                    'STREET'            : 'Street',
                    'STREETREQMESSAGE'  : 'Street is required!',
                    'CITY'              : 'City',
                    'CITYREQMESSAGE'    : 'City is required!',
                    'ZIP'               : 'ZIP',
                    'ZIPREQMESSAGE'     : 'ZIP is required!',
                    'VAT'               : 'VAT ID',
                    'VATREQMESSAGE'     : 'VAT ID is required!',
                    'COUNTRY'           : 'Country',
                    'COUNTRYREQMESSAGE' : 'Country is required!',
                    'CURRENCY'          : 'Currency',
                    'CURRENCYREQMESSAGE': 'Currency is required!',
                    'MCMSAPIKEY'        : 'MCMS API KEY',
                    'MCMSAPIKEYMESSAGE' : 'MCMS API KEY is required!',
                    'MINIMUMPAYOUT'     : 'Minimum payout amount for licensors',
                    'EUR'               : 'EUR',
                    'USD'               : 'USD',
                    'BUTTON'            : {
                        'SAVE'      : 'Save',
                        'CANCEL'    : 'Cancel',
                    },
                },
            },

            'USER' : {
                'OVERVIEW' : {
                    'LABEL1' : 'User',
                },
            },


            'SIDEBAR'   : {
                'BUTTON'    : {
                    'ADD'       : 'Add client',
                },
            },
        },

        'HELPTEXT' : {

            'OVERVIEW' : {
                'HEADLINE' : 'Clientoverview',
                'TEXT' : 'Here you will find an overview of all clients.',
                'LIST1' : 'With the + you can create new clients.',
            },

            'EDIT' : {
                'HEADLINE' : 'Edit clients',
                'TEXT' : 'Here, the client can be viewed and edited in detail with its users.',
                'LIST1' : 'The fields marked with * are mandatory and must be completed.',
                'LIST2' : 'The currency must be set correctly, this is the currency in which the client settles, not how it is paid.',
                'LIST3' : 'VAT ID is a mandatory field and is assumed as each company must have a tax ID.',
                'LIST4' : 'The users belonging to the client can be created and edited here.',
                'LIST5' : 'Only users can be created for this client.',
            },

        },
    }
};
