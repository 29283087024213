import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectComponent } from './project.component';
import {RouterModule} from '@angular/router';

import { MatButtonModule, MatIconModule, MatRippleModule, MatSlideToggleModule, MatTableModule, MatStepperModule ,MatFormFieldModule, MatPaginatorModule, MatInputModule, MatSidenavModule, MatSelectModule, MatListModule} from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';


const routes = [
  {
    path     : 'project',
    component: ProjectComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatRippleModule, MatSlideToggleModule, MatTableModule,
    TranslateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatListModule,
    FuseSharedModule,
    FuseSidebarModule,
  ],
  declarations: [ProjectComponent],
  exports: [
    ProjectComponent
  ]
})
export class ProjectModule { }
