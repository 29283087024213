import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { LoginComponent } from 'app/login/login.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';

const routes = [
    {
        path     : 'login',
        component: LoginComponent
    },
    {
        path: 'forgot-password',
        component : ForgotpasswordComponent
    },
    {
        path: 'forgot-password/password-reset/:token',
        component: ResetpasswordComponent
    }
];

@NgModule({
    declarations: [
        LoginComponent,
        ForgotpasswordComponent,
        ResetpasswordComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,

        FuseSharedModule
    ]
})
export class LoginModule
{
}
