import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatStepperModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { AccountComponent } from '@/main/account/account.component';
import {AuthGuardService as AuthGuard } from '@/auth/auth-guard.service';


const routes = [
    {
        path     : 'account',
        component: AccountComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        AccountComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        TranslateModule,
        FuseSharedModule
    ],
    exports     : [
        AccountComponent
    ]
})

export class AccountModule { }
