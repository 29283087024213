import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {MatDialog} from '@angular/material';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountingService {

  httpHeaders: HttpHeaders;

  constructor(
      private _httpClient: HttpClient,
      private _auth: OAuthService,
      public dialog: MatDialog
  ) {
    this.httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + this._auth.getAccessToken());
  }

  loadPeriods(uri: string, filter = '', direction = 'asc', sort = 'name', page = 0, pageSize = 10): Observable<any> {
    return this._httpClient.get('/api/' + uri, {
      params: new HttpParams()
          .set('filter', filter)
          .set('sort', sort)
          .set('direction', direction)
          .set('page', page.toString())
          .set('pageSize', pageSize.toString()), headers: this.httpHeaders
    }).pipe(
        map(res => res)
    );
  }

  getPeriodsList(): any {
      return this._httpClient.get('/api/accounting/getPeriodsList', {headers: this.httpHeaders});
  }

  addPeriod(uri: string, period): Observable<any> {
    return this._httpClient.post('/api/' + uri, period, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  deletePeriods(uri: string, formData: any): any {
    return this._httpClient.post('/api/' + uri, formData, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  getOnePeriod(uri: string): any {
    return this._httpClient.get('/api/' + uri, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  closePeriod(period_id): any {
      return this._httpClient.get('/api/accounting/closePeriod/' + period_id, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  loadSales(uri: string,  filter = '', direction = 'asc', sort = 'lastname', page = 0, pageSize = 10, periodId = 0):  Observable<any> {
    return this._httpClient.get('/api/' + uri, {
      params: new HttpParams()
          .set('filter', filter)
          .set('sort', sort)
          .set('direction', direction)
          .set('page', page.toString())
          .set('periodId', periodId.toString())
          .set('pageSize', pageSize.toString()), headers : this.httpHeaders
    }).pipe(
        map(res => res )
    );
  }

  saveDigitalSale(uri, sale): Observable<any> {
    return this._httpClient.post('/api/' + uri, sale, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  saveSoundRecordingSale(uri, sale): Observable<any> {
    return this._httpClient.post('/api/' + uri, sale, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  saveMerchSale(uri, sale): Observable<any> {
    return this._httpClient.post('/api/' + uri, sale, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  saveReturn(uri, sale): Observable<any> {
    return this._httpClient.post('/api/' + uri, sale, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  deleteReturns(ids): Observable<any> {
    return this._httpClient.post('/api/accounting/returns/delete', ids, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  deleteSales(ids): Observable<any> {
    return this._httpClient.post('/api/accounting/sales/deleteDigitalSales', ids, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  deleteMerchSales(ids): Observable<any> {
    return this._httpClient.post('/api/accounting/sales/deleteMerchSales', ids, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  deleteSoundRecordingSales(ids): Observable<any> {
    return this._httpClient.post('/api/accounting/sales/deleteSoundRecordingSales', ids, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  deleteSublicenseRevenue(ids): Observable<any> {
    return this._httpClient.post('/api/accounting/sales/deleteSublicenses', ids, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  // Import //
  saveAutomatorTask(task: any): any {
    return this._httpClient.post('/api/import/saveAutomatorTask', task, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  loadAutomatorTasks( filter = '', direction = 'asc', sort = 'type', page = 0, pageSize = 10, periodId = 0):  Observable<any> {
      return this._httpClient.get('/api/import/getAutomatorTasks', {
          params: new HttpParams()
              .set('filter', filter)
              .set('sort', sort)
              .set('direction', direction)
              .set('page', page.toString())
              .set('periodId', periodId.toString())
              .set('pageSize', pageSize.toString()), headers : this.httpHeaders
      }).pipe(
          map(res => res )
      );
  }

  deleteAutomatorTask(id): any {
      return this._httpClient.get('/api/import/deleteAutomatorTask/' + id, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

    startImportTask(task): any {
        return this._httpClient.post('/api/import/startAutomatorTask', task, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
    }

  getAvailablePeriods(accountingPeriodId): any {
      return this._httpClient.get('/api/import/mcmsSalesSync/availablePeriods?period=' + accountingPeriodId, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

  getImportedFiles(accountingPeriodId): any {
        return this._httpClient.get('/api/import/getImportedFiles/' + accountingPeriodId, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
  }

    MCMSSalesSync(importData): any {
        return this._httpClient.post('/api/import/mcmsSalesSync/addServerTask', importData, {reportProgress: false, observe: 'events', headers: this.httpHeaders});

    }

    deleteImportedFile(fileId): any {
        return this._httpClient.get('/api/import/deleteImportedFile/' + fileId, {reportProgress: false, observe: 'events', headers: this.httpHeaders});
    }

    downloadErrorReport(fileId): any {
        return this._httpClient.get('/api/import/getErrorReport/' + fileId, {responseType: 'blob', reportProgress: false, observe: 'events', headers : this.httpHeaders } );
    }

    getRunningImportTasks(periodId): any {

        return this._httpClient.get('/api/import/getRunningTasks?period=' + periodId, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );

    }

    getErrorImportTasks(periodId): any {

        return this._httpClient.get('/api/import/getErrorTasks?period=' + periodId, {reportProgress: false, observe: 'events', headers : this.httpHeaders } );

    }

}
