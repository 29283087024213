import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalService} from '@/shared/services/global.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';

@Component({
    selector     : 'resetpassword',
    templateUrl  : './resetpassword.component.html',
    styleUrls    : ['./resetpassword.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ResetpasswordComponent implements OnInit, OnDestroy
{
    resetPasswordForm: FormGroup;

    // Private
    private _unsubscribeAll: Subject<any>;
    private token: any;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private _globalService: GlobalService
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {



        this.resetPasswordForm = this._formBuilder.group({
            token          : ['', Validators.required],
            email          : ['', [Validators.required, Validators.email]],
            password       : ['', Validators.required],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.resetPasswordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
            });


        this.token = this.route.snapshot.paramMap.get('token');

        console.log(this.token);
        this._globalService.checkResetToken(this.token).subscribe(
            ( response: HttpEvent<any> ) => {

                switch (response.type) {
                    case HttpEventType.Response:
                        console.log(response.status);

                            this.resetPasswordForm.get('email').setValue(response.body.email);
                            this.resetPasswordForm.get('token').setValue(this.token);


                        break;
                }
            },
            error => {
                console.log(error);
                this.router.navigateByUrl('/login');
            }
        );

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    get f() { return this.resetPasswordForm.controls; }

    onSubmit(): void {
        if (this.resetPasswordForm.valid) {
            this._globalService.ResetPassword({email : this.f.email.value, token: this.f.token.value, password: this.f.password.value }).subscribe(
                ( response: HttpEvent<any> ) => {
                    switch (response.type) {
                        case HttpEventType.Response:
                            if ( response.body != null && response.body.success === true ){
                                console.log(response.body);
                            }

                            this.router.navigateByUrl('/login');

                            break;
                    }
                },
                error => {
                        console.log(error);
                }
            );
        }
    }

}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if ( !control.parent || !control )
    {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if ( !password || !passwordConfirm )
    {
        return null;
    }

    if ( passwordConfirm.value === '' )
    {
        return null;
    }

    if ( password.value === passwordConfirm.value )
    {
        return null;
    }

    return {'passwordsNotMatching': true};
};
