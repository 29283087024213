import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  httpHeaders: HttpHeaders;

  constructor(
      private _httpClient: HttpClient,
      private _auth: OAuthService
  ) {
      this.httpHeaders = new HttpHeaders().set('Accept', 'application/json').set('Authorization', 'Bearer ' + this._auth.getAccessToken());
  }

  getOverallRevenue(from, to): any {
      return this._httpClient.get('/api/analytics/getOverallRevenue?from=' + from + '&to=' + to, { headers: this.httpHeaders});
  }

  getRevenueByLicensor(from, to): any {
      return this._httpClient.get('/api/analytics/getDigitalRevenueByLicensors?from=' + from + '&to=' + to, { headers: this.httpHeaders});
  }

  getPhysicalRevenueByLicensor(from, to): any {
        return this._httpClient.get('/api/analytics/getPhysicalRevenueByLicensors?from=' + from + '&to=' + to, { headers: this.httpHeaders});
  }

    getMerchRevenueByLicensor(from, to): any {
        return this._httpClient.get('/api/analytics/getMerchRevenueByLicensors?from=' + from + '&to=' + to, { headers: this.httpHeaders});
    }

    getSublicenseRevenueByLicensor(from, to): any {
        return this._httpClient.get('/api/analytics/getSublicenseRevenueByLicensors?from=' + from + '&to=' + to, { headers: this.httpHeaders});
    }

}
